import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import BreadcrumbsAddUsers from "../../components/Common/BreadcrumbUsers";
import TableContainer from "../../components/Common/TableContainerRelance";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  FormGroup,
  Label,
  Form
} from "reactstrap";
import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import withRouter from "../../components/Common/withRouter";

function Relance(props) {
  document.title = "Relances Clients";

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [compte, setCompte] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [relances, setRelances] = useState([]);
  const [filteredRelances, setFilteredRelances] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [relanceDate, setRelanceDate] = useState("");
  const [relanceHeure, setRelanceHeure] = useState("");
  const [typeRelance, setTypeRelance] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [piecesRegulariser, setPiecesRegulariser] = useState([]);
  const [etatAppel, setEtatAppel] = useState("");
  const [humeurClient, setHumeurClient] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const getAllCompte = async () => {
    try {
      const response = await axios.get(
        key.apiBaseURL + "/affichertoutcompteclient",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response) {
        setCompte(response.data);
      }
    } catch (error) {
      console.log("erreur :: ", error.response);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllCompte();
    }
  }, [accessToken]);

  const imprimer = () => {
    window.print();
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSelectClient = (e) => {
    const selectedClient = compte.find(c => c.intitule_client === e.target.value);
    setSelectedClient(selectedClient.intitule_client);

    const pieces = [];
    if (selectedClient.copie_ifu === 0) pieces.push("Copie IFU");
    if (selectedClient.piece_identite === 0) pieces.push("Pièce d'identité");
    if (selectedClient.copie_piece_identite === 0) pieces.push("Copie pièce d'identité");
    if (selectedClient.deux_photo_identite === 0) pieces.push("Deux photos d'identités");
    if (selectedClient.formulaire_EER === 0) pieces.push("Formulaire EER");
    if (selectedClient.formulaire_KYC === 0) pieces.push("Formulaire KYC");
    if (selectedClient.formulaire_BIC === 0) pieces.push("Formulaire BIC");
    if (selectedClient.cip_attestation_residence === 0) pieces.push("CIP/Attestation de résidence");
    if (selectedClient.justificatif_activite === 0) pieces.push("Justificatif d'activité");
    setPiecesRegulariser(pieces);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRelance = {
      date: relanceDate,
      heure: relanceHeure,
      client: selectedClient,
      pieces: piecesRegulariser,
      type: typeRelance,
      etatAppel,
      humeurClient,
      commentaire
    };
    setRelances([...relances, newRelance]);
    setFilteredRelances([...relances, newRelance]);
    setShowForm(false);
    toast.success("Relance ajoutée avec succès!");
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = relances.filter(relance =>
      relance.client.toLowerCase().includes(term)
    );
    setFilteredRelances(filtered);
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <div className="container-fluid">
          <BreadcrumbsAddUsers title="" breadcrumbItem="Relances Clients" />
          <Row>
            <Col>
              <div className="mb-3">
                <Link to="#" onClick={imprimer} className="btn btn-success waves-effect waves-light ms-auto">
                  Imprimer
                </Link>
                <Button color="primary" className="ms-2" onClick={handleShowForm}>
                  Faire une relance
                </Button></div>            
            </Col>
            <Col className="d-flex justify-content-end ">
            <div className="mb-3">
              <Link to="/rapport-relance-client" className="btn btn-success waves-effect waves-light ms-auto">
                  Rapport
              </Link>
            </div>
            </Col>
          </Row>
          {showForm && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="relanceDate">Date</Label>
                            <Input
                              type="date"
                              id="relanceDate"
                              value={relanceDate}
                              onChange={(e) => setRelanceDate(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="relanceHeure">Heure</Label>
                            <Input
                              type="time"
                              id="relanceHeure"
                              value={relanceHeure}
                              onChange={(e) => setRelanceHeure(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="selectClient">Intitulé du Compte</Label>
                            <Input
                              type="select"
                              id="selectClient"
                              value={selectedClient}
                              onChange={handleSelectClient}
                            >
                              <option value="">Sélectionner un client</option>
                              {compte.map((c) => (
                                <option key={c.intitule_client} value={c.intitule_client}>
                                  {c.intitule_client}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="piecesRegulariser">Pièces à Régulariser</Label>
                            <Input
                              type="textarea"
                              id="piecesRegulariser"
                              value={piecesRegulariser.join(", ")}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="typeRelance">Type de Relance</Label>
                            <Input
                              type="select"
                              id="typeRelance"
                              value={typeRelance}
                              onChange={(e) => setTypeRelance(e.target.value)}
                            >
                              <option value="">Sélectionner un type</option>
                              <option value="sms">SMS</option>
                              <option value="mail">Mail</option>
                              <option value="appel_whatsapp">Appel WhatsApp</option>
                              <option value="appel_international">Appel International</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="etatAppel">État appel</Label>
                            <Input
                              type="select"
                              id="etatAppel"
                              value={etatAppel}
                              onChange={(e) => setEtatAppel(e.target.value)}
                            >
                              <option value="">Sélectionner un état</option>
                              <option value="decroche">Décroché</option>
                              <option value="non_decroche">Non décroché</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="humeurClient">Humeur client</Label>
                            <Input
                              type="select"
                              id="humeurClient"
                              value={humeurClient}
                              onChange={(e) => setHumeurClient(e.target.value)}
                            >
                              <option value="">Sélectionner une humeur</option>
                              <option value="courtois">Courtois</option>
                              <option value="non_courtois">Non courtois</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="commentaire">Commentaire</Label>
                            <Input
                              type="textarea"
                              id="commentaire"
                              value={commentaire}
                              onChange={(e) => setCommentaire(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button color="primary" type="submit">Valider</Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col>
              <div className="search-box mb-4">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Rechercher par intitulé de compte"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
              <Card>
                <CardBody>
                  <Table className="table-responsive">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Heure</th>
                        <th>Intitulé du Compte</th>
                        <th>Pièces à Régulariser</th>
                        <th>Type de Relance</th>
                        <th>État appel</th>
                        <th>Humeur client</th>
                        <th>Commentaire</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredRelances.map((relance, index) => (
                        <tr key={index}>
                          <td>{relance.date}</td>
                          <td>{relance.heure}</td>
                          <td>{relance.client}</td>
                          <td>{relance.pieces.join(", ")}</td>
                          <td>{relance.type}</td>
                          <td>{relance.etatAppel}</td>
                          <td>{relance.humeurClient}</td>
                          <td>{relance.commentaire}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

Relance.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withRouter(Relance);
