import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Image
import logo from "../../../assets/images/logo-dark.png";
import PropTypes from "prop-types";
import { getFactureDetail } from "../../../store/documents/actions";
import { connect } from "react-redux";

const FactureDetail = props => {

  document.title=" Facture Detail | Minible - Responsive Bootstrap 5 Admin Dashboard"

  const params = props.router.params;

  const {
    factureDetail,
    onGetFactureDetail,
  } = props;

  useEffect(() => {
    if (params && params.id) {
      onGetFactureDetail(params.id);
    }
  }, [params, onGetFactureDetail]);

  //Print the Facture
  const printFacture = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Facturation" breadcrumbItem="Voir la facture" />
          {!isEmpty(factureDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="facture-title">
                      <h4 className="float-end font-size-16">
                        Facture Proforma
                        {/* <span className={`badge ${factureDetail.status === 'Payé' ? 'bg-success' : 'bg-warning'} font-size-12 ms-2`}>
                          {factureDetail.status
                          }</span> */}
                      </h4>
                      <div className="mb-4">
                        <img src={logo} alt="logo" height="20" />
                      </div>
                      <div className="text-muted">
                        <p className="mb-1">{factureDetail.shippingAddress}</p>
                        <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i> {factureDetail.shippingEmail}</p>
                        <p><i className="uil uil-phone me-1"></i> {factureDetail.shippingPhoneno}</p>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col sm="6">
                        <div className="text-muted">
                          <h5 className="font-size-16 mb-3">Facture à l'ordre de:</h5>
                          <h5 className="font-size-15 mb-2">{factureDetail.billingName}</h5>
                          <p className="mb-1">{factureDetail.billingAddress}</p>
                          <p className="mb-1">{factureDetail.billingEmail}</p>
                          <p>{factureDetail.billingPhoneno}</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="text-muted text-sm-end">
                          <div>
                            <h5 className="font-size-16 mb-1">No de la facture:</h5>
                            <p>{factureDetail.factureID}</p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">Date d'établissement de la facture:</h5>
                            <p>{factureDetail.date}</p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">No de la commande:</h5>
                            <p>#{factureDetail.orderId}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="py-2">
                      <h5 className="font-size-15">Produits commandés</h5>
                      <div className="table-responsive">
                        <Table className="table-nowrap table-centered mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>No.</th>
                              <th>Item</th>
                              <th>Prix</th>
                              <th>Quantités</th>
                              <th className="text-end" style={{ width: "120px" }}>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(
                              factureDetail.orderSummary.items,
                              (item, key) => (
                                <tr key={key}>
                                  <td>{item.id}</td>
                                  <td>
                                    <h5 className="font-size-15 mb-1">{item.item}</h5>
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">Coleur : <span className="fw-medium">{item.color}</span></li>{" "}
                                      <li className="list-inline-item">Taille : <span className="fw-medium">{item.size}</span></li>
                                    </ul>
                                  </td>
                                  <td>{item.price}</td>
                                  <td>{item.quantity}</td>
                                  <td className="text-end">{item.price}</td>
                                </tr>
                              )
                            )}
                            <tr>
                              <th colSpan="4" className="text-end">Sous Total</th>
                              <td className="text-end">{factureDetail.orderSummary.subTotal}</td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                Réductions :</th>
                              <td className="border-0 text-end">- {factureDetail.orderSummary.discount}</td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                Frais de livraison :</th>
                              <td className="border-0 text-end">{factureDetail.orderSummary.shipping}</td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">
                                Taxes</th>
                              <td className="border-0 text-end">{factureDetail.orderSummary.tax}</td>
                            </tr>
                            <tr>
                              <th colSpan="4" className="border-0 text-end">Montant dû</th>
                              <td className="border-0 text-end"><h4 className="m-0">{factureDetail.orderSummary.total}</h4></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-print-none mt-4">
                        <div className="float-end">
                          <Link to="#" onClick={printFacture} className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></Link>{" "}
                          <Link to="#" className="btn btn-primary w-md waves-effect waves-light">Envoyer</Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

FactureDetail.propTypes = {
  factureDetail: PropTypes.object,
  onGetFactureDetail: PropTypes.func,
};

const mapStateToProps = ({ factures }) => ({
  factureDetail: factures.factureDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetFactureDetail: id => dispatch(getFactureDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FactureDetail));
