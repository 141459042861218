import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../Common/withRouter";
import { Link, useLocation } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  // const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      // scrollElement(item);
      return false;
    }
    // scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  // useEffect(() => {
  //   ref.current.recalculate();
  // }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop;
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
  //     }
  //   }
  // }
  return (
    <React.Fragment>
       <SimpleBar style={{ maxHeight: "100%" }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="uil-home-alt"></i>
                <span>{props.t("TABLEAU DE BORD")}</span>
              </Link>
            </li>
            
            <li>
              <Link to="/userslist-obd" className="waves-effect">
                <i className="uil-calender"></i>
                <span>{props.t("Utilisateurs")}</span>
              </Link>
            </li>

            <li>
              <Link to="/contacts-grid-Obd" className="waves-effect">
                <i className="uil-calender"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
            </li>

            <li>
              <Link to="/compte-client" className="has-arrow waves-effect">
                <i className="uil-calender"></i>
                <span>{props.t("Compte client")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/compte-client">{props.t("Listes")} </Link>
                </li>
                <li>
                  <Link to="/rapport-compte-client">{props.t("Rapports")} </Link>
                </li>
                <li>
                  <Link to="/relance-compte-client">{props.t("Relances")} </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-store"></i>
                <span>{props.t("Catalogues")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/productslist-obd">{props.t("Listes de produits")} </Link>
                </li>
                <li>
                  <Link to="/gridcategory-obd">{props.t("Catégories de produits")} </Link>
                </li>
                {/* <li>
                  <Link to="/#">{props.t("Lot de produits")} </Link>
                </li> */}
                <li>
                  <Link to="/prixachat-obd">{props.t("Prix d'achat")} </Link>
                </li>
                <li>
                  <Link to="/prixvente-obd">{props.t("Prix de vente")} </Link>
                </li>
              </ul>
            </li>

          
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-store"></i>
                <span>{props.t("Stock")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/stocks-inventory">{props.t("Inventaire")}</Link>
                </li>
                <li>
                  <Link to="/stocks-supply">{props.t("approvisionnement")}</Link>
                </li>
                <li>
                  <Link to="/stocks-magazin">{props.t("Magasins")}</Link>
                </li>
                <li>
                  <Link to="/stocks-loss">{props.t("Pertes & avaries")}</Link>
                </li>
                <li>
                  <Link to="/stocks-consommation">{props.t("Centre de consommation")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-flask"></i>
                <span>{props.t("Facturation")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/facture-list">{props.t("Factures")}</Link>
                </li>
                <li>
                  <Link to="/factureP-list">{props.t("Factures proforma")}</Link>
                </li>
                <li>
                  <Link to="/bonC-list">{props.t("Bon de commande")}</Link>
                </li>
                <li>
                  <Link to="/bonL-list">{props.t("Bon de livraison")}</Link>
                </li>
                <li>
                  <Link to="/factureN-list">{props.t("Factures normalisées")}</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to="/#" className="waves-effect">
                <i className="uil-calender"></i>
                <span>{props.t("Règlements")}</span>
              </Link>
            </li>

            <li>
              <Link to="/file-manager" className="has-arrow waves-effect">
                <i className="uil-comments-alt"></i>
                <span>{props.t("Fichier")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Exportation des fichiers")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Importation des fichiers")} </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Guide d'utilisation")} </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));