import React, { useEffect, useState } from "react"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { Link, useParams } from "react-router-dom"

import { Row, Col, CardBody, Card, Container, Form, Label, Input, FormFeedback, UncontrolledAlert } from "reactstrap";
import axios from 'axios'
import { key } from '../../constants/key'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/ReactToastify.css'

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const Activate = (props) => {
  document.title="Activate"

  const params = useParams('token')

  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  
  useEffect(()=>{
    const validateAcount = async () => {
      try {
        const token = params.token
        const response = await axios.post(
          `${key.apiBaseURL}/createuser`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        if ( response ) {
          setSuccess(true)
        }
      } catch (error) {
        setSuccess(false)
        toast.error("Nous n'avons pas pu activer votre compte")
        console.log("Erreur lors de la validation :: ", error.response)
      }
    }

    validateAcount()
  }, [params])
  
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            {
              success === true
              ?
                <Col sm={5} md={5} lg={5}>
                  <UncontrolledAlert
                    color="success"
                    className="alert-success alert-dismissible fade show mt-4 px-4 mb-0 text-center"
                    role="alert"
                  >
                    <i className="uil uil-check-circle d-block display-4 mt-2 mb-3 text-success"></i>
                    <h5 className="text-success">Succès</h5>
                    <p>Valider</p>
                  </UncontrolledAlert>
                </Col>
              :
                success === false
                ?
                  <Col sm={5} md={5} lg={5}>
                    <UncontrolledAlert
                      color="danger"
                      className="alert-danger alert-dismissible fade show mt-4 px-4 mb-0 text-center"
                      role="alert"
                    >
                      <i className="uil uil-exclamation-octagon d-block display-4 mt-2 mb-3 text-danger"></i>
                      <h5 className="text-danger">Erreur</h5>
                      <p>Echec de la validation</p>
                    </UncontrolledAlert>
                  </Col>
                :
                  <Col sm={5} md={5} lg={5}>
                    <UncontrolledAlert
                      color="secondary"
                      className="alert-border alert-border-warning alert-dismissible fade show mt-4 px-4 mb-0 text-center"
                      role="alert"
                    >
                      <i className="uil uil-exclamation-triangle d-block display-4 mt-2 mb-3 text-warning"></i>
                      <h5 className="text-warning">En cours de validation</h5>
                      <p>Veuillez patienter</p>
                    </UncontrolledAlert>
                  </Col> 
            }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Activate
