import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  ModalFooter
} from "reactstrap";
import axios from "axios"
import Select from 'react-select';
import { key } from "../../constants/key"
import { ToastContainer, toast } from 'react-toastify'
import { Link } from "react-router-dom";

const ProductsList = () => {
  document.title = "Minible | Produits";

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;
  const [listCategorie, setListCategorie] = useState('')
  const [listMagasin, setListMagasin] = useState([])
  const [listProduit, setListProduit] = useState('')
  const [listTarif, setListTarif] = useState([]);
  const [listQuantite, setListQuantite] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const openConfirmModal = (index) => {
    setProductToDelete(productData[index]);
    setConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setProductToDelete(null);
    setConfirmModal(false);
  };

  const getAllCategorie = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/voirtoutecategorie`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data"
          }
        }
      )
      if (response) {
        console.log("categorie list :: ", response.data)
        setListCategorie(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    if (accessToken) {
      getAllCategorie()
    }
  }, [accessToken])

  const getAllMagazin = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/voirtoutmagasin`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data"
          }
        }
      )
      if (response) {
        console.log("magasin list :: ", response.data)
        setListMagasin(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    if (accessToken) {
      getAllMagazin()
    }
  }, [accessToken])

  const getAllTarif = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/voirtouttarif`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data"
          }
        }
      )
      if (response) {
        console.log("tarif list :: ", response.data)
        setListTarif(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    if (accessToken) {
      getAllTarif()
    }
  }, [accessToken])

  const getAllQuantite = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/voirtouttarifqte`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data"
          }
        }
      )
      if (response) {
        console.log("quantite list :: ", response.data)
        setListQuantite(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    if (accessToken) {
      getAllQuantite()
    }
  }, [accessToken])

  const getAllProduit = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/affichertoutproduit`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data"
          }
        }
      )
      if (response) {
        console.log(response)
        console.log("produit list :: ", response.data)
        setProductData(response.data);
        setListProduit(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    if (accessToken) {
      getAllProduit()
    }
  }, [accessToken])

  const [productData, setProductData] = useState([
    // Add more initial data as needed
  ]);

  const handleSelectChange = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setNewProduct({ ...newProduct, magasin_id: values });
  };

  const selectOptions = listMagasin.map(item => ({
    value: item.id,
    label: item.nom_du_magasin
  }));

  const [newProduct, setNewProduct] = useState({
    photo: "",
    libelle: "",
    descriptionn: "",
    qte_alerte: "",
    qte_securite: "",
    categorie_id: "",
    default_prix_achat: "",
    magasin_id: [],
    numero_produit: "",
    tva: false,
    prix_vente_tarif: [{ id_tarif: "", montant: "", tva: "", ttc: "" }],
    prix_vente_qte: [{ id_tarif_qte: "", montant: "", tva: "", ttc: "" }],
    stocke: false,
    vendable: false,
    compose: false,
    fabrique: false,
    taux_tva: 18
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [formPage, setFormPage] = useState(1);

  const toggleModal = () => {
    setErrors({});
    setFormPage(1);
    setModal(!modal);
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const validateForm = () => {
    const newErrors = {};

    if (!newProduct.libelle.trim()) newErrors.libelle = "Veuillez remplir ce champ.";
    if (!newProduct.descriptionn.trim()) newErrors.descriptionn = "Veuillez remplir ce champ.";
    if (!newProduct.qte_alerte.trim()) newErrors.qte_alerte = "Veuillez remplir ce champ.";
    if (!newProduct.qte_securite.trim()) newErrors.qte_securite = "Veuillez remplir ce champ.";
    if (!newProduct.categorie_id.trim()) newErrors.categorie_id = "Veuillez remplir ce champ.";
    if (!newProduct.default_prix_achat.trim()) newErrors.default_prix_achat = "Veuillez remplir ce champ.";
    if (newProduct.magasin_id.length === 0) newErrors.magasin_id = "Veuillez remplir ce champ.";

    if (newProduct.prix_vente_tarif.length === 0) {
      newErrors.prix_vente_tarif = "Veuillez ajouter au moins un tarif de vente.";
    } else {
      newProduct.prix_vente_tarif.forEach((item, index) => {
        if (!item.id_tarif.trim() || !item.montant.trim() || !item.tva.trim() || !item.ttc.trim()) {
          newErrors.prix_vente_tarif = "Tous les champs de tarif de vente doivent être remplis.";
        }
      });
    }

    if (newProduct.prix_vente_qte.length === 0) {
      newErrors.prix_vente_qte = "Veuillez ajouter au moins une quantité de vente.";
    } else {
      newProduct.prix_vente_qte.forEach((item, index) => {
        if (!item.id_tarif_qte.trim() || !item.montant.trim() || !item.tva.trim() || !item.ttc.trim()) {
          newErrors.prix_vente_qte = "Tous les champs de quantité de vente doivent être remplis.";
        }
      });
    }

    if (!newProduct.numero_produit.trim()) newErrors.numero_produit = "Veuillez remplir ce champ.";

    return newErrors;
  };


  const handleNextPage = () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setFormPage(formPage + 1);
  };

  const handlePrevPage = () => {
    setFormPage(formPage - 1);
  };

  const handleSave = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("numero_produit", newProduct.numero_produit || '');
      formData.append("id_categorie", newProduct.categorie_id || '');
      formData.append("photo", newProduct.photo || '');
      formData.append("libelle", newProduct.libelle || '');
      formData.append("descriptionn", newProduct.descriptionn || '');
      formData.append("stocke", newProduct.stocke ? 1 : 0);
      formData.append("vendable", newProduct.vendable ? 1 : 0);
      formData.append("compose", newProduct.compose ? 1 : 0);
      formData.append("fabrique", newProduct.fabrique ? 1 : 0);
      formData.append("prix_achat", newProduct.prix_achat ? formatNumber(newProduct.prix_achat) : 0);
      formData.append("tva", newProduct.tva ? 1 : 0);
      formData.append("taux_tva", newProduct.taux_tva || 0);

      // Créer les objets pour prix_vente_tarif et prix_vente_qte
      const prixVenteTarif = [];
      newProduct.prix_vente_tarif.forEach((item, index) => {
        prixVenteTarif[index] = {
          id_tarif: item.id_tarif || '',
          montant: item.montant || '',
          tva: item.tva || '',
          ttc: item.ttc || '',
        };
      });

      const prixVenteQte = [];
      newProduct.prix_vente_qte.forEach((item, index) => {
        prixVenteQte[index] = {
          id_tarif_qte: item.id_tarif_qte || '',
          montant: item.montant || '',
          tva: item.tva || '',
          ttc: item.ttc || '',
        };
      });

      // Ajouter les objets à formData
      formData.append("prix_vente_tarif", JSON.stringify(prixVenteTarif));
      formData.append("prix_vente_qte", JSON.stringify(prixVenteQte));

      console.log("FormData:", formData);

      const response = await axios.post(`${key.apiBaseURL}/creerunproduit`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("response status :: ", response.status === 201);
      if (response.status === 201) {
        toast.success("Produit ajouté avec succès");
        console.log(response.data);
        setProductData([response.data, ...productData]);
      } else {
        toast.error("Erreur, veuillez réessayer");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit", error.response || error);
      toast.error("Erreur, veuillez réessayer");
    }

    setNewProduct({
      photo: "",
      libelle: "",
      descriptionn: "",
      qte_alerte: "",
      qte_securite: "",
      categorie_id: "",
      default_prix_achat: "",
      magasin_id: [],
      numero_produit: "",
      tva: false,
      prix_vente_tarif: [{ id_tarif: "", montant: "", tva: "", ttc: "" }],
      prix_vente_qte: [{ id_tarif_qte: "", montant: "", tva: "", ttc: "" }],
      stocke: false,
      vendable: false,
      compose: false,
      fabrique: false,
      taux_tva: 18,
    });
    setErrors({});
    setFormPage(1);
    setModal(false);
  };





  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        [name]: checked,
      }));
    } else {
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };


  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setNewProduct({ ...newProduct, photo: file });
  };

  const handleTarifChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTarifPrixVente = newProduct.prix_vente_tarif.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [name]: value };
        const montantHt = parseFloat(updatedItem.montant) || 0;
        const tauxTva = parseFloat(newProduct.taux_tva) / 100;
        const montantTaxe = newProduct.tva ? montantHt * tauxTva : 0;
        updatedItem.tva = montantTaxe.toFixed(2);
        updatedItem.ttc = (montantHt + montantTaxe).toFixed(2);
        return updatedItem;
      }
      return item;
    });
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      prix_vente_tarif: updatedTarifPrixVente,
    }));
  };

  const handleAddTarif = () => {
    setNewProduct({
      ...newProduct,
      prix_vente_tarif: [
        ...newProduct.prix_vente_tarif,
        { id_tarif: "", montant: "", tva: "", ttc: "" },
      ],
    });
  };

  const handleRemoveTarif = (index) => {
    const updatedTarifPrixVente = newProduct.prix_vente_tarif.filter((_, i) => i !== index);
    setNewProduct({ ...newProduct, prix_vente_tarif: updatedTarifPrixVente });
  };

  const handleInputChang = (e) => {
    const { name, type, checked, value } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    if (name === 'tva' || name === 'taux_tva') {
      const tauxTva = name === 'taux_tva' ? parseFloat(value) / 100 : parseFloat(newProduct.taux_tva) / 100;

      const updatedTarifPrixVente = newProduct.prix_vente_tarif.map(item => {
        const montantHt = parseFloat(item.montant) || 0;
        const montantTaxe = name === 'tva' ? (checked ? montantHt * tauxTva : 0) : (newProduct.tva ? montantHt * tauxTva : 0);
        return {
          ...item,
          tva: montantTaxe.toFixed(2),
          ttc: (montantHt + montantTaxe).toFixed(2),
        };
      });

      const updatedQuantitePrixVente = newProduct.prix_vente_qte.map(item => {
        const montantHt = parseFloat(item.montant) || 0;
        const montantTaxe = name === 'tva' ? (checked ? montantHt * tauxTva : 0) : (newProduct.tva ? montantHt * tauxTva : 0);
        return {
          ...item,
          tva: montantTaxe.toFixed(2),
          ttc: (montantHt + montantTaxe).toFixed(2),
        };
      });

      setNewProduct((prevProduct) => ({
        ...prevProduct,
        [name]: inputValue,
        prix_vente_tarif: updatedTarifPrixVente,
        prix_vente_qte: updatedQuantitePrixVente
      }));
    } else {
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        [name]: inputValue
      }));
    }
  };




  const handleQuantiteChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQuantitePrixVente = newProduct.prix_vente_qte.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [name]: value };
        const montantHt = parseFloat(updatedItem.montant) || 0;
        const tauxTva = parseFloat(newProduct.taux_tva) / 100;
        const montantTaxe = newProduct.tva ? montantHt * tauxTva : 0;
        updatedItem.tva = montantTaxe.toFixed(2);
        updatedItem.ttc = (montantHt + montantTaxe).toFixed(2);
        return updatedItem;
      }
      return item;
    });
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      prix_vente_qte: updatedQuantitePrixVente,
    }));
  };

  const handleAddQuantite = () => {
    setNewProduct({
      ...newProduct,
      prix_vente_qte: [...newProduct.prix_vente_qte, { id_tarif_qte: "", montant: "", tva: "", ttc: "" }],
    });
  };

  const handleRemoveQuantite = (index) => {
    const updatedQuantitePrixVente = newProduct.prix_vente_qte.filter((_, i) => i !== index);
    setNewProduct({ ...newProduct, prix_vente_qte: updatedQuantitePrixVente });
  };

  const handleEditProduct = (index) => {
    setEditMode(true);
    setSelectedProductIndex(index);
    const productToEdit = productData[index];

    // Assurez-vous que magasin_id est toujours un tableau
    const prixVenteTarif = Array.isArray(productToEdit.prix_vente_tarif) ? productToEdit.prix_vente_tarif : [{ id_tarif: "", montant: "", tva: "", ttc: "" }];
    const prixVenteQte = Array.isArray(productToEdit.prix_vente_qte) ? productToEdit.prix_vente_qte : [{ id_tarif_qte: "", montant: "", tva: "", ttc: "" }];
    const magasinId = Array.isArray(productToEdit.magasin_id) ? productToEdit.magasin_id : [];

    setNewProduct({
      photo: productToEdit.photo || "",
      libelle: productToEdit.libelle || "",
      descriptionn: productToEdit.descriptionn || "",
      qte_alerte: productToEdit.qte_alerte || "",
      qte_securite: productToEdit.qte_securite || "",
      categorie_id: productToEdit.categorie_id || "",
      default_prix_achat: productToEdit.default_prix_achat || "",
      magasin_id: magasinId,
      numero_produit: productToEdit.numero_produit || "",
      tva: productToEdit.tva || false,
      prix_vente_tarif: prixVenteTarif,
      prix_vente_qte: prixVenteQte,
      stocke: productToEdit.stocke || false,
      vendable: productToEdit.vendable || false,
      compose: productToEdit.compose || false,
      fabrique: productToEdit.fabrique || false,
      taux_tva: productToEdit.taux_tva || 18
    });

    toggleModal();
  };

  const handleSaveEdit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("numero_produit", newProduct.numero_produit);
      formData.append("libelle", newProduct.libelle);
      formData.append("photo", newProduct.photo);
      formData.append("descriptionn", newProduct.descriptionn);
      formData.append("stocke", newProduct.stocke ? 1 : 0);
      formData.append("vendable", newProduct.vendable ? 1 : 0);
      formData.append("compose", newProduct.compose ? 1 : 0);
      formData.append("fabrique", newProduct.fabrique ? 1 : 0);
      formData.append("prix_achat", formatNumber(newProduct.prix_achat));
      formData.append("tva", newProduct.tva ? 1 : 0);

      // Créer les objets pour prix_vente_tarif et prix_vente_qte
      const prixVenteTarif = (newProduct.prix_vente_tarif || []).map((item, index) => ({
        id_tarif: item.id_tarif || '',
        montant: item.montant || '',
        tva: item.tva || '',
        ttc: item.ttc || '',
      }));

      const prixVenteQte = (newProduct.prix_vente_qte || []).map((item, index) => ({
        id_tarif_qte: item.id_tarif_qte || '',
        montant: item.montant || '',
        tva: item.tva || '',
        ttc: item.ttc || '',
      }));

      // Ajouter les objets à formData
      formData.append("prix_vente_tarif", JSON.stringify(prixVenteTarif));
      formData.append("prix_vente_qte", JSON.stringify(prixVenteQte));


      // Object.keys(newProduct.indicateurs).forEach((key) => {
      //   formData.append(`indicateurs[${key}]`, newProduct.indicateurs[key]);
      // });

      const response = await axios.put(
        `${key.apiBaseURL}/modifierproduit/${productData[selectedProductIndex].id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast.success("Produit mis à jour avec succès");
        setProductData((prevProductData) => {
          const updatedData = [...prevProductData];
          updatedData[selectedProductIndex] = response.data;
          return updatedData;
        });
      } else {
        toast.error("Erreur lors de la mise à jour, veuillez réessayer");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du produit", error.response || error);
      toast.error("Erreur, veuillez réessayer");
    }

    setNewProduct({
      photo: "",
      libelle: "",
      descriptionn: "",
      qte_alerte: "",
      qte_securite: "",
      categorie_id: "",
      default_prix_achat: "",
      magasin_id: [],
      numero_produit: "",
      tva: false,
      prix_vente_tarif: [{ id_tarif: "", montant: "", tva: "", ttc: "" }],
      prix_vente_qte: [{ id_tarif_qte: "", montant: "", tva: "", ttc: "" }],
      stocke: false,
      vendable: false,
      compose: false,
      fabrique: false,
      taux_tva: 18
    });
    setErrors({});
    setFormPage(1);
    setModal(false);
    setEditMode(false);
    setSelectedProductIndex(null);
  };

  const handleDeleteProduct = async () => {
    if (!productToDelete) return;

    try {
      const response = await axios.delete(`${key.apiBaseURL}/supprimerproduit/${productToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 201) {
        toast.success("Produit supprimé avec succès");
        setProductData(productData.filter((product) => product.id !== productToDelete.id));
        closeConfirmModal();
      } else {
        toast.error("Erreur, veuillez réessayer");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du produit", error.response || error);
      toast.error("Erreur, veuillez réessayer");
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = Array.isArray(listProduit) ? listProduit.filter(item =>
    item.libelle && item.libelle.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];



  return (
    <div className="container">
      <Row>

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center" style={{ marginTop: '90px', marginBottom: '20px' }}>
          <Col><Button color="primary" onClick={toggleModal}>
            Ajouter un produit
          </Button></Col>
          <Col>
            <input
              type="text"
              placeholder="Rechercher un produit"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control w-100 w-md-25"
            /></Col>
        </div>


      </Row>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>Photo</th>
            <th>Libellé</th>
            <th>Description</th>
            <th>Catégorie</th>
            <th>Prix d'achat</th>
            <th>Stocké</th>
            <th>Vendable</th>
            <th>Composé</th>
            <th>Fabriqué</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {productData.map((product, index) => (
            <tr key={index}>
              <td>
                {product.photo ? (
                  <img src={`${key.produitBaseURL}/${product.photo}`} alt={product.libelle} style={{ width: '50px' }} />
                ) : (
                  "Aucune photo"
                )}
              </td>
              <td>{product.libelle}</td>
              <td>{product.description}</td>
              <td>{product.categorie}</td>
              <td>{product.prix_achat_actuel}</td>
              <td className="text-center">
                {product.stocke === 1 ? (
                  <i className="bx bx-check-circle" style={{ color: 'green' }}></i>
                ) : (
                  <i className="bx bx-x-circle" style={{ color: 'red' }}></i>
                )}
              </td>
              <td className="text-center">
                {product.vendable === 1 ? (
                  <i className="bx bx-check-circle" style={{ color: 'green' }}></i>
                ) : (
                  <i className="bx bx-x-circle" style={{ color: 'red' }}></i>
                )}
              </td>
              <td className="text-center">
                {product.compose === 1 ? (
                  <i className="bx bx-check-circle" style={{ color: 'green' }}></i>
                ) : (
                  <i className="bx bx-x-circle" style={{ color: 'red' }}></i>
                )}
              </td>
              <td className="text-center">
                {product.fabrique === 1 ? (
                  <i className="bx bx-check-circle" style={{ color: 'green' }}></i>
                ) : (
                  <i className="bx bx-x-circle" style={{ color: 'red' }}></i>
                )}
              </td>
              <td>
                <div className="btn-group" role="group">
                  <Link to="#" className="text-truncate me-4" onClick={() => handleEditProduct(index)}>
                    <i className="uil uil-pen font-size-16" id="edittooltip" />
                  </Link>
                  <Link to="#" className="text-truncate me-4 text-danger" onClick={() => openConfirmModal(index)}>
                    <i className="uil uil-trash-alt font-size-16" id="deletetooltip" />
                  </Link>
                  <Link to="#" className="text-truncate text-success" >
                    <i className="uil uil-eye font-size-16" id="viewtooltip" />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {editMode ? "Modifier le produit" : "Ajouter un produit"}
        </ModalHeader>
        <ModalBody>
          <Form>
            {formPage === 1 && (
              <div>
                <FormGroup>
                  <Label for="numero_produit">
                    Numéro de référence <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="numero_produit"
                    id="numero_produit"
                    value={newProduct.numero_produit}
                    onChange={handleInputChange}
                    className="border-secondary"
                  />
                  {errors.numero_produit && <p className="text-danger">{errors.numero_produit}</p>}
                </FormGroup>
                <FormGroup>
                  <Label for="photo">Photo <span className="text-danger">*</span></Label>
                  <Input
                    type="file"
                    name="photo"
                    id="photo"
                    onChange={handlePhotoChange}
                    className="border-secondary"
                  />
                  {errors.photo && <p className="text-danger">{errors.photo}</p>}
                </FormGroup>
                <FormGroup>
                  <Label for="libelle">
                    Libellé <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="libelle"
                    id="libelle"
                    value={newProduct.libelle}
                    onChange={handleInputChange}
                    className="border-secondary"
                  />
                  {errors.libelle && <p className="text-danger">{errors.libelle}</p>}
                </FormGroup>
                <FormGroup>
                  <Label for="descriptionn">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="descriptionn"
                    id="descriptionn"
                    value={newProduct.descriptionn}
                    onChange={handleInputChange}
                    onBlur={handleSave.handleBlur}
                    className="border-secondary"
                  />
                  {errors.descriptionn && <p className="text-danger">{errors.descriptionn}</p>}
                </FormGroup>
                <FormGroup>
                  <Label for="qte_alerte">
                    Quantité d'alerte <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="qte_alerte"
                    id="qte_alerte"
                    value={newProduct.qte_alerte}
                    onChange={handleInputChange}
                    className="border-secondary"
                  />
                  {errors.qte_alerte && <p className="text-danger">{errors.qte_alerte}</p>}
                </FormGroup>
                <div className="mt-3 d-flex justify-content-between">
                  <Button color="secondary" size="sm" onClick={toggleModal}>
                    Annuler
                  </Button>
                  <Button color="primary" size="sm" onClick={() => setFormPage(2)}>
                    Suivant
                  </Button>
                </div>
              </div>
            )}
            {formPage === 2 && (
              <div>
                <FormGroup>
                  <Label for="qte_securite">
                    Quantité de sécurité <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="qte_securite"
                    id="qte_securite"
                    value={newProduct.qte_securite}
                    onChange={handleInputChange}
                    className="border-secondary"
                  />
                  {errors.qte_securite && <p className="text-danger">{errors.qte_securite}</p>}
                </FormGroup>
                <FormGroup>
                  <Label for="categorie_id">
                    Catégorie <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="categorie_id"
                    value={newProduct.categorie_id}
                    onChange={(e) => setNewProduct({ ...newProduct, categorie_id: e.target.value })}
                    className={`border-secondary ${errors.categorie_id ? "is-invalid" : ""}`}
                  >
                    <option value=''>Selectionnez une categorie</option>
                    {listCategorie && listCategorie.map((item, index) => (
                      <option value={item.id} key={index}>{item.nom}</option>
                    ))}
                  </Input>
                  {errors.categorie_id && <div className="invalid-feedback">{errors.categorie_id}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="magasin_id">
                    Magasin <span className="text-danger">*</span>
                  </Label>
                  <Select
                    id="magasin_id"
                    isMulti
                    value={selectOptions.filter(option => newProduct.magasin_id.includes(option.value))}
                    onChange={handleSelectChange}
                    options={selectOptions}
                    className={`border-secondary ${errors.magasin_id ? "is-invalid" : ""}`}
                  />
                  {errors.magasin_id && <div className="invalid-feedback">{errors.magasin_id}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="default_prix_achat">
                    Prix d'achat <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="default_prix_achat"
                    id="default_prix_achat"
                    value={newProduct.default_prix_achat}
                    onChange={handleInputChange}
                    className="border-secondary"
                  />
                  {errors.default_prix_achat && <p className="text-danger">{errors.default_prix_achat}</p>}
                </FormGroup>
                <div className="mt-3 d-flex justify-content-between">
                  <Button color="secondary" size="sm" onClick={() => setFormPage(1)}>
                    Retour
                  </Button>
                  <Button color="primary" size="sm" onClick={() => setFormPage(3)}>
                    Suivant
                  </Button>
                </div>
              </div>
            )}
            {formPage === 3 && (
              <div>
                <FormGroup check className="d-flex align-items-center">
                  <Label check className="me-2">
                    <Input
                      type="checkbox"
                      name="tva"
                      checked={newProduct.tva}
                      onChange={handleInputChange}
                      className="border-secondary"
                    />
                    TVA
                  </Label>
                  {newProduct.tva && (
                    <Input
                      type="number"
                      name="taux_tva"
                      value={newProduct.taux_tva}
                      onChange={handleInputChange}
                      className="border-secondary me-2"
                      style={{ width: '100px' }}
                    />
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="tarif_prix_vente">
                    Tarif prix de vente <span className="text-danger">*</span>
                  </Label>
                  {newProduct.prix_vente_tarif.map((tarif, index) => (
                    <div key={index} className="d-flex mb-2">
                      <Input
                        type="select"
                        name="id_tarif"
                        value={tarif.id_tarif}
                        onChange={(e) => handleTarifChange(index, e)}
                        className="border-secondary me-2"
                      >
                        <option value="">Sélectionnez un tarif</option>
                        {listTarif.map((item, idx) => (
                          <option value={item.id} key={idx}>{item.designation_du_tarif}</option>
                        ))}
                      </Input>
                      <Input
                        type="number"
                        name="montant"
                        placeholder="Montant HT"
                        value={tarif.montant}
                        onChange={(e) => handleTarifChange(index, e)}
                        className="border-secondary me-2"
                      />
                      {newProduct.tva && (
                        <Input
                          type="number"
                          name="tva"
                          placeholder="TVA"
                          value={tarif.tva}
                          readOnly
                          className="border-secondary me-2"
                        />
                      )}
                      <Input
                        type="number"
                        name="ttc"
                        placeholder="TTC"
                        value={tarif.ttc}
                        readOnly
                        className="border-secondary me-2"
                      />
                      <Button color="danger" size="sm" onClick={() => handleRemoveTarif(index)} className="ms-2">-</Button>
                    </div>
                  ))}
                  <Button color="primary" size="sm" onClick={handleAddTarif}>Ajouter un tarif</Button>
                </FormGroup>

                <FormGroup>
                  <Label for="quantite_prix_vente">
                    Quantité prix de vente <span className="text-danger">*</span>
                  </Label>
                  {newProduct.prix_vente_qte.map((quantite, index) => (
                    <div key={index} className="d-flex mb-2">
                      <Input
                        type="select"
                        name="id_tarif_qte"
                        value={quantite.id_tarif_qte}
                        onChange={(e) => handleQuantiteChange(index, e)}
                        className="border-secondary me-2"
                      >
                        <option value="">Sélectionnez une quantité</option>
                        {listQuantite.map((item, idx) => (
                          <option value={item.id} key={idx}>{`${item.qte_debut} à ${item.qte_fin}`}</option>
                        ))}
                      </Input>
                      <Input
                        type="number"
                        name="montant"
                        placeholder="Montant HT"
                        value={quantite.montant}
                        onChange={(e) => handleQuantiteChange(index, e)}
                        className="border-secondary me-2"
                      />
                      {newProduct.tva && (
                        <Input
                          type="number"
                          name="tva"
                          placeholder="TVA"
                          value={quantite.tva}
                          readOnly
                          className="border-secondary me-2"
                        />
                      )}
                      <Input
                        type="number"
                        name="ttc"
                        placeholder="TTC"
                        value={quantite.ttc}
                        readOnly
                        className="border-secondary me-2"
                      />
                      <Button color="danger" size="sm" onClick={() => handleRemoveQuantite(index)} className="ms-2">-</Button>
                    </div>
                  ))}
                  <Button color="primary" size="sm" onClick={handleAddQuantite}>Ajouter une quantité</Button>
                </FormGroup>

                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="stocke"
                          checked={newProduct.stocke}
                          onChange={handleInputChange}
                          className="border-secondary"
                        />
                        Stocké
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="fabrique"
                          checked={newProduct.fabrique}
                          onChange={handleInputChange}
                          className="border-secondary"
                        />
                        Fabriqué
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="vendable"
                          checked={newProduct.vendable}
                          onChange={handleInputChange}
                          className="border-secondary"
                        />
                        Vendable
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="compose"
                          checked={newProduct.compose}
                          onChange={handleInputChange}
                          className="border-secondary"
                        />
                        Composé
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="mt-3 d-flex justify-content-between">
                  <Button color="secondary" size="sm" onClick={() => setFormPage(2)}>
                    Retour
                  </Button>
                  <Button color="primary" size="sm" onClick={editMode ? handleSaveEdit : handleSave}>
                    {editMode ? "Enregistrer" : "Ajouter"}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={confirmModal} toggle={closeConfirmModal}>
        <ModalHeader toggle={closeConfirmModal}>
          Confirmation de la suppression
        </ModalHeader>
        <ModalBody>
          Êtes-vous sûr de vouloir supprimer ce produit ?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteProduct}>
            Supprimer
          </Button>
          <Button color="secondary" onClick={closeConfirmModal}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductsList;
