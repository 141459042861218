import React, { useState } from "react";
import { Col, Row, Card, CardBody, CardTitle, Container, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ConsumptionCenter = () => {
  document.title = "Minible | Centre de consommation";

  const [consumptionData, setConsumptionData] = useState([
    { productName: "Savon", productCode: "001", quantity: 5, price: 1000, store: "Magasin A" },
    { productName: "Biscuit", productCode: "002", quantity: 10, price: 1500, store: "Magasin B" },
    // Add more initial data as needed
  ]);

  const [newConsumption, setNewConsumption] = useState({ productName: "", productCode: "", quantity: "", price: "", store: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedConsumptionIndex, setSelectedConsumptionIndex] = useState(null);

  const toggleModal = () => setModal(!modal);

  const handleAddConsumption = () => {
    // Verify if all fields are filled
    if (
      newConsumption.productName.trim() === "" ||
      newConsumption.productCode.trim() === "" ||
      newConsumption.quantity.trim() === "" ||
      newConsumption.price.trim() === "" ||
      newConsumption.store.trim() === ""
    ) {
      alert("Veuillez remplir tous les champs avant de soumettre la consommation.");
      return;
    }

    if (editMode) {
      const updatedConsumptions = [...consumptionData];
      updatedConsumptions[selectedConsumptionIndex] = newConsumption;
      setConsumptionData(updatedConsumptions);
      setEditMode(false);
    } else {
      setConsumptionData([newConsumption, ...consumptionData]); // Add the new consumption at the top of the list
    }
    setNewConsumption({ productName: "", productCode: "", quantity: "", price: "", store: "" });
    toggleModal();
  };

  const handleEditConsumption = (index) => {
    setSelectedConsumptionIndex(index);
    setNewConsumption(consumptionData[index]);
    setEditMode(true);
    toggleModal();
  };

  const handleDeleteConsumption = (index) => {
    if (window.confirm("Voulez-vous vraiment supprimer cette consommation ?")) {
      const updatedConsumptions = [...consumptionData];
      updatedConsumptions.splice(index, 1);
      setConsumptionData(updatedConsumptions);
    }
  };

  const filteredConsumptions = consumptionData.filter(item =>
    item.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredConsumptions.length / itemsPerPage);

  const getCurrentData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredConsumptions.slice(startIndex, endIndex);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Centre de consommation" breadcrumbItem="Stocks" />

          <div className="mb-3 d-flex align-items-center">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Rechercher un produit..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </div>
            <Button color="primary" className="ml-3" onClick={toggleModal}>
              Ajouter une consommation
            </Button>
          </div>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Centre de consommation</CardTitle>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Produit</th>
                          <th>Code</th>
                          <th>Quantité</th>
                          <th>Prix</th>
                          <th>Magasin</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getCurrentData().map((item, index) => (
                          <tr key={index}>
                            <td>{item.productName}</td>
                            <td>{item.productCode}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                            <td>{item.store}</td>
                            <td>
                              <Button color="warning" size="sm" className="mr-2" onClick={() => handleEditConsumption(index)}>Modifier</Button>
                              <Button color="danger" size="sm" onClick={() => handleDeleteConsumption(index)}>Supprimer</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination className="pagination justify-content-end mt-3">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                    </PaginationItem>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <PaginationItem key={index} active={currentPage === index + 1}>
                        <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                          {index + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                    </PaginationItem>
                  </Pagination>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{editMode ? "Modifier" : "Ajouter"} une consommation</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="productName">Produit</Label>
                <Input
                  type="text"
                  id="productName"
                  value={newConsumption.productName}
                  onChange={(e) => setNewConsumption({ ...newConsumption, productName: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="productCode">Code</Label>
                <Input
                  type="text"
                  id="productCode"
                  value={newConsumption.productCode}
                  onChange={(e) => setNewConsumption({ ...newConsumption, productCode: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quantity">Quantité</Label>
                <Input
                  type="number"
                  id="quantity"
                  value={newConsumption.quantity}
                  onChange={(e) => setNewConsumption({ ...newConsumption, quantity: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="price">Prix</Label>
                <Input
                  type="number"
                  id="price"
                  value={newConsumption.price}
                  onChange={(e) => setNewConsumption({ ...newConsumption, price: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="store">Magasin</Label>
                <Input
                  type="text"
                  id="store"
                  value={newConsumption.store}
                  onChange={(e) => setNewConsumption({ ...newConsumption, store: e.target.value })}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleAddConsumption}>{editMode ? "Modifier" : "Ajouter"}</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Annuler</Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ConsumptionCenter;
