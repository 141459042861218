import React, { useState } from "react";
import { Col, Row, Card, CardBody, CardTitle, Container, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const InventoryPage = () => {
  document.title = "Obidon | Inventaire des actifs";

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Nombre d'éléments par page

  const inventoryData = [
    { productName: "Lait", productCode: "1234", quantity: 100, unitPrice: 1.5, totalValue: 150, location: "Rayon Lait", lastUpdated: "10/05/2024", reorderThreshold: 20 },
    { productName: "Pain", productCode: "5678", quantity: 75, unitPrice: 2, totalValue: 150, location: "Rayon Boulangerie", lastUpdated: "09/05/2024", reorderThreshold: 15 },
    // Ajoutez d'autres données au besoin
  ];

  const filteredInventory = inventoryData.filter(item =>
    item.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calcul du nombre total de pages
  const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);

  // Fonction pour obtenir les données de la page actuelle
  const getCurrentData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredInventory.slice(startIndex, endIndex);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Inventaires des actifs" breadcrumbItem="Stocks" />

          {/* Barre de recherche */}
          <div className="mb-3 d-flex align-items-center">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Rechercher un produit..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} 
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </div>
          </div>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Inventaire des actifs</CardTitle>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Nom du produit</th>
                          <th>Code produit</th>
                          <th>Quantité en stock</th>
                          <th>Prix unitaire</th>
                          <th>Coût total</th>
                          <th>Emplacement</th>
                          <th>Dernière mise à jour</th>
                          <th>Seuil de réapprovisionnement</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getCurrentData().map((item, index) => (
                          <tr key={index}>
                            <td>{item.productName}</td>
                            <td>{item.productCode}</td>
                            <td>{item.quantity}</td>
                            <td>{item.unitPrice}</td>
                            <td>{item.totalValue}</td>
                            <td>{item.location}</td>
                            <td>{item.lastUpdated}</td>
                            <td>{item.reorderThreshold}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination */}
                  <Pagination className="pagination justify-content-end mt-3">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                    </PaginationItem>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <PaginationItem key={index} active={currentPage === index + 1}>
                        <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                          {index + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                    </PaginationItem>
                  </Pagination>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InventoryPage;


