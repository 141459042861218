import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerUser";
import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import BreadcrumbsAddUsers from "../../components/Common/BreadcrumbUsers";
import { Col, Row, Card, CardBody, Label, Input, Button } from "reactstrap";

function RapportRelanceClient(props) {
  document.title = "Comptes Clients";

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [type_de_compte, setType_de_compte] = useState("");
  const [client_decroche, setClient_decroche] = useState(false);
  const [compte, setCompte] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const getAllCompte = async () => {
    try {
      const response = await axios.get(
        key.apiBaseURL + "/affichertoutcompteclient",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response) {
        setCompte(response.data);
      }
    } catch (error) {
      console.log("erreur :: ", error.response);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllCompte();
    }
  }, [accessToken]);

  const type_compte = [
    { id: 1, label: "PARTICULIER" },
    { id: 2, label: "ETABLISSEMENT" },
    { id: 3, label: "GROUPE ENTREPRISE" },
    { id: 4, label: "ONG/ASSOCIATION" },
    { id: 5, label: "SOCIETE" }
  ];

  const columns = useMemo(
    () => [
      {
        Header: "N°",
        accessor: "id",
        filterable: true,
        Cell: (cellProps) => (
          <span>{cellProps.row.original.id}</span>
        ),
      },
      {
        Header: "Intitulé Client",
        accessor: "intitule_client",
        filterable: true,
        Cell: (cellProps) => (
          <span>{cellProps.row.original.intitule_client}</span>
        ),
      },
      {
        Header: "Pièce à régulariser",
        accessor: "adresse_client",
        filterable: true,
        Cell: (cellProps) => (
          <div>
            {cellProps.row.original.type_de_compte === 1 && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : null}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : null}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : null}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : null}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : null}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : null}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : null}</p>
                <p>{cellProps.row.original.cip_attestation_residence === 0 ? "CIP/Attestation de résidence" : null}</p>
                <p>{cellProps.row.original.justificatif_activite === 0 ? "Justificatif d'activité" : null}</p>
              </>
            )}
            {cellProps.row.original.type_de_compte === 2 && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : null}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : null}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : null}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : null}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : null}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : null}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : null}</p>
                <p>{cellProps.row.original.registre__commerce === 0 ? "Registre de commerce" : null}</p>
                <p>{cellProps.row.original.date_registre_commerce === 0 ? "Date du registre de commerce" : null}</p>
                <p>{cellProps.row.original.registre_commerce_moins_3_mois === 0 ? "Registre de commerce (moins de 3 mois) ?" : null}</p>
                <p>{cellProps.row.original.facture_quitance_SBEE_SONEB === 0 ? "Facture ou quittance SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.date_facture_SBEE_SONEB === 0 ? "Date de la facture SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.fact_SBEE_SONEB_moins_trois_mois === 0 ? "Facture SBEE / SONEB moins 03 mois" : null}</p>
              </>
            )}
            {(cellProps.row.original.type_de_compte === 3 || cellProps.row.original.type_de_compte === 5) && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : null}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : null}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : null}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : null}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : null}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : null}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : null}</p>
                <p>{cellProps.row.original.registre__commerce === 0 ? "Registre de commerce" : null}</p>
                <p>{cellProps.row.original.date_registre_commerce === 0 ? "Date du registre de commerce" : null}</p>
                <p>{cellProps.row.original.registre_commerce_moins_3_mois === 0 ? "Registre de commerce (moins de 3 mois) ?" : null}</p>
                <p>{cellProps.row.original.facture_quitance_SBEE_SONEB === 0 ? "Facture ou quittance SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.date_facture_SBEE_SONEB === 0 ? "Date de la facture SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.fact_SBEE_SONEB_moins_trois_mois === 0 ? "Facture SBEE / SONEB moins 03 mois" : null}</p>
                <p>{cellProps.row.original.piece_identite_a_25_part === 0 ? "Pièce d'identite actionnaire ayant au moins 25%" : null}</p>
                <p>{cellProps.row.original.copie_status === 0 ? "Copie Statut" : null}</p>
              </>
            )}
            {cellProps.row.original.type_de_compte === 4 && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : null}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : null}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : null}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : null}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : null}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : null}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : null}</p>
                <p>{cellProps.row.original.facture_quitance_SBEE_SONEB === 0 ? "Facture ou quittance SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.date_facture_SBEE_SONEB === 0 ? "Date de la facture SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.fact_SBEE_SONEB_moins_trois_mois === 0 ? "Facture SBEE / SONEB moins 03 mois" : null}</p>
                <p>{cellProps.row.original.piece_identite_a_25_part === 0 ? "Pièce d'identite actionnaire ayant au moins 25%" : null}</p>
                <p>{cellProps.row.original.copie_reglement_interieur === 0 ? "Copie règlement interieur" : null}</p>
                <p>{cellProps.row.original.copie_acte_enregistrement === 0 ? "Copie acte enregistrement" : null}</p>
                <p>{cellProps.row.original.copie_publication_journal_officiel === 0 ? "Copie publication journal officiel" : null}</p>
                <p>{cellProps.row.original.copie_dernier_rapport === 0 ? "Copie dernier rapport" : null}</p>
                <p>{cellProps.row.original.articles41_42_43 === 0 ? "Articles 41_42_43" : null}</p>
              </>
            )}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        Cell: (cellProps) => (
          <span>{cellProps.row.original.date}</span>
        ),
      },
      {
        Header: "Type de relance",
        accessor: "type_de_relance",
        filterable: true,
        Cell: (cellProps) => (
          <span>{cellProps.row.original.type_de_relance}</span>
        ),
      },
      {
        Header: "Commentaire avec client",
        accessor: "commentaire_avec_client",
        filterable: true,
        Cell: (cellProps) => (
          <span>{cellProps.row.original.commentaire_avec_client}</span>
        ),
      },
    ],
    []
  );

  const handleFilter = async () => {
    try {
      const response = await axios.post(
        key.apiBaseURL + "/filtercompteclient",
        {
          type_de_compte,
          startDate,
          endDate,
          client_decroche
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response) {
        setCompte(response.data);
      }
    } catch (error) {
      console.log("erreur :: ", error.response);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <div className="container-fluid">
          <BreadcrumbsAddUsers title="" breadcrumbItem="Comptes clients" />
          <Row>
            <Col>
              <div className="mb-3">
                <Link to="#" className="btn btn-success waves-effect waves-light ms-auto">
                  Imprimer
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-pancard-input5">
                  Type de compte
                </Label>
                <Input
                  type="select"
                  className="form-control"
                  id="basicpill-pancard-input5"
                  placeholder="Type de compte"
                  name="type_de_compte"
                  onChange={(e) => setType_de_compte(e.target.value)}
                  value={type_de_compte}
                >
                  <option value={''}>Selectionnez une option</option>
                  {
                    type_compte && type_compte.map((item, index) => (
                      <option key={index} value={item.id}>{item.label}</option>
                    ))
                  }
                </Input>
              </div>
            </Col>
            <Row className="mb-3">
              <Label for="date-range" id="date-range">Date</Label>
            </Row>
            <Row className="align-items-center">
              <Col>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-2"><strong>De:</strong> </p>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="De"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </Col>
              <Col>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-2"><strong>à:</strong> </p>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="À"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Col lg="12">
              <div className="mb-3">
                <Label>Critères</Label>
                <div className="d-flex flex-wrap">
                  <div className="form-check form-switch me-3 mb-2">
                    <Input
                      type="switch"
                      id="client_decroche"
                      checked={client_decroche}
                      onChange={(e) => setClient_decroche(e.target.checked)}
                    />
                    <Label for="client_decroche">Client décroché</Label>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" className="mt-3">
              <Button color="primary" onClick={handleFilter}>Valider</Button>
            </Col>
          </Row>
          <Row>
            <Col className="bg-white">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={compte || []}
                    isGlobalFilter={true}
                    isAddUsers={true}
                    isAddTableWithoutBorderStrap={true}
                    handleUserClicks={""}
                    customPageSize={50}
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

RapportRelanceClient.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default RapportRelanceClient;
