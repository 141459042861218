import {
    GET_MYCONTACTS_SUCCESS,
    GET_MYCONTACTS_FAIL,
    GET_MYCONTACT_INFO_SUCCESS,
    GET_MYCONTACT_INFO_FAIL,
    ADD_MYCONTACT_SUCCESS,
    ADD_MYCONTACT_FAIL,
    UPDATE_MYCONTACT_SUCCESS,
    UPDATE_MYCONTACT_FAIL,
    DELETE_MYCONTACT_SUCCESS,
    DELETE_MYCONTACT_FAIL
    
  } from "./actionTypes";
  
  const INIT_STATE = {
    mycontacts: [],
    mycontactInfo: {},
    error: {},
  };
  
  const MyContacts = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_MYCONTACTS_SUCCESS:
        return {
          ...state,
          mycontacts: action.payload,
        };
  
      case GET_MYCONTACTS_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case ADD_MYCONTACT_SUCCESS:
        return {
          ...state,
          mycontacts: [...state.mycontacts, action.payload],
        };
  
      case ADD_MYCONTACT_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case UPDATE_MYCONTACT_SUCCESS:
        return {
          ...state,
          mycontacts: state.mycontacts.map(mycontact =>
          mycontact.id.toString() === action.payload.id.toString()
              ? { mycontact, ...action.payload }
              : mycontact
          ),
        };
  
      case UPDATE_MYCONTACT_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case DELETE_MYCONTACT_SUCCESS:
        return {
          ...state,
          mycontacts: state.mycontacts.filter(
            mycontact => mycontact.id.toString() !== action.payload.toString()
          ),
        };
  
      case DELETE_MYCONTACT_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case GET_MYCONTACT_INFO_SUCCESS:
        return {
          ...state,
          mycontactInfo: action.payload,
        };
  
      case GET_MYCONTACT_INFO_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default MyContacts;
  