import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerUser";
import * as XLSX from 'xlsx';
//import components
import BreadcrumbsAddUsers from "../../components/Common/BreadcrumbUsers";
import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button
} from "reactstrap";
import DeleteModal from "../../components/Common/DeleteModalUsers";
import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/ReactToastify.css'
import moment from "moment";
import classnames from "classnames"

function CompteClient(props) {
  document.title = "Comptes Clients";

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [allCompte, setAllCompte] = useState()
  const getAllCompte = async()=>{
    try {
      const response = await axios.get(
        key.apiBaseURL + "/affichertoutcompteclient",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if ( response ) {
        console.log(response.data)
        setAllCompte(response.data)
      }
    } catch (error) {
      console.log("erreur :: ", error.response)
    }
  }
  useEffect(()=>{
    if ( accessToken ) {
      console.log("Access token")
      getAllCompte()
    }
  }, [accessToken])

  //delete user
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = async (user) => {
    try {
      console.log(user.id)
      const response = axios.get(
        `${key.apiBaseURL}/supprimercompteclient`,
        {
          params: {
            id: user.id
          },
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if ( response ) {
        toast.success("Supprimer avec succès")
        getAllCompte()
      }
    } catch (error) {
      console.log("Echec de la suppression :: ", error.response)
      toast.error("Echec de la suppression")
    }
  };

  const [update, setUpdate] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const columns = useMemo(
    () => [
      {
        Header: "N°",
        accessor: "id",
        filterable: true,
        Cell: (cellProps) => (
          <span>{ cellProps.row.original.id }</span>
        ),
      },
      {
        Header: "Intitulé Client",
        accessor: "intitule_client",
        filterable: true,
        Cell: (cellProps) => (
            <span>{ cellProps.row.original.intitule_client }</span>
        ),
      },
      {
        Header: "Adresse client",
        accessor: "adresse_client",
        filterable: true,
        Cell: (cellProps) => (
            <span>{ cellProps.row.original.adresse_client }</span>
        ),
      },
      {
        Header: "Téléphone Fix",
        accessor: "tel_fixe_client",
        filterable: true,
        Cell: (cellProps) => (
            <span>{ cellProps.row.original.tel_fixe_client }</span>
        ),
      },
      
      {
        Header: "Téléphone Mobile",
        accessor: "mobile",
        filterable: true,
        Cell: (cellProps) => (
            <span>{ cellProps.row.original.mobile }</span>
        ),
      },
      
      {
        Header: "Type de compte",
        accessor: "type_de_compte",
        filterable: true,
        Cell: (cellProps) => (
            <span>{ cellProps.row.original.type_de_compte }</span>
        ),
      },
      
      {
        Header: "Date d'ouverture",
        accessor: "date_ouverture",
        filterable: true,
        Cell: (cellProps) => (
            <span>{ cellProps.row.original.date_ouverture ? cellProps.row.original.date_ouverture.split("T")[0] : null }</span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => (
          <div className="d-flex gap-3 users">
            <ul className="list-inline font-size-20 contact-links mb-0">
              <li className="list-inline-item">
                <Link
                  to={`#`}
                  // to={`/modifier-compte-client/${cellProps.row.original.id}`}
                  onClick={()=>{
                    setUpdate(true)
                    setSelectedId(cellProps.row.original.id)
                  }}
                  className="text-primary"
                >
                  <i className="uil uil-pen font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Éditer
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const user = cellProps.row.original;
                    onClickDelete(user);
                  }}
                >
                  <i className="uil uil-trash-alt font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Supprimer
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li className="list-inline-item dropdown">
                <Link to={`/detail-compte-client/${cellProps.row.original.id}`} className="text-truncate text-success">
                  <i className="uil uil-eye font-size-16" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    Vue
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          </div>
        ),
      },
    ],
    []
  );

  const currentDate = moment().format("YYYY-MM-DD")
  const [num_compte, setNum_compte] = useState("")
  const [intitule_client, setIntitule_client] = useState("")
  const [adresse_client, setAdresse_client] = useState("")
  const [tel_fixe_client, setTel_fixe_client] = useState("")
  const [mobile, setMobile] = useState("")
  const [type_de_compte, setType_de_compte] = useState("")
  const [date_ouverture, setDate_ouverture] = useState("")
  const [registre__commerce, setRegistre__commerce] = useState(false)
  const [date_registre_commerce, setDate_registre_commerce] = useState("")
  const [registre_commerce_moins_3_mois, setRegistre_commerce_moins_3_mois] = useState(false)
  const [copie_ifu, setCopie_ifu] = useState(false)
  const [piece_identite, setPiece_identite] = useState(false)
  const [copie_piece_identite, setCopie_piece_identite] = useState(false)
  const [deux_photo_identite, setDeux_photo_identite] = useState(false)
  const [facture_quitance_SBEE_SONEB, setFacture_quitance_SBEE_SONEB] = useState(false)
  const [date_facture_SBEE_SONEB, setDate_facture_SBEE_SONEB] = useState("")
  const [fact_SBEE_SONEB_moins_trois_mois, setFact_SBEE_SONEB_moins_trois_mois] = useState(false)
  const [formulaire_EER, setFormulaire_EER] = useState(false)
  const [formulaire_KYC, setFormulaire_KYC] = useState(false)
  const [formulaire_BIC, setFormulaire_BIC] = useState(false)
  const [piece_identite_a_25_part, setPiece_identite_a_25_part] = useState(false)
  const [copie_status, setCopie_status] = useState(false)
  const [copie_reglement_interieur, setCopie_reglement_interieur] = useState(false)
  const [copie_acte_enregistrement, setCopie_acte_enregistrement] = useState(false)
  const [copie_publication_journal_officiel, setCopie_publication_journal_officie] = useState(false)
  const [copie_dernier_rapport, setCopie_dernier_rapport] = useState(false)
  const [articles41_42_43, setArticles41_42_43] = useState(false)
  const [cip_attestation_residence, setCip_attestation_residence] = useState(false)
  const [justificatif_activite, setJustificatif_activite] = useState(false)

  const [compte, setCompte] = useState()
  const getCompte = async()=>{
    try {
      const response = await axios.get(
        key.apiBaseURL + "/afficheruncompteclient",
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            params: {
                id: selectedId
            }
        }
      )
      if ( response ) {
        console.log("current compte :: ",response.data)
        setCompte(response.data)
      }
    } catch (error) {
      console.log("erreur :: ", error.response)
    }
  }
  useEffect(()=>{
      if ( accessToken && selectedId ) {
          getCompte()
      }
  }, [accessToken, selectedId])

  useEffect(()=>{
    if ( compte ) {
      setNum_compte(compte.num_compte)
      setIntitule_client(compte.intitule_client)
      setAdresse_client(compte.adresse_client)
      setTel_fixe_client(compte.tel_fixe_client)
      setMobile(compte.mobile)
      setType_de_compte(compte.type_de_compte)
      setDate_ouverture(compte.date_ouverture && compte.date_ouverture.split("T")[0])
      setRegistre__commerce(compte.registre__commerce === 1 ? true : false)
      setDate_registre_commerce(compte.date_registre_commerce && compte.date_registre_commerce.split("T")[0])
      setRegistre_commerce_moins_3_mois(compte.registre_commerce_moins_3_mois === 1 ? true : false)
      setCopie_ifu(compte.copie_ifu === 1 ? true : false)
      setPiece_identite(compte.piece_identite === 1 ? true : false)
      setCopie_piece_identite(compte.copie_piece_identite === 1 ? true : false)
      setDeux_photo_identite(compte.deux_photo_identite === 1 ? true : false)
      setFacture_quitance_SBEE_SONEB(compte.facture_quitance_SBEE_SONEB === 1 ? true : false)
      setDate_facture_SBEE_SONEB(compte.date_facture_SBEE_SONEB && compte.date_facture_SBEE_SONEB.split("T")[0])
      setFact_SBEE_SONEB_moins_trois_mois(compte.fact_SBEE_SONEB_moins_trois_mois === 1 ? true : false)
      setFormulaire_EER(compte.formulaire_EER === 1 ? true : false)
      setFormulaire_KYC(compte.formulaire_KYC === 1 ? true : false)
      setFormulaire_BIC(compte.formulaire_BIC === 1 ? true : false)
      setPiece_identite_a_25_part(compte.piece_identite_a_25_part === 1 ? true : false)
      setCopie_status(compte.copie_status === 1 ? true : false)
      setCopie_reglement_interieur(compte.copie_reglement_interieur === 1 ? true : false)
      setCopie_acte_enregistrement(compte.copie_acte_enregistrement === 1 ? true : false)
      setCopie_publication_journal_officie(compte.copie_publication_journal_officiel === 1 ? true : false)
      setCopie_dernier_rapport(compte.copie_dernier_rapport === 1 ? true : false)
      setArticles41_42_43(compte.articles41_42_43 === 1 ? true : false)
      setCip_attestation_residence(compte.cip_attestation_residence === 1 ? true : false)
      setJustificatif_activite(compte.justificatif_activite === 1 ? true : false)
    }
  }, [compte])

  const [activeTab, setactiveTab] = useState(1)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
      }
    }
  }

  const updateCompte = async () => {
    try {
      const data = {
        intitule_client,
        adresse_client,
        tel_fixe_client,
        mobile,
        type_de_compte,
        date_ouverture,
        registre__commerce,
        date_registre_commerce,
        registre_commerce_moins_3_mois,
        copie_ifu,
        piece_identite,
        copie_piece_identite,
        deux_photo_identite,
        facture_quitance_SBEE_SONEB,
        date_facture_SBEE_SONEB,
        fact_SBEE_SONEB_moins_trois_mois,
        formulaire_EER,
        formulaire_KYC,
        formulaire_BIC,
        piece_identite_a_25_part,
        copie_status,
        copie_reglement_interieur,
        copie_acte_enregistrement,
        copie_publication_journal_officiel,
        copie_dernier_rapport,
        articles41_42_43,
        cip_attestation_residence,
        justificatif_activite,
        id: selectedId
      }
      const response = await axios.post(
        key.apiBaseURL + "/modifiercompteclient",
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if(response){
        toast.success("Modifier avec succès")
        setTimeout(() => {
          window.location.reload()
        }, 2500);
      }
    } catch (error) {
      toast.error("Echec de la modification")
      console.log("erreur :: ", error.response)
    }
  }

  const type_compte = [
    {
      id: 1,
      label: "PARTICULIER"
    },
    {
      id: 2,
      label: "ETABLISSEMENT"
    },
    {
      id: 3,
      label: "GROUPE ENTREPRISE"
    },
    {
      id: 4,
      label: "ONG/ASSOCIATION"
    },
    {
      id: 5,
      label: "SOCIETE"
    }
  ]

  const piece_dossier = [
    {
      label: "Registre de commerce",
      value: registre__commerce,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Date registre de commerce",
      value: date_registre_commerce,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Registre de commerce datant de moins de 03 mois",
      value: registre_commerce_moins_3_mois,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Copie IFU",
      value: copie_ifu,
      show: true
    },
    {
      label: "Pièce d'identité",
      value: piece_identite,
      show: true
    },
    {
      label: "Copie de la pièce d'identité",
      value: copie_piece_identite,
      show: true
    },
    {
      label: "Deux photo d'identité",
      value: deux_photo_identite,
      show: true
    },
    {
      label: "Facture ou quittance SBEE/SONEB",
      value: facture_quitance_SBEE_SONEB,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Date de la facture SBEE/SONEB",
      value: date_facture_SBEE_SONEB,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Facture SBEE/SONEB datant de moins de 03 mois",
      value: fact_SBEE_SONEB_moins_trois_mois,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Formulaire EER",
      value: formulaire_EER,
      show: true
    },
    {
      label: "Formulaire KYC",
      value: formulaire_KYC,
      show: true
    },
    {
      label: "Formulaire BIC",
      value: formulaire_BIC,
      show: true
    },
    {
      label: "Pièce d'identité des actionnaire ayant 25% de part",
      value: piece_identite_a_25_part,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie des statuts",
      value: copie_status,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Copie du règlement intérieur",
      value: copie_reglement_interieur,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie de l'acte d'enregistrement",
      value: copie_acte_enregistrement,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie de la publication du journal officiel",
      value: copie_publication_journal_officiel,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie du dernier rapport",
      value: copie_dernier_rapport,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Articles 41, 42, 43",
      value: articles41_42_43,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "CIP/Attestation de résidence",
      value: cip_attestation_residence,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
            : false
    },
    {
      label: "Justificatif de l'activité",
      value: justificatif_activite,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
            : false
    },
  ]

  const exportData = () => {
    const worksheet = XLSX.utils.json_to_sheet(allCompte);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileURL = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', "compte_client");
    link.click();
    toast.success("Exporter avec succès. Consulter vos téléchargements.")
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content" style={{ display: update ? "none" : "block" }}>
        <div className="container-fluid">
          <BreadcrumbsAddUsers title="" breadcrumbItem="Comptes clients" />
          <Row>
            <Col>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <Link to="/ajouter-compte-client" className="btn btn-success waves-effect waves-light ms-auto">
                  + Ajouter un compte client
                  </Link>
                </div>
                <div>
                  <Link to="#" onClick={exportData} className="btn btn-success waves-effect waves-light ms-auto m-2">
                    Exporter
                  </Link>
              </div>
              </div>
            </Col>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={allCompte || []}
                    isGlobalFilter={true}
                    isAddUsers={true}
                    isAddTableWithoutBorderStrap={true}
                    handleUserClicks={""}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ display: update ? "block" : "none" }}>
        <Col xs="12">
          <div className="page-content">
            <div className="container-fluid">
              <BreadcrumbsAddUsers title="Comptes clients" breadcrumbItem="Modifier comptes clients" />
              <Row>
                <Col className="mb-3">
                  <div>
                    <Link
                      to="#"
                      className="btn btn-success waves-effect waves-light ms-auto"
                      onClick={()=>{
                        setUpdate(false)
                      }}
                    >
                      Annuler
                    </Link>
                  </div>
                </Col>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}>
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                              >
                                <span className="number">1.</span>{" "}
                                Compte Info
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                              >
                                <span className="number me-2">2.</span>{" "}
                                Documents
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 3 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => {
                                  setactiveTab(3)
                                }}
                              >
                                <span className="number">3.</span>{" "}
                              Confirmer
                            </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          <div className="body">
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={1}>
                                <Form>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                          Numéro de compte
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-firstname-input1"
                                          placeholder="Numéro de compte"
                                          name="num_compte"
                                          value={num_compte}
                                          disabled={true}
                                          // onChange={(e) => {
                                          //   setNum_compte(e.target.value)
                                          // }}
                                          // onBlur={(e) => {
                                          //   setNum_compte(e.target.value)
                                          // }}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                          Intitulé client
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-lastname-input2"
                                          placeholder="Intitulé client"
                                          name="intitule_client"
                                          onChange={(e) => {
                                            setIntitule_client(e.target.value)
                                          }}
                                          onBlur={(e) => {
                                            setIntitule_client(e.target.value)
                                          }}
                                          value={intitule_client}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-phoneno-input3">
                                          Adresse client
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-phoneno-input3"
                                          placeholder="Adresse client"
                                          name="adresse_client"
                                          onChange={(e) => {
                                            setAdresse_client(e.target.value)
                                          }}
                                          onBlur={(e) => {
                                            setAdresse_client(e.target.value)
                                          }}
                                          value={adresse_client}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-phoneno-input3">
                                          Téléphone fix
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-phoneno-input3"
                                          placeholder="Téléphone fix"
                                          name="tel_fixe_client"
                                          onChange={(e) => {
                                            setTel_fixe_client(e.target.value)
                                          }}
                                          onBlur={(e) => {
                                            setTel_fixe_client(e.target.value)
                                          }}
                                          value={tel_fixe_client}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-phoneno-input3">
                                          Téléphone mobile
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-phoneno-input3"
                                          placeholder="Téléphone mobile"
                                          name="mobile"
                                          onChange={(e) => {
                                            setMobile(e.target.value)
                                          }}
                                          onBlur={(e) => {
                                            setMobile(e.target.value)
                                          }}
                                          value={mobile}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-phoneno-input3">
                                          Date d'ouverture du compte
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="basicpill-phoneno-input3"
                                          placeholder="Date d'ouverture"
                                          name="date_ouverture"
                                          value={date_ouverture}
                                          disabled={true}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </TabPane>
                              <TabPane tabId={2}>
                                <div>
                                  <Form>
                                    <Row>
                                      <Col lg="12">
                                        <div className="mb-3">
                                          <Label for="basicpill-pancard-input5">
                                            Type de compte
                                          </Label>
                                          <Input
                                            type="select"
                                            className="form-control"
                                            id="basicpill-pancard-input5"
                                            placeholder="Type de compte"
                                            name="type_de_compte"
                                            onChange={(e) => {
                                              setType_de_compte(e.target.value)
                                            }}
                                            onBlur={(e) => {
                                              setType_de_compte(e.target.value)
                                              console.log(type_de_compte)
                                            }}
                                            value={type_de_compte}
                                          >
                                            <option value={''}>Selectionnez une option</option>
                                            {
                                              type_compte && type_compte.map((item, index) => (
                                                <option key={index} value={item.id}>{item.label}</option>
                                              ))
                                            }
                                          </Input>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                                          ?
                                            (
                                              <>
                                                <Col lg="4">
                                                  <div className="mb-3 square-switch d-flex justify-content-between">
                                                    <Label htmlFor="square-switch3">Registre de commerce</Label>
                                                    <Input
                                                      type="checkbox"
                                                      id="square-switch3"
                                                      switch="none"
                                                      checked={registre__commerce}
                                                      name="registre__commerce"
                                                      onChange={(e) => {
                                                        console.log(e.target.checked)
                                                        setRegistre__commerce(e.target.checked)
                                                      }}
                                                      // value={registre__commerce}
                                                    />
                                                    <Label
                                                      htmlFor="square-switch3"
                                                      data-on-label="Oui"
                                                      data-off-label="Non"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col lg="4">
                                                  <div className="mb-3">
                                                    <Label for="basicpill-phoneno-input3">
                                                      Date du registre de commerce
                                                    </Label>
                                                    <Input
                                                      type="date"
                                                      className="form-control"
                                                      id="basicpill-phoneno-input3"
                                                      placeholder="Date du registre de commerce"
                                                      name="date_registre_commerce"
                                                      value={date_registre_commerce}
                                                      onChange={(e) => {
                                                        setDate_registre_commerce(e.target.value)
                                                        const value = new Date(e.target.value)
                                                        const data =  new Date(currentDate)
                                                        if ( Math.ceil((data - value)/86400000) < 90 ) {
                                                          setRegistre_commerce_moins_3_mois(true)
                                                        } else {
                                                          setRegistre_commerce_moins_3_mois(false)
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                </Col>
                                                <Col lg="4">
                                                  <div className="mb-3 square-switch d-flex justify-content-between">
                                                    <Label htmlFor="square-switch4">Registre de commerce (moins de 3 mois) ? </Label>
                                                    <Input
                                                      type="checkbox"
                                                      id="square-switch4"
                                                      switch="none"
                                                      checked={registre_commerce_moins_3_mois}
                                                      name="registre_commerce_moins_3_mois"
                                                      disabled={true}
                                                      // onChange={(e) => {
                                                      //   setRegistre_commerce_moins_3_mois(!registre_commerce_moins_3_mois)
                                                      // }}
                                                      // value={registre_commerce_moins_3_mois}
                                                    />
                                                    <Label
                                                      htmlFor="square-switch4"
                                                      data-on-label="Oui"
                                                      data-off-label="Non"
                                                    />
                                                  </div>
                                                </Col>
                                              </>
                                            )
                                          :
                                            null
                                        :
                                          null
                                      }

                                    
                                      <Col lg="4">
                                        <div className="mb-3 square-switch d-flex justify-content-between">
                                          <Label htmlFor="square-switch5">Copie IFU </Label>
                                          <Input
                                            type="checkbox"
                                            id="square-switch5"
                                            switch="none"
                                            checked={copie_ifu}
                                            name="copie_ifu"
                                            onChange={(e) => {
                                              setCopie_ifu(!copie_ifu)
                                            }}
                                            // value={copie_ifu}
                                          />
                                          <Label
                                            htmlFor="square-switch5"
                                            data-on-label="Oui"
                                            data-off-label="Non"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="4">
                                        <div className="mb-3 square-switch d-flex justify-content-between">
                                          <Label htmlFor="square-switch6">Pièce d'identité </Label>
                                          <Input
                                            type="checkbox"
                                            id="square-switch6"
                                            switch="none"
                                            checked={piece_identite}
                                            name="piece_identite"
                                            onChange={(e) => {
                                              setPiece_identite(!piece_identite)
                                            }}
                                            // value={piece_identite}
                                          />
                                          <Label
                                            htmlFor="square-switch6"
                                            data-on-label="Oui"
                                            data-off-label="Non"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="4">
                                        <div className="mb-3 square-switch d-flex justify-content-between">
                                          <Label htmlFor="square-switch7">Copie pièce d'identité </Label>
                                          <Input
                                            type="checkbox"
                                            id="square-switch7"
                                            switch="none"
                                            checked={copie_piece_identite}
                                            name="copie_piece_identite"
                                            onChange={(e) => {
                                              setCopie_piece_identite(!copie_piece_identite)
                                            }}
                                            // value={copie_piece_identite}
                                          />
                                          <Label
                                            htmlFor="square-switch7"
                                            data-on-label="Oui"
                                            data-off-label="Non"
                                          />
                                        </div>
                                      </Col>
                                    
                                      <Col lg="4">
                                        <div className="mb-3 square-switch d-flex justify-content-between">
                                          <Label htmlFor="square-switch8">Deux photos d'identité </Label>
                                          <Input
                                            type="checkbox"
                                            id="square-switch8"
                                            switch="none"
                                            checked={deux_photo_identite}
                                            name="deux_photo_identite"
                                            onChange={(e) => {
                                              setDeux_photo_identite(!deux_photo_identite)
                                            }}
                                            // value={deux_photo_identite}
                                          />
                                          <Label
                                            htmlFor="square-switch8"
                                            data-on-label="Oui"
                                            data-off-label="Non"
                                          />
                                        </div>
                                      </Col>
                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label !== "PARTICULIER"
                                          ?
                                            <>
                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch9">Facture ou quittance SBEE / SONEB </Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch9"
                                                    switch="none"
                                                    checked={facture_quitance_SBEE_SONEB}
                                                    name="facture_quitance_SBEE_SONEB"
                                                    onChange={(e) => {
                                                      setFacture_quitance_SBEE_SONEB(!facture_quitance_SBEE_SONEB)
                                                    }}
                                                    // value={facture_quitance_SBEE_SONEB}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch9"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                              <div className="mb-3">
                                                  <Label for="basicpill-phoneno-input3">
                                                    Date de la facture SBEE / SONEB
                                                  </Label>
                                                  <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="basicpill-phoneno-input3"
                                                    placeholder="Date de la facture SBEE / SONEB"
                                                    name="date_facture_SBEE_SONEB"
                                                    value={date_facture_SBEE_SONEB}
                                                    onChange={(e) => {
                                                      setDate_facture_SBEE_SONEB(e.target.value)
                                                      const value = new Date(e.target.value)
                                                      const data = new Date(currentDate)
                                                      if ( Math.ceil((data - value)/86400000) < 90 ) {
                                                        setFact_SBEE_SONEB_moins_trois_mois(true)
                                                      } else {
                                                        setFact_SBEE_SONEB_moins_trois_mois(false)
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                            </>
                                          :
                                            null
                                        :
                                          null
                                      }

                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label !== "PARTICULIER"
                                          ?
                                            <>
                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch10">Facture SBEE / SONEB moins 03 mois</Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch10"
                                                    switch="none"
                                                    checked={fact_SBEE_SONEB_moins_trois_mois}
                                                    name="fact_SBEE_SONEB_moins_trois_mois"
                                                    disabled={true}
                                                    // onChange={(e) => {
                                                    //   setFact_SBEE_SONEB_moins_trois_mois(!fact_SBEE_SONEB_moins_trois_mois)
                                                    // }}
                                                    // value={fact_SBEE_SONEB_moins_trois_mois}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch10"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>
                                            </>
                                          :
                                            null
                                        :
                                          null
                                      }

                                        <Col lg="4">
                                          <div className="mb-3 square-switch d-flex justify-content-between">
                                            <Label htmlFor="square-switch11">formulaire EER</Label>
                                            <Input
                                              type="checkbox"
                                              id="square-switch11"
                                              switch="none"
                                              checked={formulaire_EER}
                                              name="formulaire_EER"
                                              onChange={(e) => {
                                                setFormulaire_EER(!formulaire_EER)
                                              }}
                                              // value={formulaire_EER}
                                            />
                                            <Label
                                              htmlFor="square-switch11"
                                              data-on-label="Oui"
                                              data-off-label="Non"
                                            />
                                          </div>
                                        </Col>

                                        <Col lg="4">
                                          <div className="mb-3 square-switch d-flex justify-content-between">
                                            <Label htmlFor="square-switch12">Formulaire KYC</Label>
                                            <Input
                                              type="checkbox"
                                              id="square-switch12"
                                              switch="none"
                                              checked={formulaire_KYC}
                                              name="formulaire_KYC"
                                              onChange={(e) => {
                                                setFormulaire_KYC(!formulaire_KYC)
                                              }}
                                              // value={formulaire_KYC}
                                            />
                                            <Label
                                              htmlFor="square-switch12"
                                              data-on-label="Oui"
                                              data-off-label="Non"
                                            />
                                          </div>
                                        </Col>

                                        <Col lg="4">
                                          <div className="mb-3 square-switch d-flex justify-content-between">
                                            <Label htmlFor="square-switch13">Formulaire BIC</Label>
                                            <Input
                                              type="checkbox"
                                              id="square-switch13"
                                              switch="none"
                                              checked={formulaire_BIC}
                                              name="formulaire_BIC"
                                              onChange={(e) => {
                                                setFormulaire_BIC(!formulaire_BIC)
                                              }}
                                              // value={formulaire_BIC}
                                            />
                                            <Label
                                              htmlFor="square-switch13"
                                              data-on-label="Oui"
                                              data-off-label="Non"
                                            />
                                          </div>
                                        </Col>
                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION" || 
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                                          ?
                                            <Col lg="4">
                                              <div className="mb-3 square-switch d-flex justify-content-between">
                                                <Label htmlFor="square-switch14">Pièce d'identite actionnaire ayant au moins 25%</Label>
                                                <Input
                                                  type="checkbox"
                                                  id="square-switch14"
                                                  switch="none"
                                                  checked={piece_identite_a_25_part}
                                                  name="piece_identite_a_25_part"
                                                  onChange={(e) => {
                                                    setPiece_identite_a_25_part(!piece_identite_a_25_part)
                                                  }}
                                                  // value={piece_identite_a_25_part}
                                                />
                                                <Label
                                                  htmlFor="square-switch14"
                                                  data-on-label="Oui"
                                                  data-off-label="Non"
                                                />
                                              </div>
                                            </Col>
                                          :
                                            null
                                        :
                                          null
                                      }

                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                                          ?
                                            <Col lg="4">
                                              <div className="mb-3 square-switch d-flex justify-content-between">
                                                <Label htmlFor="square-switch15">Copie Statut</Label>
                                                <Input
                                                  type="checkbox"
                                                  id="square-switch15"
                                                  switch="none"
                                                  checked={copie_status}
                                                  name="copie_status"
                                                  onChange={(e) => {
                                                    setCopie_status(!copie_status)
                                                  }}
                                                  // value={copie_status}
                                                />
                                                <Label
                                                  htmlFor="square-switch15"
                                                  data-on-label="Oui"
                                                  data-off-label="Non"
                                                />
                                              </div>
                                            </Col>
                                          :
                                          null
                                        :
                                          null
                                      }

                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                                          ?
                                            <Row>
                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch16">Copie règlement interieur</Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch16"
                                                    switch="none"
                                                    checked={copie_reglement_interieur}
                                                    name="copie_reglement_interieur"
                                                    onChange={(e) => {
                                                      setCopie_reglement_interieur(!copie_reglement_interieur)
                                                    }}
                                                    // value={copie_reglement_interieur}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch16"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>

                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch17">Copie acte enregistrement</Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch17"
                                                    switch="none"
                                                    checked={copie_acte_enregistrement}
                                                    name="copie_acte_enregistrement"
                                                    onChange={(e) => {
                                                      setCopie_acte_enregistrement(!copie_acte_enregistrement)
                                                    }}
                                                    // value={copie_acte_enregistrement}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch17"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>

                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch18">Copie publication journal officiel</Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch18"
                                                    switch="none"
                                                    checked={copie_publication_journal_officiel}
                                                    name="copie_publication_journal_officiel"
                                                    onChange={(e) => {
                                                      setCopie_publication_journal_officie(!copie_publication_journal_officiel)
                                                    }}
                                                    // value={copie_publication_journal_officiel}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch18"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          :
                                            null
                                        :
                                          null
                                      }

                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                                          ?
                                            <>
                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch19">Copie dernier rapport</Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch19"
                                                    switch="none"
                                                    checked={copie_dernier_rapport}
                                                    name="copie_dernier_rapport"
                                                    onChange={(e) => {
                                                      setCopie_dernier_rapport(!copie_dernier_rapport)
                                                    }}
                                                    // value={copie_dernier_rapport}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch19"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch20">Articles 41_42_43</Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch20"
                                                    switch="none"
                                                    checked={articles41_42_43}
                                                    name="articles41_42_43"
                                                    onChange={(e) => {
                                                      setArticles41_42_43(!articles41_42_43)
                                                    }}
                                                    // value={articles41_42_43}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch20"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>
                                            </>
                                          :
                                            null
                                        :
                                          null
                                      }

                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
                                          ?
                                            <Col lg="4">
                                              <div className="mb-3 square-switch d-flex justify-content-between">
                                                <Label htmlFor="square-switch21">CIP / Attestation de residence</Label>
                                                <Input
                                                  type="checkbox"
                                                  id="square-switch21"
                                                  switch="none"
                                                  checked={cip_attestation_residence}
                                                  name="cip_attestation_residence"
                                                  onChange={(e) => {
                                                    setCip_attestation_residence(!cip_attestation_residence)
                                                  }}
                                                  // value={cip_attestation_residence}
                                                />
                                                <Label
                                                  htmlFor="square-switch21"
                                                  data-on-label="Oui"
                                                  data-off-label="Non"
                                                />
                                              </div>
                                            </Col>
                                          :
                                            null
                                        :
                                          null
                                      }
                                    
                                      {
                                        type_de_compte
                                        ?
                                          type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
                                          ?
                                            <>
                                              <Col lg="4">
                                                <div className="mb-3 square-switch d-flex justify-content-between">
                                                  <Label htmlFor="square-switch22">Justificatif activite</Label>
                                                  <Input
                                                    type="checkbox"
                                                    id="square-switch22"
                                                    switch="none"
                                                    checked={justificatif_activite}
                                                    name="justificatif_activite"
                                                    onChange={(e) => {
                                                      setJustificatif_activite(!justificatif_activite)
                                                    }}
                                                    // value={justificatif_activite}
                                                  />
                                                  <Label
                                                    htmlFor="square-switch22"
                                                    data-on-label="Oui"
                                                    data-off-label="Non"
                                                  />
                                                </div>
                                              </Col>
                                            </>
                                          :
                                            null
                                        :
                                          null
                                      }
                                    </Row>
                                  </Form>
                                </div>
                              </TabPane>
                              <TabPane tabId={3}>
                                <Row>
                                  <Col className="border">
                                    {/* <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div> */}
                                    <div className="text-center">
                                      <h4>Récapitulatif</h4>
                                    </div>
                                    <div className="m-3">
                                      <div className="row">
                                        <div className="col-6">
                                          <span>Type de compte client</span>
                                        </div>
                                        <div className="col-6">
                                          <span>{ type_de_compte ? type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label : "" }</span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6">
                                          <span>Numéro compte</span>
                                        </div>
                                        <div className="col-6">
                                          <span>{ num_compte }</span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6">
                                          <span>Intitulé client</span>
                                        </div>
                                        <div className="col-6">
                                          <span>{ intitule_client }</span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6">
                                          <span>Adresse client</span>
                                        </div>
                                        <div className="col-6">
                                          <span>{ adresse_client }</span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6">
                                          <span>Téléphone fix client</span>
                                        </div>
                                        <div className="col-6">
                                          <span>{ tel_fixe_client }</span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6">
                                          <span>Téléphone mobile</span>
                                        </div>
                                        <div className="col-6">
                                          <span>{ mobile }</span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6">
                                          <span>Date d'ouverture du compte</span>
                                        </div>
                                        <div className="col-6">
                                          <span>{ date_ouverture }</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="m-3">
                                      <div className="row border">
                                        <div className="col-4 border"><span>Pièces du dossier</span></div>
                                        <div className="col-4 border"><span>Valeurs</span></div>
                                        <div className="col-4 border"><span>Observations</span></div>
                                      </div>
                                      {
                                        piece_dossier.map((item, index) => (
                                          item.show === true
                                          ?
                                            <div className="row border" key={index}>
                                              <div className="col-4 border"><span>{item.label}</span></div>
                                              <div className="col-4 border"><span>{item.value === false || item.value == null ? "NON" : "OUI"}</span></div>
                                              <div className="col-4 border"><span>{!item.value ? "Non à jour" : "A jour"}</span></div>
                                            </div>
                                          :
                                          null
                                        ))
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li>
                              {
                                activeTab === 3 
                                ?
                                  <Button
                                    className="btn btn-success"
                                    onClick={() => updateCompte()}
                                  >
                                    Modifier
                                  </Button>
                                :
                                  <Link
                                    to="#"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    Next
                                  </Link>
                              }
                            </li>
                          </ul>
                        </div>

                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </div>
    </React.Fragment>
  );
}

CompteClient.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default CompteClient;
