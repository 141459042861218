import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerUser";
import * as XLSX from 'xlsx';
import { Col, Row, Card, CardBody, Label, Input, Button, TabPane, Form, } from "reactstrap";
import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
// import withRouter from "../../components/Common/withRouter";
import BreadcrumbsAddUsers from "../../components/Common/BreadcrumbUsers";
import moment from "moment";

function RapportCompteClient(props) {
  document.title = "Comptes Clients";

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const currentDate = moment().format("YYYY-MM-DD")
  const [num_compte, setNum_compte] = useState("")
  const [intitule_client, setIntitule_client] = useState("")
  const [adresse_client, setAdresse_client] = useState("")
  const [tel_fixe_client, setTel_fixe_client] = useState("")
  const [mobile, setMobile] = useState("")
  const [type_de_compte, setType_de_compte] = useState("")
  const [date_ouverture, setDate_ouverture] = useState("")
  const [registre__commerce, setRegistre__commerce] = useState(false)
  const [date_registre_commerce, setDate_registre_commerce] = useState("")
  const [registre_commerce_moins_3_mois, setRegistre_commerce_moins_3_mois] = useState(false)
  const [copie_ifu, setCopie_ifu] = useState(false)
  const [piece_identite, setPiece_identite] = useState(false)
  const [copie_piece_identite, setCopie_piece_identite] = useState(false)
  const [deux_photo_identite, setDeux_photo_identite] = useState(false)
  const [facture_quitance_SBEE_SONEB, setFacture_quitance_SBEE_SONEB] = useState(false)
  const [date_facture_SBEE_SONEB, setDate_facture_SBEE_SONEB] = useState("")
  const [fact_SBEE_SONEB_moins_trois_mois, setFact_SBEE_SONEB_moins_trois_mois] = useState(false)
  const [formulaire_EER, setFormulaire_EER] = useState(false)
  const [formulaire_KYC, setFormulaire_KYC] = useState(false)
  const [formulaire_BIC, setFormulaire_BIC] = useState(false)
  const [piece_identite_a_25_part, setPiece_identite_a_25_part] = useState(false)
  const [copie_status, setCopie_status] = useState(false)
  const [copie_reglement_interieur, setCopie_reglement_interieur] = useState(false)
  const [copie_acte_enregistrement, setCopie_acte_enregistrement] = useState(false)
  const [copie_publication_journal_officiel, setCopie_publication_journal_officie] = useState(false)
  const [copie_dernier_rapport, setCopie_dernier_rapport] = useState(false)
  const [articles41_42_43, setArticles41_42_43] = useState(false)
  const [cip_attestation_residence, setCip_attestation_residence] = useState(false)
  const [justificatif_activite, setJustificatif_activite] = useState(false)


  
  const [compte, setCompte] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const getAllCompte = async () => {
    try {
      const response = await axios.get(
        key.apiBaseURL + "/affichertoutcompteclient",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if (response) {
        console.log(response.data)
        setCompte(response.data)
      }
    } catch (error) {
      console.log("erreur :: ", error.response)
    }
  }

  useEffect(() => {
    if (accessToken) {
      console.log("Access token")
      getAllCompte()
    }
  }, [accessToken])

  useEffect(()=>{
    if ( compte ) {
      setNum_compte(compte.num_compte)
      setIntitule_client(compte.intitule_client)
      setAdresse_client(compte.adresse_client)
      setTel_fixe_client(compte.tel_fixe_client)
      setMobile(compte.mobile)
      setType_de_compte(compte.type_de_compte)
      setDate_ouverture(compte.date_ouverture && compte.date_ouverture.split("T")[0])
      setRegistre__commerce(compte.registre__commerce === 1 ? true : false)
      setDate_registre_commerce(compte.date_registre_commerce && compte.date_registre_commerce.split("T")[0])
      setRegistre_commerce_moins_3_mois(compte.registre_commerce_moins_3_mois === 1 ? true : false)
      setCopie_ifu(compte.copie_ifu === 1 ? true : false)
      setPiece_identite(compte.piece_identite === 1 ? true : false)
      setCopie_piece_identite(compte.copie_piece_identite === 1 ? true : false)
      setDeux_photo_identite(compte.deux_photo_identite === 1 ? true : false)
      setFacture_quitance_SBEE_SONEB(compte.facture_quitance_SBEE_SONEB === 1 ? true : false)
      setDate_facture_SBEE_SONEB(compte.date_facture_SBEE_SONEB && compte.date_facture_SBEE_SONEB.split("T")[0])
      setFact_SBEE_SONEB_moins_trois_mois(compte.fact_SBEE_SONEB_moins_trois_mois === 1 ? true : false)
      setFormulaire_EER(compte.formulaire_EER === 1 ? true : false)
      setFormulaire_KYC(compte.formulaire_KYC === 1 ? true : false)
      setFormulaire_BIC(compte.formulaire_BIC === 1 ? true : false)
      setPiece_identite_a_25_part(compte.piece_identite_a_25_part === 1 ? true : false)
      setCopie_status(compte.copie_status === 1 ? true : false)
      setCopie_reglement_interieur(compte.copie_reglement_interieur === 1 ? true : false)
      setCopie_acte_enregistrement(compte.copie_acte_enregistrement === 1 ? true : false)
      setCopie_publication_journal_officie(compte.copie_publication_journal_officiel === 1 ? true : false)
      setCopie_dernier_rapport(compte.copie_dernier_rapport === 1 ? true : false)
      setArticles41_42_43(compte.articles41_42_43 === 1 ? true : false)
      setCip_attestation_residence(compte.cip_attestation_residence === 1 ? true : false)
      setJustificatif_activite(compte.justificatif_activite === 1 ? true : false)
    }
  }, [compte])

  const type_compte = [
    { id: 1, label: "PARTICULIER" },
    { id: 2, label: "ETABLISSEMENT" },
    { id: 3, label: "GROUPE ENTREPRISE" },
    { id: 4, label: "ONG/ASSOCIATION" },
    { id: 5, label: "SOCIETE" }
  ]

  const imprimer = () => {
    window.print();
  };

  const piece_dossier = [
    {
      label: "Registre de commerce",
      value: registre__commerce,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Date registre de commerce",
      value: date_registre_commerce,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Registre de commerce datant de moins de 03 mois",
      value: registre_commerce_moins_3_mois,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Copie IFU",
      value: copie_ifu,
      show: true
    },
    {
      label: "Pièce d'identité",
      value: piece_identite,
      show: true
    },
    {
      label: "Copie de la pièce d'identité",
      value: copie_piece_identite,
      show: true
    },
    {
      label: "Deux photo d'identité",
      value: deux_photo_identite,
      show: true
    },
    {
      label: "Facture ou quittance SBEE/SONEB",
      value: facture_quitance_SBEE_SONEB,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Date de la facture SBEE/SONEB",
      value: date_facture_SBEE_SONEB,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Facture SBEE/SONEB datant de moins de 03 mois",
      value: fact_SBEE_SONEB_moins_trois_mois,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Formulaire EER",
      value: formulaire_EER,
      show: true
    },
    {
      label: "Formulaire KYC",
      value: formulaire_KYC,
      show: true
    },
    {
      label: "Formulaire BIC",
      value: formulaire_BIC,
      show: true
    },
    {
      label: "Pièce d'identité des actionnaire ayant 25% de part",
      value: piece_identite_a_25_part,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie des statuts",
      value: copie_status,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
            : false
    },
    {
      label: "Copie du règlement intérieur",
      value: copie_reglement_interieur,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie de l'acte d'enregistrement",
      value: copie_acte_enregistrement,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie de la publication du journal officiel",
      value: copie_publication_journal_officiel,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Copie du dernier rapport",
      value: copie_dernier_rapport,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "Articles 41, 42, 43",
      value: articles41_42_43,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
            : false
    },
    {
      label: "CIP/Attestation de résidence",
      value: cip_attestation_residence,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
            : false
    },
    {
      label: "Justificatif de l'activité",
      value: justificatif_activite,
      show: type_de_compte ?
            type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
            : false
    },
  ]

  const columns = useMemo(
    () => [
      {
        Header: "N°",
        accessor: "id",
        filterable: true,
        Cell: (cellProps) => (
          <span>{cellProps.row.original.id}</span>
        ),
      },
      {
        Header: "Intitulé Client",
        accessor: "intitule_client",
        filterable: true,
        Cell: (cellProps) => (
          <span>{cellProps.row.original.intitule_client}</span>
        ),
      },
      {
        Header: "Pièce à régulariser",
        accessor: "adresse_client",
        filterable: true,
        Cell: (cellProps) => (
          <div>
            {cellProps.row.original.type_de_compte == 1 && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : "null"}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : "null"}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : "null"}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : "null"}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : "null"}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : "null"}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : "null"}</p>
                <p>{cellProps.row.original.cip_attestation_residence === 0 ? "CIP/Attestation de résidence" : "null"}</p>
                <p>{cellProps.row.original.justificatif_activite === 0 ? "Justificatif d'activité" : "null"}</p>
              </>
            )}

            {cellProps.row.original.type_de_compte === 2 && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : null}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : null}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : null}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : null}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : null}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : null}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : null}</p>
                <p>{cellProps.row.original.registre__commerce === 0 ? "Registre de commerce" : null}</p>
                <p>{cellProps.row.original.date_registre_commerce === 0 ? "Date du registre de commerce" : null}</p>
                <p>{cellProps.row.original.registre_commerce_moins_3_mois === 0 ? "Registre de commerce (moins de 3 mois) ?" : null}</p>
                <p>{cellProps.row.original.facture_quitance_SBEE_SONEB === 0 ? "Facture ou quittance SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.date_facture_SBEE_SONEB === 0 ? "Date de la facture SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.fact_SBEE_SONEB_moins_trois_mois === 0 ? "Facture SBEE / SONEB moins 03 mois" : null}</p>
              </>
            )}

            {(cellProps.row.original.type_de_compte === 3 || cellProps.row.original.type_de_compte === 5) && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : null}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : null}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : null}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : null}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : null}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : null}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : null}</p>
                <p>{cellProps.row.original.registre__commerce === 0 ? "Registre de commerce" : null}</p>
                <p>{cellProps.row.original.date_registre_commerce === 0 ? "Date du registre de commerce" : null}</p>
                <p>{cellProps.row.original.registre_commerce_moins_3_mois === 0 ? "Registre de commerce (moins de 3 mois) ?" : null}</p>
                <p>{cellProps.row.original.facture_quitance_SBEE_SONEB === 0 ? "Facture ou quittance SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.date_facture_SBEE_SONEB === 0 ? "Date de la facture SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.fact_SBEE_SONEB_moins_trois_mois === 0 ? "Facture SBEE / SONEB moins 03 mois" : null}</p>
                <p>{cellProps.row.original.piece_identite_a_25_part === 0 ? "Pièce d'identite actionnaire ayant au moins 25%" : null}</p>
                <p>{cellProps.row.original.copie_status === 0 ? "Copie Statut" : null}</p>
              </>
            )}

            {cellProps.row.original.type_de_compte === 4 && (
              <>
                <p>{cellProps.row.original.copie_ifu === 0 ? "Copie IFU" : null}</p>
                <p>{cellProps.row.original.piece_identite === 0 ? "Pièce d'identité" : null}</p>
                <p>{cellProps.row.original.copie_piece_identite === 0 ? "Copie pièce d'identité" : null}</p>
                <p>{cellProps.row.original.deux_photo_identite === 0 ? "Deux photos d'identités" : null}</p>
                <p>{cellProps.row.original.formulaire_EER === 0 ? "Formulaire EER" : null}</p>
                <p>{cellProps.row.original.formulaire_KYC === 0 ? "Formulaire KYC" : null}</p>
                <p>{cellProps.row.original.formulaire_BIC === 0 ? "Formulaire BIC" : null}</p>
                <p>{cellProps.row.original.facture_quitance_SBEE_SONEB === 0 ? "Facture ou quittance SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.date_facture_SBEE_SONEB === 0 ? "Date de la facture SBEE / SONEB" : null}</p>
                <p>{cellProps.row.original.fact_SBEE_SONEB_moins_trois_mois === 0 ? "Facture SBEE / SONEB moins 03 mois" : null}</p>
                <p>{cellProps.row.original.piece_identite_a_25_part === 0 ? "Pièce d'identite actionnaire ayant au moins 25%" : null}</p>
                <p>{cellProps.row.original.copie_reglement_interieur === 0 ? "Copie règlement interieur" : null}</p>
                <p>{cellProps.row.original.copie_acte_enregistrement === 0 ? "Copie acte enregistrement" : null}</p>
                <p>{cellProps.row.original.copie_publication_journal_officiel === 0 ? "Copie publication journal officiel" : null}</p>
                <p>{cellProps.row.original.copie_dernier_rapport === 0 ? "Copie dernier rapport" : null}</p>
                <p>{cellProps.row.original.articles41_42_43 === 0 ? "Articles 41_42_43" : null}</p>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const handleFilter = async () => {
    try {
      const response = await axios.post(
        key.apiBaseURL + "/filtercompteclient",
        {
          type_de_compte,
          startDate,
          endDate,
          copie_ifu,
          piece_identite,
          copie_piece_identite,
          deux_photo_identite,
          formulaire_EER,
          formulaire_KYC,
          formulaire_BIC,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response) {
        setCompte(response.data);
      }
    } catch (error) {
      console.log("erreur :: ", error.response);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <div className="container-fluid">
          <BreadcrumbsAddUsers title="" breadcrumbItem="Comptes clients" />
          <Row>
            <Col>
              <div className="mb-3">
                <Link to="#" className="btn btn-success waves-effect waves-light ms-auto">
                  Imprimer
                </Link>
              </div>
            </Col>
          </Row>
          <TabPane tabId={2}>
            <div>
              <Form>
                <Row>
                  <Col lg="12">
                    <div className="mb-3">
                      <Label for="basicpill-pancard-input5">
                        Type de compte
                      </Label>
                      <Input
                        type="select"
                        className="form-control"
                        id="basicpill-pancard-input5"
                        placeholder="Type de compte"
                        name="type_de_compte"
                        onChange={(e) => {
                          setType_de_compte(e.target.value)
                        }}
                        onBlur={(e) => {
                          setType_de_compte(e.target.value)
                          console.log(type_de_compte)
                        }}
                        value={type_de_compte}
                      >
                        <option value={''}>Selectionnez une option</option>
                        {
                          type_compte && type_compte.map((item, index) => (
                            <option key={index} value={item.id}>{item.label}</option>
                          ))
                        }
                      </Input>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
              <Label for="date-range" id="date-range">Date</Label>
            </Row>
            <Row className="align-items-center my-4">
              <Col>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-2"><strong>De:</strong>  </p>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="De"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </Col>
              <Col>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-2"><strong>à:</strong>  </p>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="À"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
                <Row>
                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" ||
                        type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                        type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                        ?
                        (
                          <>
                            <Col lg="4">
                              <div className="mb-3 square-switch d-flex justify-content-between">
                                <Label htmlFor="square-switch3">Registre de commerce</Label>
                                <Input
                                  type="checkbox"
                                  id="square-switch3"
                                  switch="none"
                                  checked={registre__commerce}
                                  name="registre__commerce"
                                  onChange={(e) => {
                                    console.log(e.target.checked)
                                    setRegistre__commerce(e.target.checked)
                                  }}
                                // value={registre__commerce}
                                />
                                <Label
                                  htmlFor="square-switch3"
                                  data-on-label="Oui"
                                  data-off-label="Non"
                                />
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                  Date du registre de commerce
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="basicpill-phoneno-input3"
                                  placeholder="Date du registre de commerce"
                                  name="date_registre_commerce"
                                  value={date_registre_commerce}
                                  onChange={(e) => {
                                    setDate_registre_commerce(e.target.value)
                                    const value = new Date(e.target.value)
                                    const data = new Date(currentDate)
                                    if (Math.ceil((data - value) / 86400000) < 90) {
                                      setRegistre_commerce_moins_3_mois(true)
                                    } else {
                                      setRegistre_commerce_moins_3_mois(false)
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3 square-switch d-flex justify-content-between">
                                <Label htmlFor="square-switch4">Registre de commerce (moins de 3 mois) ? </Label>
                                <Input
                                  type="checkbox"
                                  id="square-switch4"
                                  switch="none"
                                  checked={registre_commerce_moins_3_mois}
                                  name="registre_commerce_moins_3_mois"
                                  disabled={true}
                                // onChange={(e) => {
                                //   setRegistre_commerce_moins_3_mois(!registre_commerce_moins_3_mois)
                                // }}
                                // value={registre_commerce_moins_3_mois}
                                />
                                <Label
                                  htmlFor="square-switch4"
                                  data-on-label="Oui"
                                  data-off-label="Non"
                                />
                              </div>
                            </Col>
                          </>
                        )
                        :
                        null
                      :
                      null
                  }


                  <Col lg="4">
                    <div className="mb-3 square-switch d-flex justify-content-between">
                      <Label htmlFor="square-switch5">Copie IFU </Label>
                      <Input
                        type="checkbox"
                        id="square-switch5"
                        switch="none"
                        checked={copie_ifu}
                        name="copie_ifu"
                        onChange={(e) => {
                          setCopie_ifu(!copie_ifu)
                        }}
                      // value={copie_ifu}
                      />
                      <Label
                        htmlFor="square-switch5"
                        data-on-label="Oui"
                        data-off-label="Non"
                      />
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className="mb-3 square-switch d-flex justify-content-between">
                      <Label htmlFor="square-switch6">Pièce d'identité </Label>
                      <Input
                        type="checkbox"
                        id="square-switch6"
                        switch="none"
                        checked={piece_identite}
                        name="piece_identite"
                        onChange={(e) => {
                          setPiece_identite(!piece_identite)
                        }}
                      // value={piece_identite}
                      />
                      <Label
                        htmlFor="square-switch6"
                        data-on-label="Oui"
                        data-off-label="Non"
                      />
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className="mb-3 square-switch d-flex justify-content-between">
                      <Label htmlFor="square-switch7">Copie pièce d'identité </Label>
                      <Input
                        type="checkbox"
                        id="square-switch7"
                        switch="none"
                        checked={copie_piece_identite}
                        name="copie_piece_identite"
                        onChange={(e) => {
                          setCopie_piece_identite(!copie_piece_identite)
                        }}
                      // value={copie_piece_identite}
                      />
                      <Label
                        htmlFor="square-switch7"
                        data-on-label="Oui"
                        data-off-label="Non"
                      />
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className="mb-3 square-switch d-flex justify-content-between">
                      <Label htmlFor="square-switch8">Deux photos d'identité </Label>
                      <Input
                        type="checkbox"
                        id="square-switch8"
                        switch="none"
                        checked={deux_photo_identite}
                        name="deux_photo_identite"
                        onChange={(e) => {
                          setDeux_photo_identite(!deux_photo_identite)
                        }}
                      // value={deux_photo_identite}
                      />
                      <Label
                        htmlFor="square-switch8"
                        data-on-label="Oui"
                        data-off-label="Non"
                      />
                    </div>
                  </Col>
                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label !== "PARTICULIER"
                        ?
                        <>
                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch9">Facture ou quittance SBEE / SONEB </Label>
                              <Input
                                type="checkbox"
                                id="square-switch9"
                                switch="none"
                                checked={facture_quitance_SBEE_SONEB}
                                name="facture_quitance_SBEE_SONEB"
                                onChange={(e) => {
                                  setFacture_quitance_SBEE_SONEB(!facture_quitance_SBEE_SONEB)
                                }}
                              // value={facture_quitance_SBEE_SONEB}
                              />
                              <Label
                                htmlFor="square-switch9"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-phoneno-input3">
                                Date de la facture SBEE / SONEB
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="basicpill-phoneno-input3"
                                placeholder="Date de la facture SBEE / SONEB"
                                name="date_facture_SBEE_SONEB"
                                value={date_facture_SBEE_SONEB}
                                onChange={(e) => {
                                  setDate_facture_SBEE_SONEB(e.target.value)
                                  const value = new Date(e.target.value)
                                  const data = new Date(currentDate)
                                  if (Math.ceil((data - value) / 86400000) < 90) {
                                    setFact_SBEE_SONEB_moins_trois_mois(true)
                                  } else {
                                    setFact_SBEE_SONEB_moins_trois_mois(false)
                                  }
                                }}
                              />
                            </div>
                          </Col>
                        </>
                        :
                        null
                      :
                      null
                  }

                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label !== "PARTICULIER"
                        ?
                        <>
                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch10">Facture SBEE / SONEB moins 03 mois</Label>
                              <Input
                                type="checkbox"
                                id="square-switch10"
                                switch="none"
                                checked={fact_SBEE_SONEB_moins_trois_mois}
                                name="fact_SBEE_SONEB_moins_trois_mois"
                                disabled={true}
                              // onChange={(e) => {
                              //   setFact_SBEE_SONEB_moins_trois_mois(!fact_SBEE_SONEB_moins_trois_mois)
                              // }}
                              // value={fact_SBEE_SONEB_moins_trois_mois}
                              />
                              <Label
                                htmlFor="square-switch10"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>
                        </>
                        :
                        null
                      :
                      null
                  }

                  <Col lg="4">
                    <div className="mb-3 square-switch d-flex justify-content-between">
                      <Label htmlFor="square-switch11">formulaire EER</Label>
                      <Input
                        type="checkbox"
                        id="square-switch11"
                        switch="none"
                        checked={formulaire_EER}
                        name="formulaire_EER"
                        onChange={(e) => {
                          setFormulaire_EER(!formulaire_EER)
                        }}
                      // value={formulaire_EER}
                      />
                      <Label
                        htmlFor="square-switch11"
                        data-on-label="Oui"
                        data-off-label="Non"
                      />
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className="mb-3 square-switch d-flex justify-content-between">
                      <Label htmlFor="square-switch12">Formulaire KYC</Label>
                      <Input
                        type="checkbox"
                        id="square-switch12"
                        switch="none"
                        checked={formulaire_KYC}
                        name="formulaire_KYC"
                        onChange={(e) => {
                          setFormulaire_KYC(!formulaire_KYC)
                        }}
                      // value={formulaire_KYC}
                      />
                      <Label
                        htmlFor="square-switch12"
                        data-on-label="Oui"
                        data-off-label="Non"
                      />
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className="mb-3 square-switch d-flex justify-content-between">
                      <Label htmlFor="square-switch13">Formulaire BIC</Label>
                      <Input
                        type="checkbox"
                        id="square-switch13"
                        switch="none"
                        checked={formulaire_BIC}
                        name="formulaire_BIC"
                        onChange={(e) => {
                          setFormulaire_BIC(!formulaire_BIC)
                        }}
                      // value={formulaire_BIC}
                      />
                      <Label
                        htmlFor="square-switch13"
                        data-on-label="Oui"
                        data-off-label="Non"
                      />
                    </div>
                  </Col>
                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION" ||
                        type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                        type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                        ?
                        <Col lg="4">
                          <div className="mb-3 square-switch d-flex justify-content-between">
                            <Label htmlFor="square-switch14">Pièce d'identite actionnaire ayant au moins 25%</Label>
                            <Input
                              type="checkbox"
                              id="square-switch14"
                              switch="none"
                              checked={piece_identite_a_25_part}
                              name="piece_identite_a_25_part"
                              onChange={(e) => {
                                setPiece_identite_a_25_part(!piece_identite_a_25_part)
                              }}
                            // value={piece_identite_a_25_part}
                            />
                            <Label
                              htmlFor="square-switch14"
                              data-on-label="Oui"
                              data-off-label="Non"
                            />
                          </div>
                        </Col>
                        :
                        null
                      :
                      null
                  }

                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                        type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                        ?
                        <Col lg="4">
                          <div className="mb-3 square-switch d-flex justify-content-between">
                            <Label htmlFor="square-switch15">Copie Statut</Label>
                            <Input
                              type="checkbox"
                              id="square-switch15"
                              switch="none"
                              checked={copie_status}
                              name="copie_status"
                              onChange={(e) => {
                                setCopie_status(!copie_status)
                              }}
                            // value={copie_status}
                            />
                            <Label
                              htmlFor="square-switch15"
                              data-on-label="Oui"
                              data-off-label="Non"
                            />
                          </div>
                        </Col>
                        :
                        null
                      :
                      null
                  }

                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                        ?
                        <Row>
                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch16">Copie règlement interieur</Label>
                              <Input
                                type="checkbox"
                                id="square-switch16"
                                switch="none"
                                checked={copie_reglement_interieur}
                                name="copie_reglement_interieur"
                                onChange={(e) => {
                                  setCopie_reglement_interieur(!copie_reglement_interieur)
                                }}
                              // value={copie_reglement_interieur}
                              />
                              <Label
                                htmlFor="square-switch16"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch17">Copie acte enregistrement</Label>
                              <Input
                                type="checkbox"
                                id="square-switch17"
                                switch="none"
                                checked={copie_acte_enregistrement}
                                name="copie_acte_enregistrement"
                                onChange={(e) => {
                                  setCopie_acte_enregistrement(!copie_acte_enregistrement)
                                }}
                              // value={copie_acte_enregistrement}
                              />
                              <Label
                                htmlFor="square-switch17"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch18">Copie publication journal officiel</Label>
                              <Input
                                type="checkbox"
                                id="square-switch18"
                                switch="none"
                                checked={copie_publication_journal_officiel}
                                name="copie_publication_journal_officiel"
                                onChange={(e) => {
                                  setCopie_publication_journal_officie(!copie_publication_journal_officiel)
                                }}
                              // value={copie_publication_journal_officiel}
                              />
                              <Label
                                htmlFor="square-switch18"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>
                        </Row>
                        :
                        null
                      :
                      null
                  }

                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                        ?
                        <>
                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch19">Copie dernier rapport</Label>
                              <Input
                                type="checkbox"
                                id="square-switch19"
                                switch="none"
                                checked={copie_dernier_rapport}
                                name="copie_dernier_rapport"
                                onChange={(e) => {
                                  setCopie_dernier_rapport(!copie_dernier_rapport)
                                }}
                              // value={copie_dernier_rapport}
                              />
                              <Label
                                htmlFor="square-switch19"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch20">Articles 41_42_43</Label>
                              <Input
                                type="checkbox"
                                id="square-switch20"
                                switch="none"
                                checked={articles41_42_43}
                                name="articles41_42_43"
                                onChange={(e) => {
                                  setArticles41_42_43(!articles41_42_43)
                                }}
                              // value={articles41_42_43}
                              />
                              <Label
                                htmlFor="square-switch20"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>
                        </>
                        :
                        null
                      :
                      null
                  }

                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
                        ?
                        <Col lg="4">
                          <div className="mb-3 square-switch d-flex justify-content-between">
                            <Label htmlFor="square-switch21">CIP / Attestation de residence</Label>
                            <Input
                              type="checkbox"
                              id="square-switch21"
                              switch="none"
                              checked={cip_attestation_residence}
                              name="cip_attestation_residence"
                              onChange={(e) => {
                                setCip_attestation_residence(!cip_attestation_residence)
                              }}
                            // value={cip_attestation_residence}
                            />
                            <Label
                              htmlFor="square-switch21"
                              data-on-label="Oui"
                              data-off-label="Non"
                            />
                          </div>
                        </Col>
                        :
                        null
                      :
                      null
                  }

                  {
                    type_de_compte
                      ?
                      type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
                        ?
                        <>
                          <Col lg="4">
                            <div className="mb-3 square-switch d-flex justify-content-between">
                              <Label htmlFor="square-switch22">Justificatif activite</Label>
                              <Input
                                type="checkbox"
                                id="square-switch22"
                                switch="none"
                                checked={justificatif_activite}
                                name="justificatif_activite"
                                onChange={(e) => {
                                  setJustificatif_activite(!justificatif_activite)
                                }}
                              // value={justificatif_activite}
                              />
                              <Label
                                htmlFor="square-switch22"
                                data-on-label="Oui"
                                data-off-label="Non"
                              />
                            </div>
                          </Col>
                        </>
                        :
                        null
                      :
                      null
                  }
                </Row>
              </Form>
            </div>
          </TabPane>
          <Col lg="12" className="mt-3">
            <Button color="primary" onClick={handleFilter}>Valider</Button>
          </Col>
          <Row> 
            <Col className="bg-white">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={compte || []}
                    isGlobalFilter={true}
                    isAddUsers={true}
                    isAddTableWithoutBorderStrap={true}
                    handleUserClicks={""}
                    customPageSize={50}
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

RapportCompteClient.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default RapportCompteClient;
