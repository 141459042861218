const orderList = [
    {
        id: 1,
      orderID: "#FF7842",
      date: "10 Jul, 2020",
      Amount:"141",
      supplierId: "#VZ001",
      supplierName:"Nesta Technologies",
      supplierAddress: "Bogota, Colombia",
      supplierPhoneno:" ",
      supplierEmail: "info@themesbrand.com",
      supplierWebsite: "www.themesbrand.com",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260.00 XOF ",
            quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250.00 XOF ",
            quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
        id: 2,
      orderID: "#FF7587",
      date: "10 Jul, 2020",
      Amount:"141",
      supplierId: "#MN0112",
      supplierName:"Crissy Holland",
      supplierAddress: "Bogota, Argentine",
      supplierPhoneno:"013-345-6419",
      supplierEmail: "TerryBrown@dayrep.com",
      supplierWebsite: " ",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 5,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
        id: 3,
      orderID: "#FO7415",
      date: "10 Jul, 2020",
      Amount:"141",
      supplierId: "#VZ003",
      supplierName:"Force Medicines",
      supplierAddress: "Pekin, China",
      supplierPhoneno:"012-345-6109",
      supplierWebsite: "www.themesbrand.com",
      supplierEmail: "info@themesbrand.com",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 5,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
        id: 4,
      orderID: "#FG7115",
      date: "10 Jul, 2020",
      Amount:"141",
      supplierId: "#VZ005",
      supplierName:"Zoetic Fashion",
      supplierAddress: "Cotonou, Bénin",
      supplierPhoneno:"012-345-6109",
      supplierEmail: "AmyMcDonald@jourrapide.com",
      supplierWebsite: " ",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Grabatyny tach",
            price: "260 XOF",
             quantity: 8,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 7,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
        id: 5,
      orderID: "#FS7115",
      date: "10 Jul, 2020",
      Amount:"141",
      supplierId: "#MN0117",
      supplierName:"Jessie Jacobs",
      supplierAddress: "Darfour, Soudan",
      supplierPhoneno:"012-345-6109",
      supplierEmail: "JessieJacobs@teleworm.us",
      supplierWebsite: " ",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 7,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
        id: 6,
      orderID: "#FD7115",
      date: "4 Jul, 2020",
      Amount:"141",
      supplierId: "#MN0117",
      supplierName:"Jessie Jacobs",
      supplierAddress: "BooBoo, MeatSalam",
      supplierPhoneno:"012-345-6109",
      supplierEmail: "info@themesbrand.com",
      supplierWebsite: "www.themesbrand.com",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "South park dvd ",
            price: "250 XOF",
             quantity: 7,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
]
export { orderList}