/* CONTACTS */
export const GET_MYCONTACTS = "GET_MYCONTACTS"
export const GET_MYCONTACTS_SUCCESS = "GET_MYCONTACTS_SUCCESS"
export const GET_MYCONTACTS_FAIL = "GET_MYCONTACTS_FAIL"

/* CONTACTS INFO */
export const GET_MYCONTACT_INFO = "GET_MYCONTACT_INFO"
export const GET_MYCONTACT_INFO_SUCCESS = "GET_MYCONTACT_INFO_SUCCESS"
export const GET_MYCONTACT_INFO_FAIL = "GET_MYCONTACT_INFO_FAIL"

/**
 * add CONTACT
 */
 export const ADD_NEW_MYCONTACT = "ADD_NEW_MYCONTACT"
 export const ADD_MYCONTACT_SUCCESS = "ADD_MYCONTACT_SUCCESS"
 export const ADD_MYCONTACT_FAIL = "ADD_MYCONTACT_FAIL"

 /**
 * Edit CONTACT
 */
export const UPDATE_MYCONTACT = "UPDATE_MYCONTACT"
export const UPDATE_MYCONTACT_SUCCESS = "UPDATE_MYCONTACT_SUCCESS"
export const UPDATE_MYCONTACT_FAIL = "UPDATE_MYCONTACT_FAIL"

/**
 * Delete CONTACT
 */
export const DELETE_MYCONTACT = "DELETE_MYCONTACT"
export const DELETE_MYCONTACT_SUCCESS = "DELETE_MYCONTACT_SUCCESS"
export const DELETE_MYCONTACT_FAIL = "DELETE_MYCONTACT_FAIL"


