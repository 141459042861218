import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
  GET_BONS,
  GET_BON_DETAIL,
  ADD_NEW_BON,
  UPDATE_BON,
  DELETE_BON
 } from "./actionTypes"
import {
  getBonsSuccess,
  getBonsFail,
  getBonDetailSuccess,
  getBonDetailFail,
  addBonSuccess,
  addBonFail,
  updateBonSuccess,
  updateBonFail,
  deleteBonSuccess,
  deleteBonFail
} from "./actions"

//Include Both Helper File with needed methods
import { 
  getBons,
  getBonDetail,
  addNewBon,
  updateBon,
  deleteBon,
 } from "../../helpers/fakebackend_helper"

function* fetchBons() {
  try {
    const response = yield call(getBons)
    yield put(getBonsSuccess(response))
  } catch (error) {
    yield put(getBonsFail(error))
  }
}

function* onUpdateBon ({payload: bon }) {
  try {
    const response = yield call(updateBon, bon);
    yield put(updateBonSuccess(response));
  } catch (error) {
    yield put(updateBonFail(error));
  }
}

function* onDeleteBon ({payload: bon}) {
  try {
    const response = yield call(deleteBon, bon);
    yield put(deleteBonSuccess(response));
  } catch (error) {
    yield put(deleteBonFail(error));
  }
}

function* onAddNewBon ({ payload: bon}) {
  try {
    const response = yield call(addNewBon, bon);
    yield put(addBonSuccess(response));
  } catch (error) {
    yield put(addBonFail(error));
  }
}

function* fetchBonDetail({ bonId }) {
  try {
    const response = yield call(getBonDetail, bonId)
    yield put(getBonDetailSuccess(response))
  } catch (error) {
    yield put(getBonDetailFail(error))
  }
}

function* bonSaga() {
  yield takeEvery(GET_BONS, fetchBons)
  yield takeEvery(GET_BON_DETAIL, fetchBonDetail)
  yield takeEvery(ADD_NEW_BON, onAddNewBon);
  yield takeEvery(UPDATE_BON, onUpdateBon);
  yield takeEvery(DELETE_BON, onDeleteBon);
}

export default bonSaga
