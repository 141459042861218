// Clients

const myContacts = [
    {
      id: 1,
      contactId: "#MN0123",
      contactName: "William Shipp",
      contactEmail: "WilliamShipp@jourrapide.com",
      contactPhoneno:"012-345-6789",
      contactAddress: "Los Angeles, USA",
      contactType: "Client",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 2,
      contactId: "#MN0122",
      contactName: "Joe Hardy",
      contactEmail: "JoeHardy@dayrep.com",
      contactPhoneno:"012-345-7489",
      contactAddress: "Los Angeles, USA",
      contactType: "Fournisseur",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 3,
      contactId: "#MN0121",
      contactName: "Thomas Hankins",
      contactEmail: "ThomasHankins@dayrep.com",
      contactPhoneno:"012-587-6789",
      contactAddress: "Los Angeles, USA",
      contactType: "Fournisseur",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 4,
      contactId: "#MN0120",
      contactName: "Mary Frazier",
      contactEmail: "MaryFrazier@armyspy.com",
      contactPhoneno:"012-345-4789",
      contactAddress: "Los Angeles, USA",
      contactType: "Client",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 5,
      contactId: "#MN0119",
      contactName: "Sam Perry",
      contactEmail: "SamPerry@rhyta.com",
      contactPhoneno:"012-214-6789",
      contactAddress: "Los Angeles, USA",
      contactType: "Client",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 6,
      contactId: "#MN0118",
      contactName: "Muriel Myers",
      contactEmail: "MurielMyers@rhyta.com",
      contactPhoneno:"012-345-4519",
      contactAddress: "Los Angeles, USA",
      contactType: "Fournisseur",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 7,
      contactId: "#MN0117",
      contactName: "Jessie Jacobs",
      contactEmail: "JessieJacobs@teleworm.us",
      contactPhoneno:"012-345-6109",
      contactAddress: "Los Angeles, USA",
      contactType: "Fournisseur",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 8,
      contactId: "#MN0116",
      contactName: "Edward King",
      contactEmail: "EdwardKing@teleworm.us",
      contactPhoneno:"012-385-6789",
      contactAddress: "Los Angeles, USA",
      contactType: "Client",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 9,
      contactId: "#MN0115",
      contactName: "Stacy Webster",
      contactEmail: "StacyWebster@armyspy.com",
      contactPhoneno:"012-345-9889",
      contactAddress: "Los Angeles, USA",
      contactType: "Fournisseur",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 10,
      contactId: "#MN0114",
      contactName: "Amy McDonald",
      contactEmail: "AmyMcDonald@jourrapide.com",
      contactPhoneno:"074-345-6789",
      contactAddress: "Los Angeles, USA",
      contactType: "Client",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 11,
      contactId: "#MN0113",
      contactName: "Terry Brown",
      contactEmail: "TerryBrown@dayrep.com",
      contactPhoneno:"013-345-6419",
      contactAddress: "Los Angeles, USA",
      contactType: "Fournisseur",
      categorie: "Particulier",
      website: " ",
    },
    {
      id: 12,
      contactId: "#MN0112",
      contactName: "Crissy Holland",
      contactEmail: "CrissyHolland@armyspy.com",
      contactPhoneno:"012-345-6789",
      contactAddress: "Los Angeles, USA",
      contactType: "Fournisseur",
      categorie: "Particulier",
      website: " ",
    },
 
    {
        id: 13,
        contactId: "#VZ001",
        contactName: "Nesta Technologies",
        contactEmail: "info@themesbrand.com", 
        contactPhoneno:"012-345-6789", 
        contactAddress: "Los Angeles, USA",
        contactType: "Fournisseur",
        categorie: "Entreprise",
        website: "www.themesbrand.com",  
    },
    {
        id: 14,
        contactId: "#VZ002",
        contactName: "iTest Factory",
        contactEmail: "info@themesbrand.com",
        contactPhoneno:"012-345-6789",
        contactAddress: "Berlin, Germany",
        contactType: "Fournisseur",
        categorie: "Entreprise",
        website: "www.themesbrand.com",     
    },
    {
        id: 15,
        contactId: "#VZ003",
        contactName: "Force Medicines",
        contactEmail: "info@themesbrand.com", 
        contactPhoneno:"012-345-6789",
        contactAddress: "Phoenix, USA",
        contactType: "Fournisseur",
        categorie: "Entreprise",
        website: "www.themesbrand.com",
    },
    {
        id: 16,
        contactId: "#VZ004",
        contactName: "Digitech Galaxy",
        contactEmail: "info@themesbrand.com", 
        contactPhoneno:"012-345-6789",
        contactAddress: "Bogota, Colombia",
        contactType: "Client",
        categorie: "Entreprise",
        website: "www.themesbrand.com",     
    },
    {
        id: 17,
        contactId: "#VZ005",
        contactName: "Zoetic Fashion",
        contactEmail: "info@themesbrand.com",
        contactPhoneno:"012-345-6789", 
        contactAddress: "Brasilia, Brazil",
        contactType: "Client",
        categorie: "Entreprise",
        website: "www.themesbrand.com",    
    },
    {
        id: 18,
        contactId: "#VZ006",
        contactName: "Micro Design",
        contactEmail: "info@themesbrand.com",
        contactPhoneno:"012-345-6789",
        contactAddress: "Windhoek, Namibia",
        contactType: "Client",
        categorie: "Entreprise",
        website: "www.themesbrand.com",
    },
    {
        id: 19,
        contactId: "#VZ007",
        contactName: "Syntyce Solutions",
        contactEmail: "info@themesbrand.com",
        contactPhoneno:"012-345-6789",
        contactAddress: "Damascus, Syria",
        contactType: "Fournisseur",
        categorie: "Entreprise",
        website: "www.themesbrand.com",
    },
    {
        id: 20,
        contactId: "#VZ008",
        contactName: "Meta4Systems",
        contactEmail: "info@themesbrand.com", 
        contactPhoneno:"012-345-6789",
        contactAddress: "London, UK",
        contactType: "Client",
        categorie: "Entreprise",
        website: "www.themesbrand.com",    
    },
    {
        id: 21,
        contactId: "#VZ009",
        contactName: "Moetic Fashion",
        contactEmail: "info@themesbrand.com",
        contactPhoneno:"012-345-6789", 
        contactAddress: "Damascus, Syria",
        contactType: "Fournisseur",
        categorie: "Entreprise",
        website: "www.themesbrand.com", 
    },
    {
        id: 22,
        contactId: "#VZ0010" ,   
        contactName: "Syntyce Solutions",
        contactEmail: "info@themesbrand.com",
        contactPhoneno:"012-345-6789",
        contactAddress: "Berlin, Germany",
        contactType: "Fournisseur",
        categorie: "Entreprise",
        website: "www.themesbrand.com",     
    },
];

export  {myContacts }

