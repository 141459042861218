import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from "react-toastify"

const UserProfile = props => {

  document.title=" Profile | OBIDON INTERNATIONAL"
  
  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [name, setname] = useState("");

  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [avatar, setAvatar] = useState("")

  const getCurrentUser = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/profil`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if ( response ) {
        console.log("profil data :: ", response.data)
        setNom(response.data.nom)
        setPrenom(response.data.prenom)
        setEmail(response.data.email)
        setRole(response.data.role)
        setAvatar(response.data.avatar)
      }
    } catch (error) {
      console.log("profil error :: ", error)
    }
  }

  
  useEffect(()=>{
    if(accessToken){
      getCurrentUser()
    }
  }, [accessToken])
  // useEffect(() => {
  //   if ( accessToken ) {
  //     console.log("access token :: ", accessToken)
  //   }
  //   if ( user ) {
  //     console.log("user data :: ", user)
  //     setNom(user.nom)
  //     setPrenom(user.prenom)
  //     setEmail(user.email)
  //     setRole(user.role)
  //     setAvatar(user.avatar)
  //   }
  // }, [accessToken, user])

  const selectProfileState = (state) => state.Profile;
    const ProfileProperties = createSelector(
      selectProfileState,
        (profile) => ({
          error: profile.error,
          success: profile.success,
        })
    );

    const {
      error,
      success
  } = useSelector(ProfileProperties);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nom: nom || '',
      prenom : prenom || '',
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("Veuillez entrer votre nouveau nom"),
      prenom: Yup.string().required("Veuillez entrer votre nouveau prénom"),
    }),
    onSubmit: (values) => {
      alert("Modifier nom et prénom")
      axios.post(
        `${key.apiBaseURL}/updatenomprenom`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      ).then((data)=>{
        toast.success("Nom et prénom modifié avec succès.")
        getCurrentUser()
      }).catch((error)=>{
        toast.error("Echec de la modification. Réessayez plus tard.")
      })
    }
  });

  const validationNewPassword = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      newpassword : '',
      confirmpassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Veuillez entrer l'ancien mot de passe"),
      newpassword: Yup.string().required("Veuillez entrer le nouveau mot de passe"),
      confirmpassword: Yup.string().required("Veuillez entrer la confirmation du mot de passe"),
    }),
    onSubmit: (values) => {
      alert("Modifier mot de passe")
      axios.post(
        `${key.apiBaseURL}/updatepassword`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      ).then((data)=>{
        toast.success("Mot de passe modifier avec succès.")
      }).catch((error)=>{
        toast.error("Echec de la modification. Réessayez plus tard.")
      })
    }
  });


  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Profil" breadcrumbItem="Profil" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={key.avatarBaseURL + avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{role}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className="card-title mb-4">Changer de nom et prénom</h4>
              <Card>
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="form-group">
                      <Label className="form-label">Nom <span className="text-danger">*</span></Label>
                      <Input
                        name="nom"
                        className="form-control border-secondary"
                        placeholder="Entrez votre nouveau nom"
                        type="text"
                        value={validation.values.nom || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.nom && validation.errors.nom ? true : false
                        }
                      />
                      {validation.touched.nom && validation.errors.nom ? (
                        <FormFeedback type="invalid">{validation.errors.nom}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Prénom <span className="text-danger">*</span></Label>
                      <Input
                        name="prenom"
                        className="form-control border-secondary"
                        placeholder="Entrez votre nouveau prénom"
                        type="text"
                        value={validation.values.prenom || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.prenom && validation.errors.prenom ? true : false
                        }
                      />
                      {validation.touched.prenom && validation.errors.prenom ? (
                        <FormFeedback type="invalid">{validation.errors.prenom}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="text-center mt-4">
                      <Button type="submit" color="danger">
                        Mettre à jour
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <h4 className="card-title mb-4">Changer votre mot de passe</h4>
              <Card>
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationNewPassword.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="form-group">
                      <Label className="form-label">Ancien mot de passe <span className="text-danger">*</span></Label>
                      <Input
                        name="password"
                        className="form-control border-secondary"
                        placeholder="Entrez l'ancien mot de passe"
                        type="text"
                        value={validationNewPassword.values.password || ""}
                        onChange={validationNewPassword.handleChange}
                        onBlur={validationNewPassword.handleBlur}
                        invalid={
                          validationNewPassword.touched.password && validationNewPassword.errors.password ? true : false
                        }
                      />
                      {validationNewPassword.touched.password && validationNewPassword.errors.password ? (
                        <FormFeedback type="invalid">{validationNewPassword.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Nouveau mot de passe <span className="text-danger">*</span></Label>
                      <Input
                        name="newpassword"
                        className="form-control border-secondary"
                        placeholder="Entrez votre nouveau mot de pass"
                        type="text"
                        value={validationNewPassword.values.newpassword || ""}
                        onChange={validationNewPassword.handleChange}
                        onBlur={validationNewPassword.handleBlur}
                        invalid={
                          validationNewPassword.touched.newpassword && validationNewPassword.errors.newpassword ? true : false
                        }
                      />
                      {validationNewPassword.touched.newpassword && validationNewPassword.errors.newpassword ? (
                        <FormFeedback type="invalid">{validationNewPassword.errors.newpassword}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Confirmer nouveau mot de passe <span className="text-danger">*</span></Label>
                      <Input
                        name="confirmpassword"
                        className="form-control border-secondary"
                        placeholder="Confirmez votre nouveau mot de passe"
                        type="text"
                        value={validationNewPassword.values.confirmpassword || ""}
                        onChange={validationNewPassword.handleChange}
                        onBlur={validationNewPassword.handleBlur}
                        invalid={
                          validationNewPassword.touched.confirmpassword && validationNewPassword.errors.confirmpassword ? true : false
                        }
                      />
                      {validationNewPassword.touched.confirmpassword && validationNewPassword.errors.confirmpassword ? (
                        <FormFeedback type="invalid">{validationNewPassword.errors.confirmpassword}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="text-center mt-4">
                      <Button type="submit" color="danger">
                        Mettre à jour
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
