import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Form,
    Input,
    FormFeedback,
    Label,
    Container,
} from "reactstrap";
import CategorieContent from "./categorieContent";


const ProductsCategory = () => {
    document.title = "Catégorie Grid | Minible - Responsive Bootstrap 5 Admin Dashboard";

    

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [categories, setCategories] = useState([
        { id: 1, nom: "Produits d'élevage", produits: 36000},
    ]);
    const [currentCategorie, setCurrentCategorie] = useState(null);

    const toggle = useCallback(() => {
        setModal(!modal);
    }, [modal]);

    const handleAddCategorie = () => {
        setIsEdit(false);
        setCurrentCategorie(null);
        toggle();
    };

    const handleEditCategorie = (categorie) => {
        setIsEdit(true);
        setCurrentCategorie(categorie);
        toggle();
    };

    const handleDeleteCategorie = (id) => {
        setCategories(categories.filter(categorie => categorie.id !== id));
    };

    const handleSubmit = (values) => {
        if (isEdit) {
            setCategories(categories.map(categorie => (categorie.id === values.id ? values : categorie)));
        } else {
            setCategories([...categories, { ...values, id: categories.length + 1 }]);
        }
        toggle();
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: currentCategorie || { nom: "", produits: "", image: null },
        validationSchema: Yup.object({
            nom: Yup.string().required("Nom de catégorie est requis"),
            produits: Yup.number().required("Nombre de produits est requis"),
        }),
        onSubmit: handleSubmit,
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="">
                        <Col md={6}>
                            <div className="mb-3">
                                <Link to="#" className="btn btn-success waves-effect waves-light ms-auto" onClick={handleAddCategorie}>
                                    <i className="mdi mdi-plus me-2"></i> Ajouter une catégorie
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <CategorieContent categories={categories} onEdit={handleEditCategorie} onDelete={handleDeleteCategorie} />
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Modifier la catégorie" : "Nouvelle catégorie"}
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={formik.handleSubmit}>
                                <div className="mb-3">
                                    <Label className="form-label">Nom de la catégorie</Label>
                                    <Input
                                        name="nom"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.nom}
                                        invalid={formik.touched.nom && formik.errors.nom ? true : false}
                                    />
                                    {formik.touched.nom && formik.errors.nom ? (
                                        <FormFeedback type="invalid">{formik.errors.nom}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Nombre de produits</Label>
                                    <Input
                                        name="produits"
                                        type="number"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.produits}
                                        invalid={formik.touched.produits && formik.errors.produits ? true : false}
                                    />
                                    {formik.touched.produits && formik.errors.produits ? (
                                        <FormFeedback type="invalid">{formik.errors.produits}</FormFeedback>
                                    ) : null}
                                </div>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button type="submit" color="success">
                                                Enregistrer
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProductsCategory;
