import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


const CategoryListModal = ({ isOpen, toggle, categorieContact }) => {
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Liste de catégories
        </ModalHeader>
        <ModalBody>
        <p> 
            {
                categorieContact && categorieContact.map((item, index) => (
                    <p key={index} value={item.id} >{item.libelle}</p>
                ))
            }
        </p>
        </ModalBody>
      </Modal>
    );
  };
  
  CategoryListModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    categorieContact: PropTypes.func.isRequired,
  };
  
  export default CategoryListModal;