import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerUser";
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import BreadcrumbsAddUsers from "../../components/Common/BreadcrumbUsers";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "../../store/actionsUsers";

import { Role, Email, Prenom } from "./UsersListCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody
} from "reactstrap";
import DeleteModal from "../../components/Common/DeleteModalUsers";
import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/ReactToastify.css'

function Users() {
  document.title = "Liste des Utilisateurs";

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState([]);

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [utilisateurs, setUtilisateurs] = useState()
  const getAllUser = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/getalluser`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if ( response ) {
        console.log("user list :: ", response.data)
        setUtilisateurs(response.data)
      }
    } catch (error) {
      alert("Aucun utilisateur trouvé")
    }
  }

  useEffect(()=>{
    if ( accessToken ) {
      getAllUser()
    }
  }, [accessToken])

  const handleAddUser = () => {
    setIsEdit(false);
    toggle();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nom: (user && user.nom) || "",
      prenom: (user && user.prenom) || "",
      email: (user && user.email) || "",
      role: (user && user.role) || "", // Default role set to "Collaborateur"
      img: (user && user.img) || "", // New field for image
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("Veuillez entrer le nom"),
      prenom: Yup.string().required("Veuillez entrer le prenom"),
      email: Yup.string().email("Email invalide").required("Veuillez entrer l'email"),
      role: Yup.string().required("Veuillez entrer le rôle"),
      img: Yup.mixed().required("Veuillez ajouter une photo"), // Validation for image
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: user.id,
          nom: values.nom,
          prenom: values.prenom,
          email: values.email,
          role: values.role,
          img: values.img,
        };
      } else {
        const formData = new FormData();
        formData.append('nom', values.nom);
        formData.append('prenom', values.prenom);
        formData.append('email', values.email);
        formData.append('role', values.role);
        formData.append('avatar', values.img);
        // console.log("avatar : ", values.img)
        axios.post(
          `${key.apiBaseURL}/confirm_mail`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data' // Définir le type de contenu pour FormData
            }
          }
        ).then((data) => {
          getAllUser()
          toast.success("L'envoie est un succès.")
          validation.resetForm();
          toggle();
          console.log("success :: ", data)
        }).catch((error)=>{
          toast.error("L'envoie a échoué.")
          console.log("erreur :: ", error.response)
        })
      }
    },
  });

  const dispatch = useDispatch();

  const selectContactsState = (state) => state.contacts;
  const ContactsProperties = createSelector(
    selectContactsState,
    (Contacts) => ({
      users: Contacts.users,
    })
  );

  // const { users } = useSelector(ContactsProperties);

  // useEffect(() => {
  //   if (users && !users.length) {
  //     dispatch(onGetUsers());
  //   }
  // }, [dispatch, users]);

  // useEffect(() => {
  //   if (users && !users.length) {
  //     dispatch(onGetUsers());
  //   }
  // }, [dispatch, users]);

  // useEffect(() => {}, [users]);

  // useEffect(() => {
  //   if (!isEmpty(users)) {
  //     setIsEdit(false);
  //   }
  // }, [users]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setUser(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleUserClick = useCallback((arg) => {
    const user = arg;
    setUser({
      id: user.id,
      nom: user.nom,
      prenom: user.prenom,
      email: user.email,
      role: user.role,
      img: user.img,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  //delete user
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (user) => {
    setUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (user.id) {
      dispatch(onDeleteUser(user.id));
      setDeleteModal(false);
    }
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => (
          <React.Fragment>
            {!cellProps.row.original.avatar ? (
              <div className="avatar-xs d-inline-block me-2">
                <div className="avatar-title bg-primary-subtle rounded-circle text-primary">
                  <i className="mdi mdi-account-circle m-0"></i>
                </div>
              </div>
            ) : (
              <img
                className="avatar-xs rounded-circle me-2"
                src={ `${key.avatarBaseURL}${cellProps.row.original.avatar}`}
                alt=""
              />
            )}
            <Link to="#" className="text-body">
              {`${cellProps.row.original.nom} ${cellProps.row.original.prenom}`}
            </Link>
          </React.Fragment>
        ),
      },
      {
        Header: "Rôle",
        accessor: "role",
        filterable: true,
        Cell: (cellProps) => <Role {...cellProps} />,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => <Email {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => (
          <div className="d-flex gap-3 users">
            <ul className="list-inline font-size-20 contact-links mb-0">
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => {
                    const user = cellProps.row.original;
                    handleUserClick(user);
                  }}
                >
                  <i className="uil uil-pen font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Éditer
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const user = cellProps.row.original;
                    onClickDelete(user);
                  }}
                >
                  <i className="uil uil-trash-alt font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Supprimer
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li className="list-inline-item dropdown">
                <Link to="#" className="text-truncate text-success">
                  <i className="uil uil-eye font-size-16" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    Suspendre
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          </div>
        ),
      },
    ],
    [handleUserClick]
  );

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <BreadcrumbsAddUsers title="Utilisateurs" breadcrumbItem="Liste des utilisateurs" />
          <Row>
            <Col>
              <div className="mb-3">
                <Link to="#" className="btn btn-success waves-effect waves-light ms-auto" onClick={handleAddUser}>
                 + Ajouter un utilisateur
                </Link>
              </div>
            </Col>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={utilisateurs || []}
                    isGlobalFilter={true}
                    isAddUsers={true}
                    isAddTableWithoutBorderStrap={true}
                    handleUserClicks={handleUserClicks}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Éditer utilisateur" : "Ajouter un utilisateur"}
            </ModalHeader>
           <ModalBody>
  <Form
    onSubmit={(e) => {
      e.preventDefault();
      validation.handleSubmit();
      return false;
    }}
  >
    <Row>
      <Col className="col-12">
        {!isEdit && (
          <>
            <div className="mb-3">
              <Label className="form-label">Nom <span className="text-danger">*</span></Label>
              <Input
                name="nom"
                type="text"
                className="border-secondary"
                placeholder="Entrez le nom"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.nom || ""}
                invalid={validation.touched.nom && validation.errors.nom ? true : false}
              />
              {validation.touched.nom && validation.errors.nom ? (
                <FormFeedback type="invalid">{validation.errors.nom}</FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Prénom <span className="text-danger">*</span></Label>
              <Input
                name="prenom"
                type="text"
                className="border-secondary"
                placeholder="Entrez le prénom"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.prenom || ""}
                invalid={validation.touched.prenom && validation.errors.prenom ? true : false}
              />
              {validation.touched.prenom && validation.errors.prenom ? (
                <FormFeedback type="invalid">{validation.errors.prenom}</FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Email <span className="text-danger">*</span></Label>
              <Input
                name="email"
                type="email"
                className="border-secondary"
                placeholder="Entrez le mail"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={validation.touched.email && validation.errors.email ? true : false}
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Photo</Label>
              <Input
                name="img"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  validation.setFieldValue("img", event.currentTarget.files[0]);
                }}
                onBlur={validation.handleBlur}
                invalid={validation.touched.img && validation.errors.img ? true : false}
              />
              {validation.touched.img && validation.errors.img ? (
                <FormFeedback type="invalid">{validation.errors.img}</FormFeedback>
              ) : null}
            </div>
          </>
        )}
        <div className="mb-3">
          <Label className="form-label">Rôle <span className="text-danger">*</span></Label>
          <Input
            name="role"
            type="select"
            className="border-secondary"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.role}
            invalid={validation.touched.role && validation.errors.role ? true : false}
          >
            <option value="">Selectionnez un rôle</option>
            <option value="Admin">Admin</option>
            <option value="Collaborateur">Collaborateur</option>
            <option value="Caissier">Caissier</option>
            <option value="Comptable">Comptable</option>
            <option value="Superviseur">Superviseur</option>
          </Input>
          {validation.touched.role && validation.errors.role ? (
            <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-end">
          <button type="submit" className="btn btn-success save-user">
            Sauvegarder
          </button>
        </div>
      </Col>
    </Row>
  </Form>
</ModalBody>

          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

Users.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Users;
