import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, UncontrolledAlert } from "reactstrap";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useParams } from "react-router-dom";
import  withRouter from "../../components/Common/withRouter"


// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import axios from 'axios';
import { key } from '../../constants/key';
import { ToastContainer, toast } from 'react-toastify'

const Newpassword = props => {

  document.title="Réinitialise votre mot de passe  | OBIDON INTERNATIONAL"

  const params = useParams('token')

  const [success, setSuccess] = useState()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newpassword: "",
      confirmpassword: ""
    },
    validationSchema: Yup.object({
      newpassword: Yup.string().required("Veuillez entrer votre nouveau mot de passe"),
      confirmpassword: Yup.string().required("Veuillez confirmer votre nouveau mot de passe"),
    }),
    onSubmit: (values) => {
      const token = params.token
      console.log("token :: ", token)
      axios.post(
        `${key.apiBaseURL}/resetpass`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      ).then(data => {
        toast.success("Mot de passe réinitialisé avec succès")
        setSuccess(true)
      }).catch(error => {
        toast.error("Echec lors de la réinitialisation")
        setSuccess(false)
      })
    }
  });

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  
  const selectForgotPasswordState = (state) => state.ForgetPassword;
    const ForgotPasswordProperties = createSelector(
      selectForgotPasswordState,
        (forgetPassword) => ({
          forgetError: forgetPassword.forgetError,
          forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
        })
    );

    const {
      forgetError,
      forgetSuccessMsg
  } = useSelector(ForgotPasswordProperties);    

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>

      <div className="account-pages my-3  pt-sm-3">
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={5}>
              <div>

                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
                <Card>
                  <CardBody className="p-4">
                    {
                      success === true
                      ?
                      (<Col>
                        <UncontrolledAlert
                          color="success"
                          className="alert-success alert-dismissible fade show mt-4 px-4 mb-0 text-center"
                          role="alert"
                        >
                          <i className="uil uil-check-circle d-block display-4 mt-2 mb-3 text-success"></i>
                          <h5 className="text-success">Succès</h5>
                          <Link to={"/login"}>
                            <p>Cliquez ici pour vous connecter</p>
                          </Link>
                        </UncontrolledAlert>
                      </Col>)
                      :
                        <div>
                          <div className="text-center mt-2">
                            <h5 className="text-primary">Réinitialisez votre mot de passe</h5>
                            {/* <p className="text-muted">Reset Password with Minible.</p> */}
                          </div>
                          <div className="p-2 mt-3">
                            {forgetError && forgetError ? (
                              <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                                {forgetError}
                              </Alert>
                            ) : null}
                            {forgetSuccessMsg ? (
                              <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                              {forgetSuccessMsg}
                              </Alert>
                            ) : null}

                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="mb-3">
                              <Label className="form-label">Nouveau mot de passe</Label>
                              <Input
                                name="newpassword"
                                className="form-control"
                                placeholder="Nouveau mot de passe"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.newpassword || ""}
                                invalid={
                                  validation.touched.newpassword && validation.errors.newpassword ? true : false
                                }
                              />
                              {validation.touched.newpassword && validation.errors.newpassword ? (
                                <FormFeedback type="invalid">{validation.errors.newpassword}</FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Confirmer le nouveau mot de passe</Label>
                              <Input
                                name="confirmpassword"
                                className="form-control"
                                placeholder="Confirmez le mot de passe"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmpassword || ""}
                                invalid={
                                  validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false
                                }
                              />
                              {validation.touched.confirmpassword && validation.errors.confirmpassword ? (
                                <FormFeedback type="invalid">{validation.errors.confirmpassword}</FormFeedback>
                              ) : null}
                            </div>
                            <Row className="mb-0">
                              <Col xs={12} className="text-end">
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Envoyer
                              </button>
                              </Col>
                            </Row>
                            <div className="mt-4 text-center">
                              <p className="mb-0">Vous vous en souvenez ? <Link to="/login" className="fw-medium text-primary"> Connexion </Link></p>
                            </div>
                          </Form>
                          </div>
                        </div>
                    }
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} OBIDON INTERNATIONAL{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Newpassword.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

export default withRouter(Newpassword);