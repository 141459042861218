import React, { useEffect, useState, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Input,
  FormFeedback,
  Label,
  Container,
  InputGroup,
  InputGroupText,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  Progress,
} from "reactstrap";
import DeleteModal from "../../components/Common/DeleteModal";
import CardContent from "./card-content";
import {
  deleteMyContact as onDeleteMyContact,
} from "../../store/actions";
import axios from "axios";
import { key } from "../../constants/key";
import CategoryModal from './new-categorie';
import CategoryListModal from './categorie-list';
import MultiStepProgressBar from "../../components/multiStep_form/MultiStepProgressBar";

const CardContact = () => {
  document.title="Contact"

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 2; // Total number of steps

  const goToNextStep = () => {
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };


  const [searchTerm, setSearchTerm] = useState("");
  const [categorieContact, setCategorieContact] = useState([]);
  const [contacts, setContacts] = useState([]);

  const getAllCategorie = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/getallcategoriecontact`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if ( response ) {
        // const data = response.data
        console.log("get all categorie data :: ", response)
        setCategorieContact(response.data.data)
      }
    } catch (error) {
      console.log("get all categorie error : ", error)
    }
  }
  
  const getAllContact = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/getallcontact`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      if ( response ) {
        // const data = response.data
        console.log("get all contact :: ", response.data[0])
        setContacts(response.data)
      }
    } catch (error) {
      console.log("get all contact error : ", error)
    }
  }

  useEffect(()=>{
    getAllCategorie()
    getAllContact()
  }, [accessToken])

  useEffect(()=>{
      console.log("all categorie :: ", categorieContact)
  }, [categorieContact])

  useEffect(()=>{
    console.log("all contact :: ", contacts)
    // console.log("all contact type :: ", typeof contacts)
}, [contacts])

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [mycontact, setMyContact] = useState([]);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      // contactId: (mycontact&& mycontact.contactId) || "",
      contactName: (mycontact&& mycontact.contactName) || "",
      contactEmail: (mycontact&& mycontact.contactEmail) || "",
      contactPhoneno: (mycontact&& mycontact.contactPhoneno) || "",
      contactAddress: (mycontact&& mycontact.contactAddress) || "",
      numeroIFU: (mycontact&& mycontact.numeroIFU) || "",
      RCCM: (mycontact&& mycontact.RCCM) || "",
      contactType: (mycontact&& mycontact.contactType) || "",
      categorie: (mycontact&& mycontact.categorie) || "",
      tarif: (mycontact&& mycontact.tarif) || "",
    },
    validationSchema: Yup.object({
      // contactId: Yup.string().required("Please Enter the contact Id"),
      contactName: Yup.string().required("Please Enter the name"),
      contactEmail: Yup.string().required("Please Enter the email"),
      contactPhoneno: Yup.string().required("Please enter the phone number"),
      contactAddress: Yup.string().required("Please Enter the address"),
      numeroIFU: Yup.string().required("Please Enter the address"),
      RCCM: Yup.string().required("Please Enter the address"),
      contactType: Yup.string().required("Please Enter the contact type"),
      categorie: Yup.string().required("Please Enter The categorie"),
      tarif: Yup.string().required("Please Enter The categorie"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const newData = {
          id_contact: mycontact.id,
          nom: values.contactName,
          prenom: null,
          ifu: values.numeroIFU,
          rccm: values.RCCM,
          tel: values.contactPhoneno,
          email: values.contactEmail,
          adresse: values.contactAddress,
          type: values.contactType,
          categorie: values.categorie,
          tarif: values.tarif
        }
        axios.post(
          `${key.apiBaseURL}/modifycontact`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        .then((data)=>{
          console.log(data)
          //alert("Success")
          if(data.status === 200){
            validation.resetForm();
            getAllContact()
          }
        })
        .catch((error)=>{
          alert("Erreur lors de la modification du contact")
          console.log("modify contact error :: ", error)
        })
      } else {
        console.log("form data :: ", values)
        const data = {
          nom: values.contactName,
          prenom: null,
          ifu: values.numeroIFU,
          rccm: values.RCCM,
          tel: values.contactPhoneno,
          email: values.contactEmail,
          adresse: values.contactAddress,
          type: values.contactType,
          categorie: values.categorie,
          tarif: values.tarif
        }
        axios.post(
          `${key.apiBaseURL}/createcontact`,
          data,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        .then((data)=>{
          console.log(data)
          if(data.status === 201){
           // alert("Success")
            getAllContact()
            validation.resetForm();
          }
        })
        .catch((error)=>{
          alert("Erreur lors de la création du contact")
          console.log("create contact error :: ", error)
        })
      }
      toggle();
    },
  });
  const resetForm = () => {
    validation.resetForm();
  };


  const dispatch = useDispatch();

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setMyContact(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleMyContactClick = useCallback((arg) => {
    const mycontact = arg;
    console.log("updating contact : ", mycontact)
    setMyContact({
      id: mycontact.id,
      contactId: mycontact.id,
      contactName: mycontact.nom,
      contactEmail: mycontact.email,
      contactPhoneno: mycontact.tel,
      contactAddress: mycontact.adresse,
      numeroIFU: mycontact.ifu,
      RCCM: mycontact.rccm,
      contactType: mycontact.type,
      categorie: mycontact.categorie,
      tarif: mycontact.tarif,
    });

    setIsEdit(true);

    toggle();
  }, [toggle]);

  //delete contact
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (mycontact) => {
    setMyContact(mycontact);
    setDeleteModal(true);
  };

  const handleDeleteMyContact = () => {
    if (mycontact.id) {
      dispatch(onDeleteMyContact(mycontact.id));
      setDeleteModal(false);
    }
  };

  const handleAddMyContact = () => {
    setIsEdit(false);
    toggle();
  };


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredContacts = contacts.filter((contact) =>
    Object.values(contact).some((value) =>
      value && value.toString().toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : "")
    )
  );
  const count = contacts.length;

  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  
  const toggleCategoryModal = () => {
    setCategoryModalOpen(!categoryModalOpen);
  };
  const [categoryListModalOpen, setCategoryListModalOpen] = useState(false);

  const toggleListCategoryModal = () => {
    setCategoryListModalOpen(!categoryListModalOpen);
  };

  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteMyContact}
        onCloseClick={() => setDeleteModal(false)}
      />

      <CategoryModal 
        isOpen={categoryModalOpen} 
        toggle={toggleCategoryModal} 
        getAllCategorie={getAllCategorie} 
        accessToken={accessToken} 
      />
      <CategoryListModal 
        isOpen={categoryListModalOpen} 
        toggle={toggleListCategoryModal} 
        categorieContact={categorieContact} 
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contacts" breadcrumbItem="Contacts" />
          <Row className="align-items-center mb-3">
          <Col>
            <InputGroup>
              <InputGroupText>
                <i className="bx bx-search-alt search-icon"></i>
              </InputGroupText>
              <Input
                type="text"
                placeholder={`Rechercher parmi ${count} enregistrements...`}
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
            <style jsx>{`
              .input-group-text {
                background-color: #fff; 
                border: 0 ; 
              }
              .input-group-text .search-icon {
                color: #6c757d; 
              }
              .form-control {
                border: 0; 
              }
            `}</style>
            </Col>
            <Col xs="auto">
              <div>
              <UncontrolledDropdown className="float-end btn btn-success btn-sm">
              <DropdownToggle tag="a" className="text-body  text-white font-size-20 p-0" caret>
              <i className="uil uil-plus"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#" onClick={handleAddMyContact}>Ajouter un contact </DropdownItem>
                <DropdownItem href="#" onClick={toggleCategoryModal}>Ajouter une catégorie</DropdownItem>
                <DropdownItem href="#" onClick={toggleListCategoryModal}>Liste de catégories</DropdownItem>
              </DropdownMenu>
              </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
            <Row>
              {filteredContacts.map((contact, index) => (
                <Col xl={4} sm={5} key={index}>
                  <CardContent
                    user={contact}
                    onEdit={() => handleMyContactClick(contact)}
                    onDelete={() => onClickDelete(contact)}
                  />
                </Col>
              ))}
            </Row>
       
         <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Modifier le contact" : "Nouveau contact"}
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3 mx-3">
                <MultiStepProgressBar step={currentStep} />
              </Row>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                {currentStep === 0 && (
                  <div>
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="contactPhoneno">
                          Numéro Séquenciel 
                        </Label>
                        <Input
                          id="contactPhoneno"
                          name="contactPhoneno"
                          className="form-control"
                          placeholder="Numéro de téléphone"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactPhoneno || ""}
                          invalid={validation.touched.contactPhoneno && validation.errors.contactPhoneno}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.contactPhoneno && validation.errors.contactPhoneno ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contactPhoneno}
                          </FormFeedback>
                        ) : null}
                      </div>
                    <div className="mb-3">
                     <Label className="form-label" htmlFor="contactType">
                       Type de contact
                     </Label>
                     <Input
                       id="contactType"
                       name="contactType"
                       className="form-control"
                       placeholder="Type de contact"
                       type="text"
                       onChange={validation.handleChange}
                       onBlur={validation.handleBlur}
                       value={validation.values.contactType || ""}
                       invalid={validation.touched.contactType && validation.errors.contactType}
                       style={{ border: "1px solid black" }}
                     />
                     {validation.touched.contactType && validation.errors.contactType ? (
                       <FormFeedback type="invalid">
                         {validation.errors.contactType}
                       </FormFeedback>
                     ) : null}
                   </div>
                 
                   <div className="mb-3">
                     <Label className="form-label" htmlFor="categorie">
                       Catégorie
                     </Label>
                     <Input
                       id="categorie"
                       name="categorie"
                       className="form-control"
                       placeholder="Catégorie"
                       type="text"
                       onChange={validation.handleChange}
                       onBlur={validation.handleBlur}
                       value={validation.values.categorie || ""}
                       invalid={validation.touched.categorie && validation.errors.categorie}
                       style={{ border: "1px solid black" }}
                     />
                     {validation.touched.categorie && validation.errors.categorie ? (
                       <FormFeedback type="invalid">
                         {validation.errors.categorie}
                       </FormFeedback>
                     ) : null}
                   </div>
                 
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="contactName">
                          Nom
                        </Label>
                        <Input
                          id="contactName"
                          name="contactName"
                          className="form-control"
                          placeholder="Nom"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactName || ""}
                          invalid={validation.touched.contactName && validation.errors.contactName}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.contactName && validation.errors.contactName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contactName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    
                  </div>
                )}
                {currentStep === 1 && (
                  <div>
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="contactEmail">
                          Email
                        </Label>
                        <Input
                          id="contactEmail"
                          name="contactEmail"
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactEmail || ""}
                          invalid={validation.touched.contactEmail && validation.errors.contactEmail}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.contactEmail && validation.errors.contactEmail ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contactEmail}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="contactPhoneno">
                          Numéro de téléphone
                        </Label>
                        <Input
                          id="contactPhoneno"
                          name="contactPhoneno"
                          className="form-control"
                          placeholder="Numéro de téléphone"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactPhoneno || ""}
                          invalid={validation.touched.contactPhoneno && validation.errors.contactPhoneno}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.contactPhoneno && validation.errors.contactPhoneno ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contactPhoneno}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="contactPhoneno">
                          Numéro Whatsapp 
                        </Label>
                        <Input
                          id="contactPhoneno"
                          name="contactPhoneno"
                          className="form-control"
                          placeholder="Numéro de téléphone"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactPhoneno || ""}
                          invalid={validation.touched.contactPhoneno && validation.errors.contactPhoneno}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.contactPhoneno && validation.errors.contactPhoneno ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contactPhoneno}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="contactAddress">
                          Adresse
                        </Label>
                        <Input
                          id="contactAddress"
                          name="contactAddress"
                          className="form-control"
                          placeholder="Adresse"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactAddress || ""}
                          invalid={validation.touched.contactAddress && validation.errors.contactAddress}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.contactAddress && validation.errors.contactAddress ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contactAddress}
                          </FormFeedback>
                        ) : null}
                      </div>
                
                  </div>
                )}
                {currentStep === 2 && (
                  <div>
                    
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="numeroIFU">
                          Numéro IFU
                        </Label>
                        <Input
                          id="numeroIFU"
                          name="numeroIFU"
                          className="form-control"
                          placeholder="Numéro IFU"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.numeroIFU || ""}
                          invalid={validation.touched.numeroIFU && validation.errors.numeroIFU}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.numeroIFU && validation.errors.numeroIFU ? (
                          <FormFeedback type="invalid">
                            {validation.errors.numeroIFU}
                          </FormFeedback>
                        ) : null}
                      </div>
                    
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="RCCM">
                          RCCM
                        </Label>
                        <Input
                          id="RCCM"
                          name="RCCM"
                          className="form-control"
                          placeholder="RCCM"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.RCCM || ""}
                          invalid={validation.touched.RCCM && validation.errors.RCCM}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.RCCM && validation.errors.RCCM ? (
                          <FormFeedback type="invalid">
                            {validation.errors.RCCM}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="tarif">
                          Tarif
                        </Label>
                        <Input
                          id="tarif"
                          name="tarif"
                          className="form-control"
                          placeholder="Tarif"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.tarif || ""}
                          invalid={validation.touched.tarif && validation.errors.tarif}
                          style={{ border: "1px solid black" }}
                        />
                        {validation.touched.tarif && validation.errors.tarif ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tarif}
                          </FormFeedback>
                        ) : null}
                      </div>
                  </div>
                )}
               
                <div className="d-flex justify-content-between">
                  {currentStep > 0 && (
                    <Button color="secondary" onClick={goToPreviousStep}>
                      Précédent
                    </Button>
                  )}
                  {currentStep < totalSteps ? (
                    <Button color="primary" onClick={goToNextStep}>
                      Suivant
                    </Button>
                  ) : (
                    <Button color="primary" type="submit">
                      Soumettre
                    </Button>
                  )}
                </div>
              </Form>
            </ModalBody>
          </Modal>
       </Container> 
     </div>
    </React.Fragment>
  );
};

export default CardContact;
