import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames"
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerUser";
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import BreadcrumbsAddUsers from "../../components/Common/BreadcrumbUsers";

import {
    Col,
    Row,
    Card,
    CardBody,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/ReactToastify.css'

function CopyAjouterCompteClient(props) {
  document.title = "Comptes Clients";

  // const { id } = useParams()

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [num_compte, setNum_compte] = useState()
  const [intitule_client, setIntitule_client] = useState()
  const [adresse_client, setAdresse_client] = useState()
  const [tel_fixe_client, setTel_fixe_client] = useState()
  const [mobile, setMobile] = useState()
  const [type_de_compte, setType_de_compte] = useState()
  const [date_ouverture, setDate_ouverture] = useState()
  const [registre__commerce, setRegistre__commerce] = useState(false)
  const [date_registre_commerce, setDate_registre_commerce] = useState()
  const [registre_commerce_moins_3_mois, setRegistre_commerce_moins_3_mois] = useState(false)
  const [copie_ifu, setCopie_ifu] = useState(false)
  const [piece_identite, setPiece_identite] = useState(false)
  const [copie_piece_identite, setCopie_piece_identite] = useState(false)
  const [deux_photo_identite, setDeux_photo_identite] = useState()
  const [facture_quitance_SBEE_SONEB, setFacture_quitance_SBEE_SONEB] = useState()
  const [date_facture_SBEE_SONEB, setDate_facture_SBEE_SONEB] = useState()
  const [fact_SBEE_SONEB_moins_trois_mois, setFact_SBEE_SONEB_moins_trois_mois] = useState()
  const [formulaire_EER, setFormulaire_EER] = useState()
  const [formulaire_KYC, setFormulaire_KYC] = useState()
  const [formulaire_BIC, setFormulaire_BIC] = useState()
  const [piece_identite_a_25_part, setPiece_identite_a_25_part] = useState()
  const [copie_status, setCopie_status] = useState()
  const [copie_reglement_interieur, setCopie_reglement_interieur] = useState()
  const [copie_acte_enregistrement, setCopie_acte_enregistrement] = useState()
  const [copie_publication_journal_officiel, setCopie_publication_journal_officie] = useState()
  const [copie_dernier_rapport, setCopie_dernier_rapport] = useState()
  const [articles41_42_43, setArticles41_42_43] = useState()
  const [cip_attestation_residence, setCip_attestation_residence] = useState()
  const [justificatif_activite, ] = useState()

  useEffect(()=>{
    if ( accessToken ) {
      console.log("Access token")
    }
  }, [accessToken])

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      num_compte: "",
      intitule_client: "",
      adresse_client: "",
      tel_fixe_client: "",
      mobile: "",
      type_de_compte: "",
      date_ouverture: "",
      registre__commerce: false,
      date_registre_commerce: "",
      registre_commerce_moins_3_mois: false,
      copie_ifu: false,
      piece_identite: false,
      copie_piece_identite: false,
      deux_photo_identite: false,
      facture_quitance_SBEE_SONEB: false,
      date_facture_SBEE_SONEB: "",
      fact_SBEE_SONEB_moins_trois_mois: false,
      formulaire_EER: false,
      formulaire_KYC: false,
      formulaire_BIC: false,
      piece_identite_a_25_part: false,
      copie_status: false,
      copie_reglement_interieur: false,
      copie_acte_enregistrement: false,
      copie_publication_journal_officiel: false,
      copie_dernier_rapport: false,
      articles41_42_43: false,
      cip_attestation_residence: false,
      justificatif_activite: false
    },
    validationSchema: Yup.object({
    //   nom: Yup.string().required("Cet champs est obligatoire"),
      num_compte: Yup.string().required("Cet champs est obligatoire"),
      intitule_client: Yup.string().required("Cet champs est obligatoire"),
      adresse_client: Yup.string().required("Cet champs est obligatoire"),
      tel_fixe_client: Yup.string().required("Cet champs est obligatoire"),
      mobile: Yup.string().required("Cet champs est obligatoire"),
      type_de_compte: Yup.string().required("Cet champs est obligatoire"),
      date_ouverture: Yup.string().required("Cet champs est obligatoire"),
      registre__commerce: Yup.string().required("Cet champs est obligatoire"),
      date_registre_commerce: Yup.string().required("Cet champs est obligatoire"),
      registre_commerce_moins_3_mois: Yup.string().required("Cet champs est obligatoire"),
      copie_ifu: Yup.string().required("Cet champs est obligatoire"),
      piece_identite: Yup.string().required("Cet champs est obligatoire"),
      copie_piece_identite: Yup.string().required("Cet champs est obligatoire"),
      deux_photo_identite: Yup.string().required("Cet champs est obligatoire"),
      facture_quitance_SBEE_SONEB: Yup.string().required("Cet champs est obligatoire"),
      date_facture_SBEE_SONEB: Yup.string().required("Cet champs est obligatoire"),
      fact_SBEE_SONEB_moins_trois_mois: Yup.string().required("Cet champs est obligatoire"),
      formulaire_EER: Yup.string().required("Cet champs est obligatoire"),
      formulaire_KYC: Yup.string().required("Cet champs est obligatoire"),
      formulaire_BIC: Yup.string().required("Cet champs est obligatoire"),
      piece_identite_a_25_part: Yup.string().required("Cet champs est obligatoire"),
      copie_status: Yup.string().required("Cet champs est obligatoire"),
      copie_reglement_interieur: Yup.string().required("Cet champs est obligatoire"),
      copie_acte_enregistrement: Yup.string().required("Cet champs est obligatoire"),
      copie_publication_journal_officiel: Yup.string().required("Cet champs est obligatoire"),
      copie_dernier_rapport: Yup.string().required("Cet champs est obligatoire"),
      articles41_42_43: Yup.string().required("Cet champs est obligatoire"),
      cip_attestation_residence: Yup.string().required("Cet champs est obligatoire"),
      justificatif_activite: Yup.string().required("Cet champs est obligatoire")
    }),
    onSubmit: (values) => {
      
    },
  });

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <div className="container-fluid">
          <BreadcrumbsAddUsers title="Comptes clients" breadcrumbItem="Ajouter comptes clients" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                          >
                            <span className="number">1.</span>{" "}
                            Compte Info
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 2 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                          >
                            <span className="number me-2">2.</span>{" "}
                            Documents
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 3 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                          >
                            <span className="number">3.</span>{" "}
                          Confirmer
                        </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <div className="body">
                        <TabContent
                          activeTab={activeTab}
                        >
                          <TabPane tabId={1}>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Numéro de compte
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                      placeholder="Numéro de compte"
                                      name="num_compte"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.num_compte}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Intitulé client
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-lastname-input2"
                                      placeholder="Intitulé client"
                                      name="intitule_client"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.intitule_client}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-phoneno-input3">
                                      Adresse client
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-phoneno-input3"
                                      placeholder="Adresse client"
                                      name="adresse_client"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.adresse_client}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-phoneno-input3">
                                      Téléphone fix
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-phoneno-input3"
                                      placeholder="Téléphone fix"
                                      name="tel_fixe_client"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.tel_fixe_client}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-phoneno-input3">
                                      Téléphone mobile
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-phoneno-input3"
                                      placeholder="Téléphone mobile"
                                      name="mobile"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.mobile}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-phoneno-input3">
                                      Date d'ouverture du compte
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-phoneno-input3"
                                      placeholder="Date d'ouverture"
                                      name="date_ouverture"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.date_ouverture}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <Form>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <Label for="basicpill-pancard-input5">
                                        Type de compte
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        id="basicpill-pancard-input5"
                                        placeholder="Type de compte"
                                        name="type_de_compte"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.type_de_compte}
                                      >
                                        <option value={''}>Selectionnez une option</option>
                                        <option value={1}>Particulier</option>
                                        <option value={2}>Etablissement</option>
                                        <option value={3}>Groupe d'entreprise</option>
                                        <option value={4}>ONG</option>
                                        <option value={5}>Société</option>
                                      </Input>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch3">Registre de commerce</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch3"
                                        switch="none"
                                        checked={validation.values.registre__commerce}
                                        name="registre__commerce"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.registre__commerce}
                                      />
                                      <Label
                                        htmlFor="square-switch3"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3">
                                      <Label for="basicpill-phoneno-input3">
                                        Date du registre de commerce
                                      </Label>
                                      <Input
                                        type="date"
                                        className="form-control"
                                        id="basicpill-phoneno-input3"
                                        placeholder="Date du registre de commerce"
                                        name="date_registre_commerce"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.date_registre_commerce}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch4">Registre de commerce (moins de 3 mois) ? </Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch4"
                                        switch="none"
                                        checked={validation.values.registre_commerce_moins_3_mois}
                                        name="registre_commerce_moins_3_mois"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.registre_commerce_moins_3_mois}
                                      />
                                      <Label
                                        htmlFor="square-switch4"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch5">Copie IFU </Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch5"
                                        switch="none"
                                        checked={validation.values.copie_ifu}
                                        name="copie_ifu"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.copie_ifu}
                                      />
                                      <Label
                                        htmlFor="square-switch5"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch6">Pièce d'identité </Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch6"
                                        switch="none"
                                        checked={validation.values.piece_identite}
                                        name="piece_identite"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.piece_identite}
                                      />
                                      <Label
                                        htmlFor="square-switch6"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch7">Copie pièce d'identité </Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch7"
                                        switch="none"
                                        checked={validation.values.copie_piece_identite}
                                        name="copie_piece_identite"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.copie_piece_identite}
                                      />
                                      <Label
                                        htmlFor="square-switch7"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch8">Deux photos d'identité </Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch8"
                                        switch="none"
                                        checked={validation.values.deux_photo_identite}
                                        name="deux_photo_identite"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.deux_photo_identite}
                                      />
                                      <Label
                                        htmlFor="square-switch8"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch9">Facture ou quittance SBEE / SONEB </Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch9"
                                        switch="none"
                                        checked={validation.values.facture_quitance_SBEE_SONEB}
                                        name="facture_quitance_SBEE_SONEB"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.facture_quitance_SBEE_SONEB}
                                      />
                                      <Label
                                        htmlFor="square-switch9"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                  <div className="mb-3">
                                      <Label for="basicpill-phoneno-input3">
                                        Date de la facture SBEE / SONEB
                                      </Label>
                                      <Input
                                        type="date"
                                        className="form-control"
                                        id="basicpill-phoneno-input3"
                                        placeholder="Date de la facture SBEE / SONEB"
                                        name="date_facture_SBEE_SONEB"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.date_facture_SBEE_SONEB}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch10">Facture SBEE / SONEB moins 03 mois</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch10"
                                        switch="none"
                                        checked={validation.values.fact_SBEE_SONEB_moins_trois_mois}
                                        name="fact_SBEE_SONEB_moins_trois_mois"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.fact_SBEE_SONEB_moins_trois_mois}
                                      />
                                      <Label
                                        htmlFor="square-switch10"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch11">formulaire EER</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch11"
                                        switch="none"
                                        checked={validation.values.formulaire_EER}
                                        name="formulaire_EER"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.formulaire_EER}
                                      />
                                      <Label
                                        htmlFor="square-switch11"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch12">Formulaire KYC</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch12"
                                        switch="none"
                                        checked={validation.values.formulaire_KYC}
                                        name="formulaire_KYC"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.formulaire_KYC}
                                      />
                                      <Label
                                        htmlFor="square-switch12"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch13">Formulaire BIC</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch13"
                                        switch="none"
                                        checked={validation.values.formulaire_BIC}
                                        name="formulaire_BIC"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.formulaire_BIC}
                                      />
                                      <Label
                                        htmlFor="square-switch13"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch14">Pièce d'identite actionnaire ayant au moins 25%</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch14"
                                        switch="none"
                                        checked={validation.values.piece_identite_a_25_part}
                                        name="piece_identite_a_25_part"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.piece_identite_a_25_part}
                                      />
                                      <Label
                                        htmlFor="square-switch14"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch15">Copie Statut</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch15"
                                        switch="none"
                                        checked={validation.values.copie_status}
                                        name="copie_status"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.copie_status}
                                      />
                                      <Label
                                        htmlFor="square-switch15"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch16">Copie règlement interieur</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch16"
                                        switch="none"
                                        checked={validation.values.copie_reglement_interieur}
                                        name="copie_reglement_interieur"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.copie_reglement_interieur}
                                      />
                                      <Label
                                        htmlFor="square-switch16"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch17">Copie acte enregistrement</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch17"
                                        switch="none"
                                        checked={validation.values.copie_acte_enregistrement}
                                        name="copie_acte_enregistrement"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.copie_acte_enregistrement}
                                      />
                                      <Label
                                        htmlFor="square-switch17"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch18">Copie publication journal officiel</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch18"
                                        switch="none"
                                        checked={validation.values.copie_publication_journal_officiel}
                                        name="copie_publication_journal_officiel"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.copie_publication_journal_officiel}
                                      />
                                      <Label
                                        htmlFor="square-switch18"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch19">Copie dernier rapport</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch19"
                                        switch="none"
                                        checked={validation.values.copie_dernier_rapport}
                                        name="copie_dernier_rapport"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.copie_dernier_rapport}
                                      />
                                      <Label
                                        htmlFor="square-switch19"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch20">Articles 41_42_43</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch20"
                                        switch="none"
                                        checked={validation.values.articles41_42_43}
                                        name="articles41_42_43"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.articles41_42_43}
                                      />
                                      <Label
                                        htmlFor="square-switch20"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch21">CIP / Attestation de residence</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch21"
                                        switch="none"
                                        checked={validation.values.cip_attestation_residence}
                                        name="cip_attestation_residence"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.cip_attestation_residence}
                                      />
                                      <Label
                                        htmlFor="square-switch21"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4">
                                    <div className="mb-3 square-switch d-flex justify-content-between">
                                      <Label htmlFor="square-switch22">Justificatif activite</Label>
                                      <Input
                                        type="checkbox"
                                        id="square-switch22"
                                        switch="none"
                                        checked={validation.values.justificatif_activite}
                                        name="justificatif_activite"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.justificatif_activite}
                                      />
                                      <Label
                                        htmlFor="square-switch22"
                                        data-on-label="Oui"
                                        data-off-label="Non"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div className="row justify-content-center">
                              <Col lg="6">
                                <div className="text-center">
                                  <div className="mb-4">
                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                  </div>
                                  <div>
                                    <h5>Confirm Detail</h5>
                                    <p className="text-muted">
                                      If several languages coalesce, the grammar of
                                      the resulting
                                </p>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 3 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={() => {
                              toggleTab(activeTab + 1)
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

CopyAjouterCompteClient.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default CopyAjouterCompteClient;
