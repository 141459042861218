import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  CardTitle,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledDropdown,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//import { Link } from "react-router-dom";

const Magasins = () => {
  document.title = "Minible | Magasins";

  const [nomMagasin, setNomMagasin] = useState("");
  const [detailsMagasin, setDetailsMagasin] = useState("");
  const [magasins, setMagasins] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedMagasin, setSelectedMagasin] = useState(null);

  const toggleForm = () => setShowForm(!showForm);

  const handleNomMagasinChange = (e) => {
    setNomMagasin(e.target.value);
  };

  const handleDetailsMagasinChange = (e) => {
    setDetailsMagasin(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nomMagasin.trim() !== "" && detailsMagasin.trim() !== "") {
      const newMagasin = {
        nom: nomMagasin,
        details: detailsMagasin,
        produits: [], // Initialise un tableau vide pour les produits du magasin
      };
      setMagasins([...magasins, newMagasin]);
      setNomMagasin("");
      setDetailsMagasin("");
      setShowForm(false);
    } else {
      alert("Veuillez remplir tous les champs !");
    }
  };

  const showDetails = (magasin) => {
    setSelectedMagasin(magasin);
  };


return (
  <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="Magasins" breadcrumbItem="Stocks" />


          {/* Bouton pour ajouter un magasin */}
          <div className="mt-4 mb-4 text-right">
            <Button color="primary" onClick={toggleForm}>
              Ajouter un magasin
            </Button>
          </div>

          {/* Liste des cartes de magasins */}
          <Row>
            {magasins.map((magasin, index) => (
              
              <Col xl={4} sm={6} key={index}>
              <Card className="text-center">
                <CardBody>
                  
                  <UncontrolledDropdown className="float-end">
                    <DropdownToggle tag="a" className="text-body font-size-16" caret>
                      <i className="uil uil-ellipsis-h"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem href="#">Edit</DropdownItem>
                      
                      <DropdownItem href="#">Remove</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div className="clearfix"></div>
      
                    <CardTitle>
                      {magasin.nom}
                    </CardTitle>
                  
                  <p className="text-muted mb-2">{magasin.details}</p>
                </CardBody>
                 {/* Soulignement avant le bouton "Profile" */}
                <hr className="my-2" />
                <div className="btn-group" role="group">
                  <Button type="button" color="btn-outline-light" className="text-truncate" onClick={() => showDetails(magasin)}> Voir les détails </Button>
                </div>
              </Card>
            </Col>           
            ))} 
           
          </Row>

          {/* Superposition du formulaire */}
          <Modal isOpen={showForm} toggle={toggleForm}>
            <ModalHeader toggle={toggleForm}>Ajouter une entrée</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="nomMagasin">Nom du magasin</Label>
                  <Input
                    type="text"
                    name="nomMagasin"
                    id="nomMagasin"
                    value={nomMagasin}
                    onChange={handleNomMagasinChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="detailsMagasin">Détails</Label>
                  <Input
                    type="textarea"
                    name="detailsMagasin"
                    id="detailsMagasin"
                    value={detailsMagasin}
                    onChange={handleDetailsMagasinChange}
                  />
                </FormGroup>
                <Button color="primary" type="submit">
                  Enregistrer
                </Button>{" "}
                <Button color="secondary" onClick={toggleForm}>
                  Annuler
                </Button>
              </Form>
            </ModalBody>
          </Modal>

          {/* Superposition des détails du magasin */}
          <Modal isOpen={selectedMagasin !== null} toggle={() => setSelectedMagasin(null)}>
            <ModalHeader toggle={() => setSelectedMagasin(null)}>
              Détails du magasin
            </ModalHeader>
            <ModalBody>
              {selectedMagasin && (
                <div>
                  <h4>{selectedMagasin.nom}</h4>
                  <p>{selectedMagasin.details}</p>
                  <Table>
                    <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Quantité</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedMagasin.produits.map((produit, index) => (
                        <tr key={index}>
                          <td>{produit.nom}</td>
                          <td>{produit.quantite}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Magasins;



              
