const Role = (cell) => {
    return cell.value ? cell.value : '';
};
const Prenom = (cell) => {
    return cell.value ? cell.value : '';
};

const Name = (cell) => {
    return cell.value ? cell.value : '';
};

const Email = (cell) => {
    return cell.value ? cell.value : '';
};


export {
    Role,
    Email,
    Name,
    Prenom

};