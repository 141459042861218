import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// Redux
import { Link } from "react-router-dom"
import withRouter from '../../components/Common/withRouter';

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { socialLogin } from "../../store/actions"
// import { loginUser,socialLogin } from "../../store/actions"


// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

import axios from 'axios'
import { key } from '../../constants/key';
import { ToastContainer, toast } from 'react-toastify'

const Login = (props) => {

  document.title=" Login | Obidon - International"

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Veuillez entre votre Email"),
      password: Yup.string().required("Veuillez entre votre Mot de passe"),
    }),
    onSubmit: (values) => {
      axios.post(
        `${key.apiBaseURL}/login`,
        values
      )
      .then((data)=>{
        console.log("Login data :: ", data)
        localStorage.setItem("authUser", JSON.stringify(data))
        props.router.navigate("/licence")
      })
      .catch((error)=>{
        toast.error("La connexion a échoué. Mot de passe ou email incorrecte")
        console.log("Erreur lors de la connexion :: ", error)
      })
    }
  });

  const selectLoginState = (state) => state.Login;
    const LoginProperties = createSelector(
      selectLoginState,
        (login) => ({
          error: login.error          
        })
    );

    const {
      error
  } = useSelector(LoginProperties);

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="account-pages my-3 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Content de vous revoir !</h5>
                    <p className="text-muted">Connectez-vous pour continuer sur <span style= {{ color :'black' }}>OBIDON ERP</span> <br/> L'utilisation de ce logiciel est réservée à <span style= {{ color :'black' }}>VERTS FLEURIS</span> </p>
                  </div> 
                  <div className="p-2 mt-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                       {error ? <Alert color="danger">{error}</Alert> : null}

                       <div className="mb-3">
                        <Label className="form-label"><strong>Email <span className="text-danger">*</span></strong></Label>
                        <Input
                          name="email"
                          className="form-control border-secondary"
                          placeholder="Entez votre e-mail"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                      <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">Mot de passe oublié ?</Link>
                        </div>
                        <Label className="form-label"> <strong>Password <span className="text-danger">*</span></strong></Label>
                        <Input
                          name="password"
                          className="form-control border-secondary"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Souviens-toi de moi
                        </label>
                      </div> */}

                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Se connecter
                        </button>
                      </div>
                    </Form>
                  </div>
                  <p className="text-muted mb-0 small text-center">Toutes utilisations frauduleuses est interdites et passible de poursuites judiciaires</p>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>© {new Date().getFullYear()} Obidon International<i className="mdi mdi-heart text-danger"></i></p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}