import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import { key } from '../../constants/key';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const PrixAchat = () => {
  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;
  const [productData, setProductData] = useState([]);
  const [listProduit, setListProduit] = useState([]);
  const [prixAchatData, setPrixAchatData] = useState([]);
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentPrixAchat, setCurrentPrixAchat] = useState({ produit: '', montant: '' });
  const [selectedPrixAchat, setSelectedPrixAchat] = useState(null);

  const toggleModal = () => setModal(!modal);
  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const getAllProduit = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/affichertoutproduit`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response) {
        console.log('Product response:', response.data);
        setProductData(response.data);
        setListProduit(response.data);
      }
    } catch (error) {
      console.log('Error fetching products:', error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllProduit();
    }
  }, [accessToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentPrixAchat({ ...currentPrixAchat, [name]: value });
  };

  const handleAddPrixAchat = () => {
    setCurrentPrixAchat({ produit: '', montant: '' });
    setEditMode(false);
    toggleModal();
  };

  const handleEditPrixAchat = (index) => {
    setCurrentPrixAchat(prixAchatData[index]);
    setEditMode(true);
    toggleModal();
  };

  const handleSavePrixAchat = () => {
    if (!currentPrixAchat.produit || !currentPrixAchat.montant) {
      toast.error('Tous les champs sont obligatoires');
      return;
    }

    if (editMode) {
      setPrixAchatData(prixAchatData.map((item, index) => (index === selectedPrixAchat ? currentPrixAchat : item)));
    } else {
      setPrixAchatData([...prixAchatData, currentPrixAchat]);
    }

    toggleModal();
  };

  const handleDeletePrixAchat = () => {
    setPrixAchatData(prixAchatData.filter((_, index) => index !== selectedPrixAchat));
    toggleConfirmModal();
  };

  return (
    <div className="container" style={{ marginTop: '90px', marginBottom: '20px' }}>
      <Button color="primary" onClick={handleAddPrixAchat} className="mb-3">
        Ajouter Prix d'achat
      </Button>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>Produit</th>
            <th>Montant</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {prixAchatData.map((prixAchat, index) => (
            <tr key={index}>
              <td>{prixAchat.produit}</td>
              <td>{prixAchat.montant}</td>
              <td>
              <div className="btn-group" role="group">
                  <Link to="#" className="text-truncate me-4" onClick={() => {
                    setSelectedPrixAchat(index);
                    handleEditPrixAchat(index);
                  }}>
                    <i className="uil uil-pen font-size-16" id="edittooltip" />
                  </Link>
                  <Link to="#" className="text-truncate me-4 text-danger"  onClick={() => {
                    setSelectedPrixAchat(index);
                    toggleConfirmModal();
                  }}>
                    <i className="uil uil-trash-alt font-size-16" id="deletetooltip" />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {editMode ? 'Modifier Prix d\'achat' : 'Ajouter Prix d\'achat'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="produit">
                Produit <span className="text-danger">*</span>
              </Label>
              <Input
                type="select"
                name="produit"
                id="produit"
                value={currentPrixAchat.produit}
                onChange={handleInputChange}
                className="border-secondary"
              >
                <option value="">Sélectionnez un produit</option>
                {listProduit.map((item, index) => (
                  <option value={item.libelle} key={index}>
                    {item.libelle}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="montant">
                Montant <span className="text-danger">*</span>
              </Label>
              <Input
                type="number"
                name="montant"
                id="montant"
                value={currentPrixAchat.montant}
                onChange={handleInputChange}
                className="border-secondary"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSavePrixAchat}>
            {editMode ? 'Enregistrer' : 'Ajouter'}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
        <ModalHeader toggle={toggleConfirmModal}>Confirmation de la suppression</ModalHeader>
        <ModalBody>Êtes-vous sûr de vouloir supprimer ce prix d'achat ?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeletePrixAchat}>
            Supprimer
          </Button>
          <Button color="secondary" onClick={toggleConfirmModal}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PrixAchat;
