import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  Form,
} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const NewFacture = () => {
  const navigate = useNavigate(); 
  const [clients] = useState([
    'Client 1', 'Client 2', 'Client 3', 'Client 4', 'Client 5', 
    'Client 6', 'Client 7', 'Client 8', 'Client 9', 'Client 10'
  ]);
  const [products] = useState([
    { name: 'Produit 1', price: 10 },
    { name: 'Produit 2', price: 20 },
    { name: 'Produit 3', price: 30 },
    { name: 'Produit 4', price: 40 },
    { name: 'Produit 5', price: 50 },
    { name: 'Produit 6', price: 60 },
    { name: 'Produit 7', price: 70 },
    { name: 'Produit 8', price: 80 },
    { name: 'Produit 9', price: 90 },
    { name: 'Produit 10', price: 100 }
  ]);
  const [paymentMethods] = useState(['Cash', 'Card', 'Transfer']);
  
  const [selectedClient, setSelectedClient] = useState('');
  const [productLines, setProductLines] = useState([
    { product: '', unitPrice: 0, quantity: 1, amount: 0 }
  ]);
  const [received, setReceived] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');

  useEffect(() => {
    const newProductLines = productLines.map(line => {
      const product = products.find(p => p.name === line.product);
      const unitPrice = product ? product.price : 0;
      return { ...line, unitPrice, amount: unitPrice * line.quantity };
    });
    setProductLines(newProductLines);
  }, [productLines.map(line => line.product), productLines.map(line => line.quantity)]);

  const handleClientChange = (e) => setSelectedClient(e.target.value);
  
  const handleProductChange = (index, value) => {
    const newProductLines = [...productLines];
    newProductLines[index].product = value;
    setProductLines(newProductLines);
  };

  const handleQuantityChange = (index, value) => {
    const newProductLines = [...productLines];
    newProductLines[index].quantity = Number(value);
    setProductLines(newProductLines);
  };

  const handleReceivedChange = (e) => setReceived(Number(e.target.value));
  const handlePaymentMethodChange = (e) => setPaymentMethod(e.target.value);

  const handleAddProductLine = () => {
    setProductLines([...productLines, { product: '', unitPrice: 0, quantity: 1, amount: 0 }]);
  };

  const handleRemoveProductLine = (index) => {
    const newProductLines = productLines.filter((_, i) => i !== index);
    setProductLines(newProductLines);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newFacture = {
      client: selectedClient,
      products: productLines,
      received,
      paymentMethod
    };
    console.log('New Facture:', newFacture);
    navigate('/factures'); 
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Facturation" breadcrumbItem="Nouvelle facture" />
        <Row>
          <Col lg={12}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="clientSelect">Sélectionner le client</Label>
                      <Input 
                        type="select" 
                        id="clientSelect" 
                        value={selectedClient} 
                        onChange={handleClientChange}
                      >
                        <option value="">-- Sélectionner le client --</option>
                        {clients.map(client => (
                          <option key={client} value={client}>{client}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                {productLines.map((line, index) => (
                  
                  <Row key={index}>
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`productSelect-${index}`}>Sélectionner le produit</Label>
                        <Input 
                          type="select" 
                          id={`productSelect-${index}`} 
                          value={line.product} 
                          onChange={(e) => handleProductChange(index, e.target.value)}
                        >
                          <option value="">-- Sélectionner le produit --</option>
                          {products.map(product => (
                            <option key={product.name} value={product.name}>{product.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`unitPrice-${index}`}>Prix unitaire</Label>
                        <Input 
                          type="number" 
                          id={`unitPrice-${index}`} 
                          value={line.unitPrice} 
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`quantity-${index}`}>Quantité</Label>
                        <Input 
                          type="number" 
                          id={`quantity-${index}`} 
                          value={line.quantity} 
                          onChange={(e) => handleQuantityChange(index, e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`amount-${index}`}>Montant</Label>
                        <Label 
                          type="number" 
                          id={`amount-${index}`} 
                          className="d-block bg-light text-dark p-2 rounded border"
                        >{line.amount}</Label>
                      </FormGroup>
                    </Col>
                    <Col md={2} className="align-items-center">
                      <Label >Suppression    </Label>
                      <Button color="success" onClick={() => handleRemoveProductLine(index)} className="align-self-end">Supprimer</Button>
                    </Col>
                  </Row>
                ))}
                <Button color="primary" onClick={handleAddProductLine} className='mt-3'>+ Ajouter un produit</Button>
                <Row className='mt-3'>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="received">Montant reçu</Label>
                      <Input 
                        type="number" 
                        id="received" 
                        value={received} 
                        onChange={handleReceivedChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="paymentMethod">Méthode de paiement</Label>
                      <Input 
                        type="select" 
                        id="paymentMethod" 
                        value={paymentMethod} 
                        onChange={handlePaymentMethodChange}
                      >
                        <option value="">-- Sélectionner la méthode de paiement --</option>
                        {paymentMethods.map(method => (
                          <option key={method} value={method}>{method}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button type="submit" color="primary" className='mt-2'>Enregistrer</Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewFacture;
