const factureList = [
    {
      id: 1,
      factureID: "#FF7842",
      date: "10 Jul, 2020",
      Amount:"141",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Connie Franco",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Jan, 2021",
      shippingID: "#SP0135",
      payType: "Espèce",
      orderId: "1123456",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260.00 XOF ",
            quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250.00 XOF ",
            quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 2,
      factureID: "#MN0130",
      date: "09 Jul, 2020",
      Amount:"153",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Paul Reynolds",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Mar, 2021",
      shippingID: "#SP0130",
      payType: "Chèque",
      orderId: "1123457",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 3,
      factureID: "#MN0129",
      date: "09 Jul, 2020",
      Amount:"220",
      color: "warning",
      status:"En attente",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Ronald Patterson",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "12 Apr, 2021",
      shippingID: "#SP0138",
      payType: "Espèce",
      orderId: "1123457",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 4,
      factureID: "#MN0128",
      date: " 08 Jul, 2020",
      Amount:"175",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Adella Perez",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Oct, 2020",
      shippingID: "#SP0134",
      payType: "Mobile Money",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 5,
      factureID: "#MN0127",
      date: "07 Jul, 2020",
      Amount:"160",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Theresa Mayers",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Mar, 2021",
      shippingID: "#SP0138",
      payType: "Chèque",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 6,
      factureID: "#MN0126",
      date: "06 Jul, 2020",
      Amount:"150",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Michael Wallace",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "07 Mar, 2020",
      shippingID: "#SP0124",
      payType: "Espèce",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 7,
      factureID: "#MN0125",
      date: "05 Jul, 2020",
      Amount:"165",
      color: "warning",
      status:"En attente",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Oliver Gonzales",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Mar, 2021",
      shippingID: "#SP0188",
      payType: "Chèque",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 8,
      factureID: "#MN0124",
      date: "05 Jul, 2020",
      Amount:"170",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"David Burke",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Mar, 2021",
      shippingID: "#SP0178",
      payType: "Espèce",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 9,
      factureID: "#MN0123",
      date: "04 Jul, 2020",
      Amount:"140",
      color: "warning",
      status:"En attente",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Willie Verner",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Mar, 2021",
      shippingID: "#SP0108",
      payType: "Mobile Money",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 10,
      factureID: "#MN0122",
      date: "03 Jul, 2020",
      Amount:"155",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Felix Perry",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "06 Aug, 2021",
      shippingID: "#SP0738",
      payType: "Chèque",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 11,
      factureID: "#MN0121",
      date: "02 Jul, 2020",
      Amount:"165",
      color: "success",
      status:"Payé",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Virgil Kelley",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "08 Aug, 2021",
      shippingID: "#SP0008",
      payType: "Moov Money",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF" ,
        total: "498.00 XOF ",
      },
    },
    {
      id: 12,
      factureID: "#MN0120",
      date: "02 Jul, 2020",
      Amount:"170",
      color: "success",
      status:"En attente",
      shippingAddress: "641 Counts Lane Wilmore, KY 40390",
      shippingEmail: "abc@123.com",
      shippingPhoneno:"012-345-6789",
      billingName:"Matthew Lawler",
      billingAddress: "4450 Fancher Drive Dallas, TX 75247",
      billingEmail:"PrestonMiller@armyspy.com",
      billingPhoneno:"001-234-5678",
      shippingDate: "17 May, 2021",
      shippingID: "#SP0458",
      payType: "Westerne Union",
      orderId: "1123458",
      orderSummary: {
        items: [
          {
            id: 1,
            item: "Nike N012 Running Shoes",
            price: "260 XOF",
             quantity: 1,
            color: "Gray",
            size: "08"
          },
          {
            id: 2,
            item: "Adidas Running Shoes",
            price: "250 XOF",
             quantity: 1,
            color: "Black",
            size: "09"
          },
        ],
        subTotal: "510.00 XOF ",
        discount: "50.00 XOF" ,
        shipping: "25.00 XOF" ,
        tax: "13.00 XOF",
        total: "498.00 XOF ",
      },
    },
  ];
  
  export { factureList }
  