import React from "react";
import "./MultiStepProgressBar.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";


const MultiStepProgressBar = (props) => {
  return (
    <ProgressBar
      percent={(props.step + 1) * 25}
      filledBackground="#664de5"
      height="2px"
      style={{ margin: "auto" }}
    >
      {[1, 2, 3, 4].map((_, index) => (
        <Step key={index} transition="scale">
          {({ accomplished }) => (
            <div
              style={{
                height: "15px",
                width: "15px",
                border: "1px solid lightgray",
                borderRadius: "50%",
                backgroundColor: `${accomplished ? "#664de5" : null}`
              }}
              className={`step ${accomplished ? "completed" : null}`}
            >
              {index + 1}
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

export default MultiStepProgressBar;