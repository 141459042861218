import React from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  Container
} from "reactstrap";
// import { Container, Row, Col, Card, CardBody, CardText, } from "reactstrap";

// import { Link } from "react-router-dom";

import { Link } from "react-router-dom";
// import SalesAnalyticsChart from "./salesanalytics-chart";
// import TopProduct from "./topselling-product";
// import TopUser from "./topuser";
// import RecentActivity from "./recent-activity";
// import SocialSource from "./socialsource";
// import LatestTransaction from "./latest-transaction";

//Import Image

const SousMenu = () => {

  document.title=" Accueil | Admin OBIDON"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* <Breadcrumbs title="Admin" breadcrumbItem="Tableau de bord "/> */}
          <h3>OBIDON STORE</h3>
          <span>Liste des modules disponibles selon votre licence</span>
          <div>
            {/* <Row>
                <MiniWidget reports={reports} />
            </Row>
            <Row>
                <MiniWidget reports={reports1} />
            </Row> */}
            <Row>
              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="primary" className="border-primary text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-user me-3"></i> Stock
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="success" className="border-success text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-check-circle me-3"></i> Facturation
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="info" className="border-info text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-question-circle me-3"></i>Salaire
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="warning" className="border-warning text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-triangle me-3"></i>Ressources Humaines 
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="danger" className="border-danger text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-octagon me-3"></i>Comptabilité ohada réviser
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="dark" className="border-dark text-white">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-arrow-circle-right me-3"></i>Comptabilité SYSBNL
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="primary" className="border-primary text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-user me-3"></i>Maintenance
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="success" className="border-success text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-check-circle me-3"></i>Caisse en temps en réelle
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="info" className="border-info text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-question-circle me-3"></i>Production d'assurance
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="warning" className="border-warning text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-triangle me-3"></i>GED : Gestion Electronique des document
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="danger" className="border-danger text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-octagon me-3"></i>Archivages
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="dark" className="border-dark text-white">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-arrow-circle-right me-3"></i>Hôtellerie
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="primary" className="border-primary text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-user me-3"></i>Bar/restaurant
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="success" className="border-success text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-check-circle me-3"></i>Messageries
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="info" className="border-info text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-question-circle me-3"></i>Gestion des tickets
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="warning" className="border-warning text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-triangle me-3"></i>Modules commercial 
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="danger" className="border-danger text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-octagon me-3"></i>Prospection clients
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="dark" className="border-dark text-white">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-arrow-circle-right me-3"></i>Relance clients
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="primary" className="border-primary text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-user me-3"></i>Encaissement des créances
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="success" className="border-success text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-check-circle me-3"></i>Points du chiffres d'affaire par commerciaux
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="info" className="border-info text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-question-circle me-3"></i>Top des ventes par commerciaux
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="warning" className="border-warning text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-triangle me-3"></i>Modules statistiques  
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="danger" className="border-danger text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-octagon me-3"></i>Analyse de vente
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="dark" className="border-dark text-white">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-arrow-circle-right me-3"></i>Vente par duo
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="primary" className="border-primary text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-user me-3"></i>% des clients achetants tel ou tel produits
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="success" className="border-success text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-check-circle me-3"></i>Proposition de produits aux clients
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="info" className="border-info text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-question-circle me-3"></i>Modules gestion hospitalière
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col lg={4} sm={6}>
                <Link to="/dashboard">
                  <Card color="warning" className="border-warning text-white-50">
                    <CardBody>
                      <h5 className="mt-0 mb-4 text-white">
                        <i className="uil uil-exclamation-triangle me-3"></i>Imagerie médicale
                      </h5>
                      <CardText>
                        Some quick example text to build on the card title and make
                        up the bulk of the card&apos;s content.
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
          </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SousMenu;
