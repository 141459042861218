import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerUser";
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import BreadcrumbsAddUsers from "../../components/Common/BreadcrumbUsers";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody
} from "reactstrap";
import DeleteModal from "../../components/Common/DeleteModalUsers";
import axios from "axios";
import { key } from "../../constants/key";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/ReactToastify.css'
import withRouter from "../../components/Common/withRouter";

function DetailCompteClient(props) {
    document.title = "Comptes Clients";

    const params = useParams('id')

    const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

    const [num_compte, setNum_compte] = useState()
    const [intitule_client, setIntitule_client] = useState()
    const [adresse_client, setAdresse_client] = useState()
    const [tel_fixe_client, setTel_fixe_client] = useState()
    const [mobile, setMobile] = useState()
    const [type_de_compte, setType_de_compte] = useState()
    const [date_ouverture, setDate_ouverture] = useState()
    const [registre__commerce, setRegistre__commerce] = useState(false)
    const [date_registre_commerce, setDate_registre_commerce] = useState()
    const [registre_commerce_moins_3_mois, setRegistre_commerce_moins_3_mois] = useState(false)
    const [copie_ifu, setCopie_ifu] = useState(false)
    const [piece_identite, setPiece_identite] = useState(false)
    const [copie_piece_identite, setCopie_piece_identite] = useState(false)
    const [deux_photo_identite, setDeux_photo_identite] = useState()
    const [facture_quitance_SBEE_SONEB, setFacture_quitance_SBEE_SONEB] = useState()
    const [date_facture_SBEE_SONEB, setDate_facture_SBEE_SONEB] = useState()
    const [fact_SBEE_SONEB_moins_trois_mois, setFact_SBEE_SONEB_moins_trois_mois] = useState()
    const [formulaire_EER, setFormulaire_EER] = useState()
    const [formulaire_KYC, setFormulaire_KYC] = useState()
    const [formulaire_BIC, setFormulaire_BIC] = useState()
    const [piece_identite_a_25_part, setPiece_identite_a_25_part] = useState()
    const [copie_status, setCopie_status] = useState()
    const [copie_reglement_interieur, setCopie_reglement_interieur] = useState()
    const [copie_acte_enregistrement, setCopie_acte_enregistrement] = useState()
    const [copie_publication_journal_officiel, setCopie_publication_journal_officie] = useState()
    const [copie_dernier_rapport, setCopie_dernier_rapport] = useState()
    const [articles41_42_43, setArticles41_42_43] = useState()
    const [cip_attestation_residence, setCip_attestation_residence] = useState()
    const [justificatif_activite, setJustificatif_activite] = useState()

    const [compte, setCompte] = useState()
    const getCompte = async()=>{
        try {
        const response = await axios.get(
            key.apiBaseURL + "/afficheruncompteclient",
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    id: params.id
                }
            }
        )
        if ( response ) {
            console.log("current compte :: ",response.data)
            setCompte(response.data)
        }
        } catch (error) {
        console.log("erreur :: ", error.response)
        }
    }
    useEffect(()=>{
        if ( accessToken ) {
            getCompte()
        }
    }, [accessToken])

    useEffect(()=>{
      if ( compte ) {
        setNum_compte(compte.num_compte)
        setIntitule_client(compte.intitule_client)
        setAdresse_client(compte.adresse_client)
        setTel_fixe_client(compte.tel_fixe_client)
        setMobile(compte.mobile)
        setType_de_compte(compte.type_de_compte)
        setDate_ouverture(compte.date_ouverture)
        setRegistre__commerce(compte.registre__commerce)
        setDate_registre_commerce(compte.date_registre_commerce)
        setRegistre_commerce_moins_3_mois(compte.registre_commerce_moins_3_mois)
        setCopie_ifu(compte.copie_ifu)
        setPiece_identite(compte.piece_identite)
        setCopie_piece_identite(compte.copie_piece_identite)
        setDeux_photo_identite(compte.deux_photo_identite)
        setFacture_quitance_SBEE_SONEB(compte.facture_quitance_SBEE_SONEB)
        setDate_facture_SBEE_SONEB(compte.date_facture_SBEE_SONEB)
        setFact_SBEE_SONEB_moins_trois_mois(compte.fact_SBEE_SONEB_moins_trois_mois)
        setFormulaire_EER(compte.formulaire_EER)
        setFormulaire_KYC(compte.formulaire_KYC)
        setFormulaire_BIC(compte.formulaire_BIC)
        setPiece_identite_a_25_part(compte.piece_identite_a_25_part)
        setCopie_status(compte.copie_status)
        setCopie_reglement_interieur(compte.copie_reglement_interieur)
        setCopie_acte_enregistrement(compte.copie_acte_enregistrement)
        setCopie_publication_journal_officie(compte.copie_publication_journal_officiel)
        setCopie_dernier_rapport(compte.copie_dernier_rapport)
        setArticles41_42_43(compte.articles41_42_43)
        setCip_attestation_residence(compte.cip_attestation_residence)
        setJustificatif_activite(compte.justificatif_activite)
      }
    }, [compte])

    const type_compte = [
        {
          id: 1,
          label: "PARTICULIER"
        },
        {
          id: 2,
          label: "ETABLISSEMENT"
        },
        {
          id: 3,
          label: "GROUPE ENTREPRISE"
        },
        {
          id: 4,
          label: "ONG/ASSOCIATION"
        },
        {
          id: 5,
          label: "SOCIETE"
        }
    ]

    const piece_dossier = [
        {
          label: "Registre de commerce",
          value: registre__commerce,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                : false
        },
        {
          label: "Date registre de commerce",
          value: date_registre_commerce,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                : false
        },
        {
          label: "Registre de commerce datant de moins de 03 mois",
          value: registre_commerce_moins_3_mois,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                : false
        },
        {
          label: "Copie IFU",
          value: copie_ifu,
          show: true
        },
        {
          label: "Pièce d'identité",
          value: piece_identite,
          show: true
        },
        {
          label: "Copie de la pièce d'identité",
          value: copie_piece_identite,
          show: true
        },
        {
          label: "Deux photo d'identité",
          value: deux_photo_identite,
          show: true
        },
        {
          label: "Facture ou quittance SBEE/SONEB",
          value: facture_quitance_SBEE_SONEB,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Date de la facture SBEE/SONEB",
          value: date_facture_SBEE_SONEB,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Facture SBEE/SONEB datant de moins de 03 mois",
          value: fact_SBEE_SONEB_moins_trois_mois,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ETABLISSEMENT" || 
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Formulaire EER",
          value: formulaire_EER,
          show: true
        },
        {
          label: "Formulaire KYC",
          value: formulaire_KYC,
          show: true
        },
        {
          label: "Formulaire BIC",
          value: formulaire_BIC,
          show: true
        },
        {
          label: "Pièce d'identité des actionnaire ayant 25% de part",
          value: piece_identite_a_25_part,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Copie des statuts",
          value: copie_status,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "SOCIETE" ||
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "GROUPE ENTREPRISE"
                : false
        },
        {
          label: "Copie du règlement intérieur",
          value: copie_reglement_interieur,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Copie de l'acte d'enregistrement",
          value: copie_acte_enregistrement,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Copie de la publication du journal officiel",
          value: copie_publication_journal_officiel,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Copie du dernier rapport",
          value: copie_dernier_rapport,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "Articles 41, 42, 43",
          value: articles41_42_43,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "ONG/ASSOCIATION"
                : false
        },
        {
          label: "CIP/Attestation de résidence",
          value: cip_attestation_residence,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
                : false
        },
        {
          label: "Justificatif de l'activité",
          value: justificatif_activite,
          show: type_de_compte ?
                type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label === "PARTICULIER"
                : false
        },
    ]

    //Print the Invoice
    const imprimer = () => {
        window.print();
    };

    //   const columns = useMemo(
    //     () => [
    //       {
    //         Header: "N°",
    //         accessor: "num_compte",
    //         filterable: true,
    //         Cell: (cellProps) => (
    //           <span>{ cellProps.row.original.num_compte }</span>
    //         ),
    //       },
    //       {
    //         Header: "Intitulé Client",
    //         accessor: "intitule_client",
    //         filterable: true,
    //         Cell: (cellProps) => (
    //             <span>{ cellProps.row.original.intitule_client }</span>
    //         ),
    //       },
    //       {
    //         Header: "Adresse client",
    //         accessor: "adresse_client",
    //         filterable: true,
    //         Cell: (cellProps) => (
    //             <span>{ cellProps.row.original.adresse_client }</span>
    //         ),
    //       },
    //       {
    //         Header: "Téléphone Fix",
    //         accessor: "tel_fixe_client",
    //         filterable: true,
    //         Cell: (cellProps) => (
    //             <span>{ cellProps.row.original.tel_fixe_client }</span>
    //         ),
    //       },
        
    //       {
    //         Header: "Téléphone Mobile",
    //         accessor: "mobile",
    //         filterable: true,
    //         Cell: (cellProps) => (
    //             <span>{ cellProps.row.original.mobile }</span>
    //         ),
    //       },
        
    //       {
    //         Header: "Type de compte",
    //         accessor: "type_de_compte",
    //         filterable: true,
    //         Cell: (cellProps) => (
    //             <span>{ cellProps.row.original.type_de_compte }</span>
    //         ),
    //       },
        
    //       {
    //         Header: "Date d'ouverture",
    //         accessor: "date_ouverture",
    //         filterable: true,
    //         Cell: (cellProps) => (
    //             <span>{ cellProps.row.original.date_ouverture ? cellProps.row.original.date_ouverture.split("T")[0] : null }</span>
    //         ),
    //       },
    //       {
    //         Header: "Action",
    //         accessor: "action",
    //         disableFilters: true,
    //         Cell: (cellProps) => (
    //           <div className="d-flex gap-3 users">
    //             <ul className="list-inline font-size-20 contact-links mb-0">
    //               <li className="list-inline-item">
    //                 <Link
    //                   to="#"
    //                   className="text-primary"
    //                   onClick={() => {
    //                     const user = cellProps.row.original;
    //                   }}
    //                 >
    //                   <i className="uil uil-pen font-size-18" id="edittooltip" />
    //                   <UncontrolledTooltip placement="top" target="edittooltip">
    //                     Éditer
    //                   </UncontrolledTooltip>
    //                 </Link>
    //               </li>
    //               <li className="list-inline-item dropdown">
    //                 <Link to="#" className="text-truncate text-success">
    //                   <i className="uil uil-eye font-size-16" id="viewtooltip" />
    //                   <UncontrolledTooltip placement="top" target="viewtooltip">
    //                     Vue
    //                   </UncontrolledTooltip>
    //                 </Link>
    //               </li>
    //             </ul>
    //           </div>
    //         ),
    //       },
    //     ],
    //     []
    //   );

    return (
        <React.Fragment>
        <ToastContainer closeButton={false} />
        <div className="page-content">
            <div className="container-fluid">
            <BreadcrumbsAddUsers title="Comptes clients" breadcrumbItem="Détails compte client" />
            <Row>
                <Col>
                <div className="mb-3">
                    <Link to="#" onClick={imprimer} className="btn btn-success waves-effect waves-light ms-auto">
                        Imprimer
                    </Link>
                </div>
                </Col>

                <Col xs="12">
                <Card>
                    <CardBody>
                        <div className="m-3">
                          <div className="row">
                            <div className="col-6">
                                <span>Type de compte client</span>
                            </div>
                            <div className="col-6">
                                <span>{ type_de_compte ? type_compte.filter(el => el.id === parseInt(type_de_compte))[0].label : "" }</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                                <span>Numéro compte</span>
                            </div>
                            <div className="col-6">
                                <span>{ num_compte }</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                                <span>Intitulé client</span>
                            </div>
                            <div className="col-6">
                                <span>{ intitule_client }</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                                <span>Adresse client</span>
                            </div>
                            <div className="col-6">
                                <span>{ adresse_client }</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                                <span>Téléphone fix client</span>
                            </div>
                            <div className="col-6">
                                <span>{ tel_fixe_client }</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                                <span>Téléphone mobile</span>
                            </div>
                            <div className="col-6">
                                <span>{ mobile }</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                                <span>Date d'ouverture du compte</span>
                            </div>
                            <div className="col-6">
                                <span>{ date_ouverture && date_ouverture.split("T")[0] }</span>
                            </div>
                          </div>
                        </div>
                        <div className="m-3">
                          <div className="row border">
                            <div className="col-4 border"><span>Pièces du dossier</span></div>
                            <div className="col-4 border"><span>Valeurs</span></div>
                            <div className="col-4 border"><span>Observations</span></div>
                          </div>
                            {
                              piece_dossier.map((item, index) => (
                                item.show === true
                                ?
                                <div className="row border" key={index}>
                                    <div className="col-4 border"><span>{item.label}</span></div>
                                    <div className="col-4 border"><span>{item.value === false || item.value == null ? "NON" : "OUI"}</span></div>
                                    <div className="col-4 border"><span>{!item.value ? "Non à jour" : "A jour"}</span></div>
                                </div>
                                :
                                null
                              ))
                            }
                        </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            </div>
        </div>
        </React.Fragment>
    );
}

DetailCompteClient.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withRouter(DetailCompteClient);
