import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import axios from "axios";
import { key } from "../../constants/key";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";

const StocksSupply = () => {
  document.title = "Minible | Approvisionnement";

  const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;

  const [formPage, setFormPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [newAppro, setNewAppro] = useState({
    num_approvisionnement: "",
    donnees: [
      {
        id_produit: "",
        id_contact: "",
        id_magasin: "",
        quantite: "",
        qte_alerte: "",
        qte_securite: "",
        date_peremption: "",
        montant: "",
      }
    ],
    montant_global: 0,
  });


  const [productList, setProductList] = useState([]);
  const [magasinList, setMagasinList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [montantsList, setMontantsList] = useState([]);
  const [entriesData, setEntriesData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getAllProduit = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/affichertoutproduit`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("produit list :: ", response.data)
        setProductList(response.data);
      }
    } catch (error) {
      console.log("Error fetching id_produits:", error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllProduit();
    }
  }, [accessToken]);

  const getAllMagazin = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/voirtoutmagasin`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("magasin list :: ", response.data)
        setMagasinList(response.data);
      }
    } catch (error) {
      console.log("Error fetching id_produits:", error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllMagazin();
    }
  }, [accessToken]);

  const getAllContacts = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/getallcontact`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("contact list :: ", response.data)
        setContactList(response.data);
      }
    } catch (error) {
      console.log("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllContacts();
    }
  }, [accessToken]);

  const getAllmontants = async () => {
    try {
      const response = await axios.get(
        `${key.apiBaseURL}/voirtoutprixachat`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("montant list :: ", response.data)
        setMontantsList(response.data);
      }
    } catch (error) {
      console.log("Error fetching unit prices:", error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllmontants();
    }
  }, [accessToken]);

  // const handleInputChange = (e, index = null) => {
  //   const { name, value } = e.target;

  //   if (index !== null) {
  //     const updatedEntries = newAppro.donnees.map((entry, i) =>
  //       i === index ? { ...entry, [name]: value } : entry
  //     );
  //     setNewAppro({ ...newAppro, donnees: updatedEntries });
  //   } else {
  //     setNewAppro({ ...newAppro, [name]: value });
  //   }
  // };
  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;

    if (index !== null) {
      const updatedEntries = newAppro.donnees.map((entry, i) =>
        i === index ? { ...entry, [name]: value || "" } : entry
      );
      setNewAppro({ ...newAppro, donnees: updatedEntries });
    } else {
      setNewAppro({ ...newAppro, [name]: value || "" });
    }
  };


  const addEntry = () => {
    setNewAppro({
      ...newAppro,
      num_approvisionnement: "",
      donnees: [
        ...newAppro.donnees,
        {
          id_produit: "",
          id_contact: "",
          quantite: "",
          id_magasin: "",
          qte_alerte: "",
          qte_securite: "",
          date_peremption: "",
          montant: "",
        },
      ],
      montant_global: 0,
    });
  };

  const removeEntry = (index) => {
    const newEntries = newAppro.donnees.filter((_, i) => i !== index);
    setNewAppro({ ...newAppro, donnees: newEntries });
  };

  // Helper function to calculate the global amount

  const calculateGlobalAmount = () => {
    return newAppro.donnees.reduce((sum, entry) => sum + parseFloat(entry.montant || 0), 0);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!newAppro.donnees || newAppro.donnees.length === 0) newErrors.donnees = "Veuillez ajouter au moins une entrée.";
    else {
      newAppro.donnees.forEach((entry, index) => {
        if (!entry.id_produit.trim()) newErrors[`id_produit_${index}`] = "Veuillez sélectionner un produit.";
        if (!entry.id_contact.trim()) newErrors[`id_contact_${index}`] = "Veuillez sélectionner un fournisseur.";
        if (!entry.id_magasin.trim()) newErrors[`id_magasin_${index}`] = "Veuillez sélectionner un magasin.";
        if (!entry.qte_alerte.trim()) newErrors[`qte_alerte_${index}`] = "Veuillez remplir ce champ.";
        if (!entry.quantite.trim()) newErrors[`quantite_${index}`] = "Veuillez remplir ce champ.";
        if (!entry.qte_securite.trim()) newErrors[`qte_securite_${index}`] = "Veuillez remplir ce champ.";
        if (!entry.date_peremption.trim()) newErrors[`date_peremption_${index}`] = "Veuillez remplir ce champ.";
        if (!entry.montant.trim()) newErrors[`montant_${index}`] = "Veuillez sélectionner un prix.";
      });
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    try {
      const payload = {
        num_approvisionnement: newAppro.num_approvisionnement || "",
        montant_global: calculateGlobalAmount(),
        donnees: (newAppro.donnees || []).map((entry) => ({
          id_produit: entry.id_produit !== undefined && entry.id_produit !== "" ? entry.id_produit : null,
          id_magasin: entry.id_magasin !== undefined && entry.id_magasin !== "" ? entry.id_magasin : null,
          montant: entry.montant !== undefined && entry.montant !== "" ? entry.montant : null,
          quantite: entry.quantite !== undefined && entry.quantite !== "" ? entry.quantite : null,
          id_contact: entry.id_contact !== undefined && entry.id_contact !== "" ? entry.id_contact : null,
          qte_alerte: entry.qte_alerte !== undefined && entry.qte_alerte !== "" ? entry.qte_alerte : null,
          date_peremption: entry.date_peremption !== undefined && entry.date_peremption !== "" ? entry.date_peremption : null,
          qte_securite: entry.qte_securite !== undefined && entry.qte_securite !== "" ? entry.qte_securite : null,
        })),
      };
  
      const formData = new FormData();
      formData.append("num_approvisionnement", payload.num_approvisionnement);
      formData.append("montant_global", payload.montant_global);
      formData.append("donnees", JSON.stringify(payload.donnees));
  
      console.log('FormData:', Array.from(formData.entries()));
  
      const response = await axios.post(`${key.apiBaseURL}/entrerunproduit`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 201) {
        console.log(response.data);
        toast.success("Produit ajouté avec succès");
        setEntriesData([response.data, ...entriesData]);
      } else {
        toast.error("Erreur, veuillez réessayer");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit", error.response || error);
      toast.error("Erreur, veuillez réessayer");
    }
  
    setNewAppro({
      num_approvisionnement: "",
      donnees: [
        {
          id_produit: "",
          id_contact: "",
          id_magasin: "",
          quantite: "",
          qte_alerte: "",
          qte_securite: "",
          date_peremption: "",
          montant: "",
        },
      ],
      montant_global: 0,
    });
    setFormValid(true);
    toggleForm();
  };
  
  
  




  const toggleForm = () => setShowForm(!showForm);

  const toggleDetailsModal = () => setShowDetailsModal(!showDetailsModal);

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleEditEntry = (index) => {
    setSelectedEntry(entriesData[index]);
    setShowForm(true);
  };

  const handleDeleteEntry = (index) => {
    setSelectedEntry(entriesData[index]);
    toggleDeleteModal();
  };

  const confirmDeleteEntry = () => {
    setEntriesData(entriesData.filter((entry) => entry !== selectedEntry));
    setSelectedEntry(null);
    toggleDeleteModal();
  };

  const handleViewDetails = (index) => {
    setSelectedEntry(entriesData[index]);
    toggleDetailsModal();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Approvisionnements" breadcrumbItem="Stocks" />
          <Button color="primary" onClick={toggleForm}>
            Ajouter une entrée
          </Button>
          <Table bordered className="mt-3">
            <thead>
              <tr>
                <th>Produit</th>
                <th>Fournisseur</th>
                <th>Quantité</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {entriesData.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.libelle}</td>
                  <td>{entry.nom}</td>
                  <td>{entry.quantite}</td>
                  <td>
                    <div className="btn-group" role="group">
                      <Link
                        to="#"
                        className="text-truncate me-4"
                        onClick={() => handleEditEntry(index)}
                      >
                        <i className="uil uil-pen font-size-16" id="edittooltip" />
                      </Link>
                      <Link
                        to="#"
                        className="text-truncate me-4 text-danger"
                        onClick={() => handleDeleteEntry(index)}
                      >
                        <i className="uil uil-trash-alt font-size-16" id="deletetooltip" />
                      </Link>
                      <Link
                        to="#"
                        className="text-truncate text-success"
                        onClick={() => handleViewDetails(index)}
                      >
                        <i className="uil uil-eye font-size-16" id="viewtooltip" />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal isOpen={showForm} toggle={toggleForm}>
            <ModalHeader toggle={toggleForm}>Ajouter une entrée</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                {formPage === 1 && (
                  <div>
                    {newAppro.donnees.map((entry, index) => (
                      <div key={index} className="border p-3 mb-3">
                        <Row form>
                          <Col md={6}>
                            <FormGroup className="border-secondary">
                              <Label for={`entryproduit${index}`}>Produit</Label>
                              <Input
                                type="select"
                                name="id_produit"
                                id={`entryproduit${index}`}
                                value={entry.id_produit}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border-secondary"
                              >
                                <option value="" disabled hidden>Sélectionner un produit</option>
                                {productList.map((product, idx) => (
                                  <option key={idx} value={product.id_produit}>{product.libelle}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="border-secondary">
                              <Label for={`entrycontact${index}`}>Contact</Label>
                              <Input
                                type="select"
                                name="id_contact"
                                id={`entrycontact${index}`}
                                value={entry.id_contact}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border-secondary"
                              >
                                <option value="" disabled hidden>Sélectionner un contact</option>
                                {contactList.map((contact, idx) => (
                                  <option key={idx} value={contact.id}>{contact.nom}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <FormGroup className="border-secondary">
                              <Label for={`entrymagasin${index}`}>Magasin</Label>
                              <Input
                                type="select"
                                name="id_magasin"
                                id={`entrymagasin${index}`}
                                value={entry.id_magasin}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border-secondary"
                              >
                                <option value="" disabled hidden>Sélectionner un magasin</option>
                                {magasinList.map((magasin, idx) => (
                                  <option key={idx} value={magasin.id}>{magasin.nom_du_magasin}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="border-secondary">
                              <Label for={`entryqte_alerte${index}`}>
                                Quantité d'alerte
                              </Label>
                              <Input
                                type="number"
                                name="qte_alerte"
                                id={`entryqte_alerte${index}`}
                                value={entry.qte_alerte}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border-secondary"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="border-secondary">
                              <Label for={`quantite${index}`}>
                                Quantité
                              </Label>
                              <Input
                                type="number"
                                name="quantite"
                                id={`quantite${index}`}
                                value={entry.quantite}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border-secondary"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <FormGroup className="border-secondary">
                              <Label for={`entryqte_securite${index}`}>
                                Quantité de sécurité
                              </Label>
                              <Input
                                type="number"
                                name="qte_securite"
                                id={`entryqte_securite${index}`}
                                value={entry.qte_securite}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border-secondary"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="border-secondary">
                              <Label for={`entrydate_peremption${index}`}>
                                Date de péremption
                              </Label>
                              <Input
                                type="date"
                                name="date_peremption"
                                id={`entrydate_peremption${index}`}
                                value={entry.date_peremption}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border-secondary"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup className="border-secondary">
                          <Label for={`entrymontant${index}`}>
                            Prix unitaire
                          </Label>
                          <Input
                            type="select"
                            name="montant"
                            id={`entrymontant${index}`}
                            value={entry.montant}
                            onChange={(e) => handleInputChange(e, index)}
                            className="border-secondary"
                          >
                            <option value="" disabled hidden>
                              Sélectionner un prix
                            </option>
                            {montantsList.map((price, idx) => (
                              <option key={idx} value={price.montant}>
                                {price.montant}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        <div className="d-flex justify-content-between">
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => removeEntry(index)}
                          >
                            Supprimer
                          </Button>
                        </div>
                      </div>
                    ))}
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={addEntry}
                      className="mt-3 mb-2"
                    >
                      Ajouter une autre entrée
                    </Button>
                    <div className="mt-3 d-flex justify-content-between">
                      <Button color="secondary" size="sm" onClick={toggleForm}>
                        Annuler
                      </Button>
                      <Button color="primary" size="sm" onClick={() => setFormPage(2)}>
                        Suivant
                      </Button>
                    </div>
                  </div>
                )}

                {formPage === 2 && (
                  <div>
                    <Card>
                      <CardBody>
                        <CardTitle>Résumé des informations</CardTitle>
                        <ul>
                          {newAppro.donnees.map((entry, index) => (
                            <li key={index}>
                              <strong>Entrée {index + 1}:</strong>
                              <ul>
                                <li>Produit: {productList.find(product => product.id_produit === entry.id_produit)?.libelle || 'N/A'}</li>
                                <li>Fournisseur: {contactList.find(contact => contact.id === entry.id_contact)?.nom || 'N/A'}</li>
                                <li>Magasin: {magasinList.find(magasin => magasin.id === entry.id_magasin)?.nom_du_magasin || 'N/A'}</li>
                                <li>Quantité d'alerte: {entry.qte_alerte}</li>
                                <li>Quantité: {entry.quantite}</li>
                                <li>Quantité de sécurité: {entry.qte_securite}</li>
                                <li>Date de péremption: {entry.date_peremption}</li>
                                <li>Prix unitaire: {entry.montant}</li>
                              </ul>
                            </li>
                          ))}
                        </ul>
                        <h5>Montant global: {calculateGlobalAmount()}</h5>
                      </CardBody>
                    </Card>
                    <div className="mt-3 d-flex justify-content-between">
                      <Button color="secondary" size="sm" onClick={() => setFormPage(1)}>
                        Précédent
                      </Button>
                      <Button color="primary" size="sm" type="submit">
                        Soumettre
                      </Button>
                    </div>
                  </div>
                )}

              </Form>
            </ModalBody>
          </Modal>
          {selectedEntry && (
            <Modal isOpen={showDetailsModal} toggle={toggleDetailsModal}>
              <ModalHeader toggle={toggleDetailsModal}>
                Détails de l'entrée
              </ModalHeader>
              <ModalBody>
                <ul>
                  {selectedEntry.donnees.map((entry, index) => (
                    <li key={index}>
                      <strong>Entrée {index + 1}:</strong>
                      <ul>
                        <li>Produit: {productList.find(product => product.id_produit === entry.id_produit)?.libelle}</li>
                        <li>Fournisseur: {contactList.find(contact => contact.id_contact === entry.id_contact)?.nom}</li>
                        <li>Magasin: {magasinList.find(magasin => magasin.id_magasin === entry.id_magasin)?.nom_du_magasin}</li>
                        <li>Quantité d'alerte: {entry.qte_alerte}</li>
                        <li>Quantité: {entry.quantite}</li>
                        <li>Quantité de sécurité: {entry.qte_securite}</li>
                        <li>Date de péremption: {entry.date_peremption}</li>
                        <li>Prix unitaire: {entry.montant}</li>
                      </ul>
                    </li>
                  ))}
                </ul>
                <h5>Montant global: {calculateGlobalAmount()}</h5>
              </ModalBody>
            </Modal>
          )}
          {selectedEntry && (
            <Modal isOpen={showDeleteModal} toggle={toggleDeleteModal}>
              <ModalHeader toggle={toggleDeleteModal}>
                Confirmation de suppression
              </ModalHeader>
              <ModalBody>
                <p>Êtes-vous sûr de vouloir supprimer cette entrée?</p>
                <div className="d-flex justify-content-end">
                  <Button color="secondary" className="me-2" onClick={toggleDeleteModal}>
                    Annuler
                  </Button>
                  <Button color="danger" onClick={confirmDeleteEntry}>
                    Supprimer
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StocksSupply;
