import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// Redux
import { Link } from "react-router-dom"
import withRouter from '../../components/Common/withRouter';

import { useSelector } from "react-redux";
import { createSelector } from "reselect";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

import axios from 'axios'
import { key } from '../../constants/key';

const Licence = (props) => {

  document.title=" Licence | Obidon - International"

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      nom: "something",
      licence: "something",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("Veuillez entre le nom"),
      licence: Yup.string().required("Veuillez entre votre clé de licence"),
    }),
    onSubmit: (values) => {
      // alert("Lience")
      props.router.navigate("/categorie-menu")
    }
  });

  const selectLoginState = (state) => state.Login;
    const LoginProperties = createSelector(
      selectLoginState,
        (login) => ({
          error: login.error          
        })
    );

    const {
      error
  } = useSelector(LoginProperties);


  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-3 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Enregistrez maintenant votre entreprise et votre clé de licence !</h5>
                    {/* <p className="text-muted">Connectez-vous pour continuer sur <span style= {{ color :'black' }}>OBIDON ERP</span> <br/> L'utilisation de ce logiciel est réservée à <span style= {{ color :'black' }}>VERTS FLEURIS</span> </p> */}
                  </div> 
                  <div className="p-2 mt-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                       {error ? <Alert color="danger">{error}</Alert> : null}

                       <div className="mb-3">
                        <Label className="form-label"><strong>Nom de l'entité <span className="text-danger">*</span></strong></Label>
                        <Input
                          name="nom"
                          className="form-control border-secondary"
                          placeholder="Entez votre nom"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.nom || ""}
                          invalid={
                            validation.touched.nom && validation.errors.nom ? true : false
                          }
                        />
                        {validation.touched.nom && validation.errors.nom ? (
                          <FormFeedback type="invalid">{validation.errors.nom}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label"> <strong>Clé de licence <span className="text-danger">*</span></strong></Label>
                        <Input
                          name="licence"
                          className="form-control border-secondary"
                          value={validation.values.licence || ""}
                          type="text"
                          placeholder="Entez la clé de licence"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.licence && validation.errors.licence ? true : false
                          }
                        />
                        {validation.touched.licence && validation.errors.licence ? (
                          <FormFeedback type="invalid">{validation.errors.licence}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Valider
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>© {new Date().getFullYear()} Obidon International<i className="mdi mdi-heart text-danger"></i></p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Licence);

Licence.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}