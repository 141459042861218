import {
    GET_BONS,
    GET_BONS_FAIL,
    GET_BONS_SUCCESS,
    GET_BON_DETAIL,
    GET_BON_DETAIL_FAIL,
    GET_BON_DETAIL_SUCCESS,
    ADD_NEW_BON,
    ADD_BON_SUCCESS,
    ADD_BON_FAIL,
    UPDATE_BON,
    UPDATE_BON_SUCCESS,
    UPDATE_BON_FAIL,
    DELETE_BON,
    DELETE_BON_SUCCESS,
    DELETE_BON_FAIL,
  } from "./actionTypes"
  
  export const getBons = () => ({
    type: GET_BONS,
  })
  
  export const getBonsSuccess = bons => ({
    type: GET_BONS_SUCCESS,
    payload: bons,
  })
  
  export const getBonsFail = error => ({
    type: GET_BONS_FAIL,
    payload: error,
  })
  
  export const addNewBon = bon => ({
    type: ADD_NEW_BON,
    payload: bon,
  })
  
  export const addBonSuccess = bon => ({
    type: ADD_BON_SUCCESS,
    payload: bon,
  })  
  
  export const addBonFail = error => ({
    type: ADD_BON_FAIL,
    payload: error,
  })
  
  export const updateBon = bon => ({
    type: UPDATE_BON,
    payload: bon,
  })
  
  export const updateBonSuccess = bon => ({
    type: UPDATE_BON_SUCCESS,
    payload: bon,
  })
  
  export const updateBonFail = error => ({
    type: UPDATE_BON_FAIL,
    payload: error,
  })
  
  export const deleteBon = bon => ({
    type: DELETE_BON,
    payload: bon,
  })
  
  export const deleteBonSuccess = bon => ({
    type: DELETE_BON_SUCCESS,
    payload: bon,
  })
  
  export const deleteBonFail = error => ({
    type: DELETE_BON_FAIL,
    payload: error,
  })
  
  export const getBonDetail = bonId => ({
    type: GET_BON_DETAIL,
    bonId,
  })
  
  export const getBonDetailSuccess = bon => ({
    type: GET_BON_DETAIL_SUCCESS,
    payload: bon,
  })
  
  export const getBonDetailFail = error => ({
    type: GET_BON_DETAIL_FAIL,
    payload: error,
  })
  