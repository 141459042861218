import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
  GET_FACTURES,
  GET_FACTURE_DETAIL,
  ADD_NEW_FACTURE,
  UPDATE_FACTURE,
  DELETE_FACTURE
 } from "./actionTypes"
import {
  getFacturesSuccess,
  getFacturesFail,
  getFactureDetailSuccess,
  getFactureDetailFail,
  addFactureSuccess,
  addFactureFail,
  updateFactureSuccess,
  updateFactureFail,
  deleteFactureSuccess,
  deleteFactureFail
} from "./actions"

//Include Both Helper File with needed methods
import { 
  getFactures,
  getFactureDetail,
  addNewFacture,
  updateFacture,
  deleteFacture,
 } from "../../helpers/fakebackend_helper"

function* fetchFactures() {
  try {
    const response = yield call(getFactures)
    yield put(getFacturesSuccess(response))
  } catch (error) {
    yield put(getFacturesFail(error))
  }
}

function* onUpdateFacture ({payload: facture }) {
  try {
    const response = yield call(updateFacture, facture);
    yield put(updateFactureSuccess(response));
  } catch (error) {
    yield put(updateFactureFail(error));
  }
}

function* onDeleteFacture ({payload: facture}) {
  try {
    const response = yield call(deleteFacture, facture);
    yield put(deleteFactureSuccess(response));
  } catch (error) {
    yield put(deleteFactureFail(error));
  }
}

function* onAddNewFacture ({ payload: facture}) {
  try {
    const response = yield call(addNewFacture, facture);
    yield put(addFactureSuccess(response));
  } catch (error) {
    yield put(addFactureFail(error));
  }
}

function* fetchFactureDetail({ factureId }) {
  try {
    const response = yield call(getFactureDetail, factureId)
    yield put(getFactureDetailSuccess(response))
  } catch (error) {
    yield put(getFactureDetailFail(error))
  }
}

function* factureSaga() {
  yield takeEvery(GET_FACTURES, fetchFactures)
  yield takeEvery(GET_FACTURE_DETAIL, fetchFactureDetail)
  yield takeEvery(ADD_NEW_FACTURE, onAddNewFacture);
  yield takeEvery(UPDATE_FACTURE, onUpdateFacture);
  yield takeEvery(DELETE_FACTURE, onDeleteFacture);
}

export default factureSaga
