/* BONS */
export const GET_BONS = "GET_BONS"
export const GET_BONS_SUCCESS = "GET_BONS_SUCCESS"
export const GET_BONS_FAIL = "GET_BONS_FAIL"

export const GET_BON_DETAIL = "GET_BON_DETAIL"
export const GET_BON_DETAIL_SUCCESS = "GET_BON_DETAIL_SUCCESS"
export const GET_BON_DETAIL_FAIL = "GET_BON_DETAIL_FAIL"

/**
 * add BON
 */
 export const ADD_NEW_BON = "ADD_NEW_BON"
 export const ADD_BON_SUCCESS = "ADD_BON_SUCCESS"
 export const ADD_BON_FAIL = "ADD_BON_FAIL"

 /**
 * Edit BON
 */
export const UPDATE_BON = "UPDATE_BON"
export const UPDATE_BON_SUCCESS = "UPDATE_BON_SUCCESS"
export const UPDATE_BON_FAIL = "UPDATE_BON_FAIL"

/**
 * Delete BON
 */
export const DELETE_BON = "DELETE_BON"
export const DELETE_BON_SUCCESS = "DELETE_BON_SUCCESS"
export const DELETE_BON_FAIL = "DELETE_BON_FAIL"

