import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../../components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  getBons as onGetBons,
  addNewBon as onAddNewBon,
  updateBon as onUpdateBon,
  deleteBon as onDeleteBon,
} from "../../../store/actions";

import {
  OrderId,
  SupplierName,
  Date,
  Amount,
 // FactureStatus,
 // DownloadPdf,
} from "./bonCCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card
} from "reactstrap";
import DeleteModal from "../../../components/Common/DeleteModal";

function DatatableTables() {

  document.title="Bon de commande | Minible - Responsive Bootstrap 5 Admin Dashboard"

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [bon, setBon] = useState([]);
  

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderID: (bon && bon.orderID) || "",
      supplierName: (bon && bon.supplierName) || "",
      date: (bon && bon.date) || "",
      Amount: (bon && bon.Amount) || "",
    },
    validationSchema: Yup.object({
      orderID: Yup.string().required("Please Enter Your Order Id"),
      supplierName: Yup.string().required("Please Enter Your Supplier Name"),
      date: Yup.string().required("Please Enter Your Order Date"),
      Amount: Yup.string().required("Total Amount"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateBon = {
          id: bon ? bon.id : 0,
          orderID: values.orderID,
          supplierName: values.supplierName,
          date: values.date,
          Amount: values.Amount,
        };
  
        // update Bon
        dispatch(onUpdateBon(updateBon));
        validation.resetForm();
      } else {
        const newBon = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderID: values["orderID"],
          supplierName: values["supplierName"],
          date: values["date"],
          Amount: values["Amount"],
        };
       
        // save new Bon
        dispatch(onAddNewBon(newBon));
        validation.resetForm();
      }
      toggle();
    },
  });
  const resetForm = () => {
    validation.resetForm();
  };

  const dispatch = useDispatch();


  const selectBonsState = (state) => state.bons;
  const BonsProperties = createSelector(
    selectBonsState,
      (Bons) => ({
        bons: Bons.bons,
      })
  );

    const {
      bons
  } = useSelector(BonsProperties);

  useEffect(() => {
    if (bons && !bons.length) {
      dispatch(onGetBons());
    }
  }, [dispatch, bons]);  

  useEffect(() => {
    setBon(bons);
  }, [bons]);

  useEffect(() => {
    if (!isEmpty(bons)) {
      setIsEdit(false);
    }
  }, [bons]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setBon(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleBonClick = useCallback((arg) => {
    const bon = arg;
    setBon({
      id: bon.id,
      orderID: bon.orderID,
      supplierName: bon.supplierName,
      date: bon.date,
      Amount: bon.Amount,
    });

    setIsEdit(true);

    toggle();
  }, [toggle]);

  //delete bon
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (bon) => {
    setBon(bon);
    setDeleteModal(true);
  };

  const handleDeleteBon = () => {
    if (bon.id) {
      dispatch(onDeleteBon(bon.id));
      setDeleteModal(false);
    }
  };
  const handleBonClicks = () => {
    setIsEdit(false);
    toggle();
  };
  const handleAddBon = () => {
    setIsEdit(false);
    toggle();
  };
  

  const columns = useMemo(
    () => [
      {
        Header: "ID du bon ",
        accessor: "orderID",
        disableGlobalFilter: true,
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        // formatter: (cellContent, row) => handleValidDate(row.date),
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Nom du fournisseur",
        accessor: "supplierName",
        filterable: true,
        Cell: (cellProps) => {
          return <SupplierName {...cellProps} />;
        },
      },
      {
        Header: "Montant",
        accessor: "Amount",
        filterable: true,
        Cell: (cellProps) => {
          return <Amount {...cellProps} />;
        },
      },
     
      {
        Header: "Détail du bon",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center">
             {/*  <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  const orderList = cellProps.row.original;
                  handleBonClick(orderList);
                }}
              >
                <i className="uil uil-pen font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderList = cellProps.row.original;
                  onClickDelete(orderList);
                }}
              >
                <i
                  className="uil uil-trash-alt font-size-18"
                  id="deletetooltip"
                />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to={`/bonC-view/${cellProps.row.original.id}`}
                className="text-success"
              >
                  <i className="uil uil-eye font-size-18" id="viewtooltip"/> 
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  Voir
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [handleBonClick]
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBon}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Facturation" breadcrumbItem="Bons de commande" />
          <Row className="mb-2">
            <Col md={6}>
              <div className="mb-3">
                <Link to="#" className="btn btn-success waves-effect waves-light"
                onClick={handleAddBon}
                >
                  
                  <i className="mdi mdi-plus me-2"></i> Ajouter un bon de commande
                  
                  </Link>
              </div>
            </Col>

           {/*  <Col md={6}>
              <div className="form-inline float-md-end mb-3">
                <div className="search-box ml-2">
                  <div className="position-relative">
                    <input type="text" className="form-control rounded border-0" placeholder="Search..." />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </div>
              </Col> */}

          </Row>
          <Card>
          <Row>
            <Col xs={12}>
              <TableContainer
                columns={columns}
                data={bons}
                isGlobalFilter={true}
                isAddBon={true}
                isAddTableBorderStrap={true}
                handleBonClicks={handleBonClicks}
              />
            </Col>
          </Row>
          </Card>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Modifier le  bon" : "Nouveau  bon de commande"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">ID du bon</Label>
                      <Input
                        name="orderID"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderID || ""}
                        invalid={
                          validation.touched.orderID &&
                          validation.errors.orderID
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderID &&
                      validation.errors.orderID ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderID}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Date</Label>
                      <Input
                        name="date"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.date || ""}
                        invalid={
                          validation.touched.date && validation.errors.date
                            ? true
                            : false
                        }
                      />
                      {validation.touched.date && validation.errors.date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.date}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Nom du fournisseur</Label>
                      <Input
                        name="supplierName"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.supplierName || ""}
                        invalid={
                          validation.touched.supplierName &&
                          validation.errors.supplierName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.supplierName &&
                      validation.errors.supplierName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.supplierName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Montant</Label>
                      <Input
                        name="Amount"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Amount || ""}
                        invalid={
                          validation.touched.Amount && validation.errors.Amount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.Amount && validation.errors.Amount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Amount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary opacity-50 border-0 me-3"
                      onClick={() => {
                        toggle();
                        resetForm();
                      }}
                    >
                      Annuler
                    </button>
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Envoyer
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default DatatableTables;
