import React, { useState } from "react";
import {
  Col, Row, Card, CardBody, CardTitle, Container,
  Pagination, PaginationItem, PaginationLink,
  Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const LossesAndDamagesPage = () => {
  document.title = "Minible | Pertes & Avaries";

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Nombre d'éléments par page

  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedLossIndex, setSelectedLossIndex] = useState(null);

  const [lossesData, setLossesData] = useState([
    { productName: "Lait", productCode: "1234", quantityLost: 10, date: "2024-05-10", lossType: "Endommagement", cause: "Erreur d'entreposage" },
    { productName: "Pain", productCode: "5678", quantityLost: 5, date: "2024-05-09", lossType: "Expiration", cause: "Stockage prolongé" },
    // Ajoutez d'autres données au besoin
  ]);

  const [newLoss, setNewLoss] = useState({
    productName: "", productCode: "", quantityLost: "", date: "", lossType: "", cause: ""
  });

  const toggleForm = () => setShowForm(!showForm);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLoss({ ...newLoss, [name]: value });
  };

  const handleAddLoss = () => {
      // Vérifier si tous les champs sont remplis
  if (
    newLoss.productName.trim() === "" ||
    newLoss.productCode.trim() === "" ||
    newLoss.quantityLost.trim() === "" ||
    newLoss.date.trim() === "" ||
    newLoss.lossType.trim() === "" ||
    newLoss.cause.trim() === ""
  ) {
    alert("Veuillez remplir tous les champs avant de soumettre la perte.");
    return;
  }

    if (editMode) {
      const updatedLosses = [...lossesData];
      updatedLosses[selectedLossIndex] = newLoss;
      setLossesData(updatedLosses);
      setEditMode(false);
    } else {
      setLossesData([ newLoss, ...lossesData ]);
    }
    setNewLoss({ productName: "", productCode: "", quantityLost: "", date: "", lossType: "", cause: "" });
    toggleForm();
  };

  const handleEdit = (index) => {
    setNewLoss(lossesData[index]);
    setSelectedLossIndex(index);
    setEditMode(true);
    toggleForm();
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleDelete = (index) => {
    setDeleteIndex(index);
    toggleDeleteModal();
  };

  const confirmDelete = () => {
    const updatedLosses = lossesData.filter((_, index) => index !== deleteIndex);
    setLossesData(updatedLosses);
    toggleDeleteModal();
  };

  const filteredLosses = lossesData.filter(item =>
    item.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calcul du nombre total de pages
  const totalPages = Math.ceil(filteredLosses.length / itemsPerPage);

  // Fonction pour obtenir les données de la page actuelle
  const getCurrentData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredLosses.slice(startIndex, endIndex);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Perte & Avaries" breadcrumbItem="Stocks" />

          {/* Barre de recherche */}
          <div className="mb-3 d-flex align-items-center">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Rechercher un produit..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </div>
            <Button color="primary" className="ml-2" onClick={toggleForm}>
              Ajouter une perte
            </Button>
          </div>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Pertes & Avaries</CardTitle>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Nom du produit</th>
                          <th>Code produit</th>
                          <th>Quantité perdue</th>
                          <th>Date</th>
                          <th>Type de perte</th>
                          <th>Cause</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getCurrentData().map((item, index) => (
                          <tr key={index}>
                            <td>{item.productName}</td>
                            <td>{item.productCode}</td>
                            <td>{item.quantityLost}</td>
                            <td>{item.date}</td>
                            <td>{item.lossType}</td>
                            <td>{item.cause}</td>
                            <td>
                              <Button color="warning" size="sm" className="mr-2" onClick={() => handleEdit(index)}>Modifier</Button>
                              <Button color="danger" size="sm" onClick={() => handleDelete(index)}>Supprimer</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination */}
                  <Pagination className="pagination justify-content-end mt-3">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                    </PaginationItem>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <PaginationItem key={index} active={currentPage === index + 1}>
                        <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                          {index + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                    </PaginationItem>
                  </Pagination>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modal pour ajouter ou modifier une perte */}
        <Modal isOpen={showForm} toggle={toggleForm}>
          <ModalHeader toggle={toggleForm}>
            {editMode ? "Modifier une perte" : "Ajouter une perte"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="productName">Nom du produit</Label>
                <Input
                  type="text"
                  name="productName"
                  id="productName"
                  value={newLoss.productName}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="productCode">Code produit</Label>
                <Input
                  type="text"
                  name="productCode"
                  id="productCode"
                  value={newLoss.productCode}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quantityLost">Quantité perdue</Label>
                <Input
                  type="number"
                  name="quantityLost"
                  id="quantityLost"
                  value={newLoss.quantityLost}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="date">Date</Label>
                <Input
                  type="date"
                  name="date"
                  id="date"
                  value={newLoss.date}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lossType">Type de perte</Label>
                <Input
                  type="text"
                  name="lossType"
                  id="lossType"
                  value={newLoss.lossType}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="cause">Cause</Label>
                <Input
                  type="text"
                  name="cause"
                  id="cause"
                  value={newLoss.cause}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <Button color="primary" onClick={handleAddLoss}>
                {editMode ? "Modifier" : "Ajouter"}
              </Button>
              <Button color="secondary" className="ml-2" onClick={toggleForm}>Annuler</Button>
            </Form>
          </ModalBody>
        </Modal>

        {/* Modal pour confirmer la suppression */}
        <Modal isOpen={showDeleteModal} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Confirmer la suppression</ModalHeader>
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer cette perte ?
          </ModalBody>
          <div className="modal-footer">
            <Button color="danger" onClick={confirmDelete}>Oui</Button>
            <Button color="secondary" onClick={toggleDeleteModal}>Non</Button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default LossesAndDamagesPage;























// import React, { useState } from "react";
// import {
//   Col, Row, Card, CardBody, CardTitle, Container,
//   Pagination, PaginationItem, PaginationLink,
//   Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input
// } from "reactstrap";
// import Breadcrumbs from "../../components/Common/Breadcrumb";

// const LossesAndDamagesPage = () => {
//   document.title = "Minible | Pertes & Avaries";

//   const [searchTerm, setSearchTerm] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5; // Nombre d'éléments par page

//   const [showForm, setShowForm] = useState(false);
//   const [lossesData, setLossesData] = useState([
//     { productName: "Lait", productCode: "1234", quantityLost: 10, date: "10/05/2024", lossType: "Endommagement", cause: "Erreur d'entreposage" },
//     { productName: "Pain", productCode: "5678", quantityLost: 5, date: "09/05/2024", lossType: "Expiration", cause: "Stockage prolongé" },
//     // Ajoutez d'autres données au besoin
//   ]);

//   const [newLoss, setNewLoss] = useState({
//     productName: "", productCode: "", quantityLost: "", date: "", lossType: "", cause: ""
//   });

//   const toggleForm = () => setShowForm(!showForm);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setNewLoss({ ...newLoss, [name]: value });
//   };

//   const handleAddLoss = () => {
//     setLossesData([...lossesData, newLoss]);
//     setNewLoss({ productName: "", productCode: "", quantityLost: "", date: "", lossType: "", cause: "" });
//     toggleForm();
//   };

//   const filteredLosses = lossesData.filter(item =>
//     item.productName.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   // Calcul du nombre total de pages
//   const totalPages = Math.ceil(filteredLosses.length / itemsPerPage);

//   // Fonction pour obtenir les données de la page actuelle
//   const getCurrentData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     return filteredLosses.slice(startIndex, endIndex);
//   };

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid={true}>
//           <Breadcrumbs title="Perte & Avaries" breadcrumbItem="Gestion des Pertes" />

//           {/* Barre de recherche */}
//           <div className="mb-3 d-flex align-items-center">
//             <div className="input-group">
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Rechercher un produit..."
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//               <div className="input-group-append">
//                 <span className="input-group-text">
//                   <i className="ri-search-line"></i>
//                 </span>
//               </div>
//             </div>
//             <Button color="primary" className="ml-2" onClick={toggleForm}>
//               Ajouter une perte
//             </Button>
//           </div>

//           <Row>
//             <Col className="col-12">
//               <Card>
//                 <CardBody>
//                   <CardTitle className="h4">Pertes & Avaries</CardTitle>
//                   <div className="table-responsive">
//                     <table className="table table-bordered table-striped table-nowrap mb-0">
//                       <thead>
//                         <tr>
//                           <th>Nom du produit</th>
//                           <th>Code produit</th>
//                           <th>Quantité perdue</th>
//                           <th>Date</th>
//                           <th>Type de perte</th>
//                           <th>Cause</th>
//                           <th>Actions</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {getCurrentData().map((item, index) => (
//                           <tr key={index}>
//                             <td>{item.productName}</td>
//                             <td>{item.productCode}</td>
//                             <td>{item.quantityLost}</td>
//                             <td>{item.date}</td>
//                             <td>{item.lossType}</td>
//                             <td>{item.cause}</td>
//                             <td>
//                               {/* Boutons d'édition et de suppression */}
//                               <Button color="warning" size="sm" className="mr-2">Modifier</Button>
//                               <Button color="danger" size="sm">Supprimer</Button>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                   </div>
//                   {/* Pagination */}
//                   <Pagination className="pagination justify-content-end mt-3">
//                     <PaginationItem disabled={currentPage === 1}>
//                       <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
//                     </PaginationItem>
//                     {Array.from({ length: totalPages }, (_, index) => (
//                       <PaginationItem key={index} active={currentPage === index + 1}>
//                         <PaginationLink onClick={() => setCurrentPage(index + 1)}>
//                           {index + 1}
//                         </PaginationLink>
//                       </PaginationItem>
//                     ))}
//                     <PaginationItem disabled={currentPage === totalPages}>
//                       <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
//                     </PaginationItem>
//                   </Pagination>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </Container>

//         {/* Modal pour ajouter une perte */}
//         <Modal isOpen={showForm} toggle={toggleForm}>
//           <ModalHeader toggle={toggleForm}>Ajouter une perte</ModalHeader>
//           <ModalBody>
//             <Form>
//               <FormGroup>
//                 <Label for="productName">Nom du produit</Label>
//                 <Input
//                   type="text"
//                   name="productName"
//                   id="productName"
//                   value={newLoss.productName}
//                   onChange={handleInputChange}
//                 />
//               </FormGroup>
//               <FormGroup>
//                 <Label for="productCode">Code produit</Label>
//                 <Input
//                   type="text"
//                   name="productCode"
//                   id="productCode"
//                   value={newLoss.productCode}
//                   onChange={handleInputChange}
//                 />
//               </FormGroup>
//               <FormGroup>
//                 <Label for="quantityLost">Quantité perdue</Label>
//                 <Input
//                   type="number"
//                   name="quantityLost"
//                   id="quantityLost"
//                   value={newLoss.quantityLost}
//                   onChange={handleInputChange}
//                 />
//               </FormGroup>
//               <FormGroup>
//                 <Label for="date">Date</Label>
//                 <Input
//                   type="date"
//                   name="date"
//                   id="date"
//                   value={newLoss.date}
//                   onChange={handleInputChange}
//                 />
//               </FormGroup>
//               <FormGroup>
//                 <Label for="lossType">Type de perte</Label>
//                 <Input
//                   type="text"
//                   name="lossType"
//                   id="lossType"
//                   value={newLoss.lossType}
//                   onChange={handleInputChange}
//                 />
//               </FormGroup>
//               <FormGroup>
//                 <Label for="cause">Cause</Label>
//                 <Input
//                   type="text"
//                   name="cause"
//                   id="cause"
//                   value={newLoss.cause}
//                   onChange={handleInputChange}
//                 />
//               </FormGroup>
//               <Button color="primary" onClick={handleAddLoss}>Ajouter</Button>
//               <Button color="secondary" className="ml-2" onClick={toggleForm}>Annuler</Button>
//             </Form>
//           </ModalBody>
//         </Modal>
//       </div>
//     </React.Fragment>
//   );
// };

// export default LossesAndDamagesPage;
