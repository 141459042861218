import {
    GET_MYCONTACT_INFO,
    GET_MYCONTACT_INFO_FAIL,
    GET_MYCONTACT_INFO_SUCCESS,
    GET_MYCONTACTS,
    GET_MYCONTACTS_FAIL,
    GET_MYCONTACTS_SUCCESS,
    ADD_NEW_MYCONTACT,
    ADD_MYCONTACT_SUCCESS,
    ADD_MYCONTACT_FAIL,
    UPDATE_MYCONTACT,
    UPDATE_MYCONTACT_SUCCESS,
    UPDATE_MYCONTACT_FAIL,
    DELETE_MYCONTACT,
    DELETE_MYCONTACT_SUCCESS,
    DELETE_MYCONTACT_FAIL,
  } from "./actionTypes"
  
  export const getMyContacts = () => ({
    type: GET_MYCONTACTS,
  })
  
  export const getMyContactsSuccess = mycontacts => ({
    type: GET_MYCONTACTS_SUCCESS,
    payload: mycontacts,
  })
  
  export const getMyContactsFail = error => ({
    type: GET_MYCONTACTS_FAIL,
    payload: error,
  })
  
  export const addNewMyContact = mycontact => ({
    type: ADD_NEW_MYCONTACT,
    payload: mycontact,
  })
  
  export const addMyContactSuccess = mycontact => ({
    type: ADD_MYCONTACT_SUCCESS,
    payload: mycontact,
  })  
  
  export const addMyContactFail = error => ({
    type: ADD_MYCONTACT_FAIL,
    payload: error,
  })
  
  export const updateMyContact = mycontact => ({
    type: UPDATE_MYCONTACT,
    payload: mycontact,
  })
  
  export const updateMyContactSuccess = mycontact => ({
    type: UPDATE_MYCONTACT_SUCCESS,
    payload: mycontact,
  })
  
  export const updateMyContactFail = error => ({
    type: UPDATE_MYCONTACT_FAIL,
    payload: error,
  })
  
  export const deleteMyContact = mycontact => ({
    type: DELETE_MYCONTACT,
    payload: mycontact,
  })
  
  export const deleteMyContactSuccess = mycontact => ({
    type: DELETE_MYCONTACT_SUCCESS,
    payload: mycontact,
  })
  
  export const deleteMyContactFail = error => ({
    type: DELETE_MYCONTACT_FAIL,
    payload: error,
  })
  
  export const getMyContactInfo = () => ({
    type: GET_MYCONTACT_INFO,
  })
  
  export const getMyContactInfoSuccess = mycontactInfo => ({
    type: GET_MYCONTACT_INFO_SUCCESS,
    payload: mycontactInfo,
  })
  
  export const getMyContactInfoFail = error => ({
    type: GET_MYCONTACT_INFO_FAIL,
    payload: error,
  })
  