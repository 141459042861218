import {
    GET_BONS_FAIL,
    GET_BONS_SUCCESS,
    GET_BON_DETAIL_SUCCESS,
    GET_BON_DETAIL_FAIL,
    ADD_BON_SUCCESS,
    ADD_BON_FAIL,
    UPDATE_BON_SUCCESS,
    UPDATE_BON_FAIL,
    DELETE_BON_SUCCESS,
    DELETE_BON_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    bons: [],
    bonDetail: {},
    error: {},
  }
  
  const Bons = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_BONS_SUCCESS:
        return {
          ...state,
          bons: action.payload,
        }
  
      case GET_BONS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
        case ADD_BON_SUCCESS:
          return {
            ...state,
            bons: [...state.bons, action.payload],
          }
  
        case ADD_BON_FAIL:
          return {
            ...state,
            error: action.payload,
          }
  
          case UPDATE_BON_SUCCESS:
            return {
              ...state,
              bons: state.bons.map(bon => 
                bon.id.toString() === action.payload.id.toString()
                ? {bon, ...action.payload}
                : bon
                ),
            }
  
            case UPDATE_BON_FAIL:
              return {
                ...state,
                error: action.payload
              }
  
              case DELETE_BON_SUCCESS: 
              return {
                ...state,
                bons: state.bons.filter(
                  bon => bon.id.toString() !== action.payload.toString()
                ),
              }
  
              case DELETE_BON_FAIL:
                return {
                  ...state,
                  error: action.payload
                }
  
      case GET_BON_DETAIL_SUCCESS:
        return {
          ...state,
          bonDetail: action.payload,
        }
  
      case GET_BON_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
  export default Bons
  