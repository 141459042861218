import React , {useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Row,
    Card,
    CardBody,
    Col,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";
import axios from "axios"
import { key } from "../../constants/key"

const CategorieContent = ({ categories, onEdit, onDelete }) => {

    const accessToken = JSON.parse(localStorage.getItem("authUser")).data.accessToken;
    const [listCategorie, setListCategorie] = useState('')
    const getAllCategorie = async() => {
        try {
            const response =  await axios.get(
                `${key.apiBaseURL}/voirtoutecategorie`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
          if(response){
            console.log("categorie list :: ", response.data)
          }
        } catch (error) {
            console.log('error', error)
        }
    } 



    return (
        <React.Fragment>
            <Row>
                {categories.map((categorie, index) => (
                    <Col key={index} md={5} xl={4}>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-start">
                                    <div className="flex-shrink-0 me-4">
                                        <div className="avatar-sm">
                                            <span
                                                className={
                                                    "avatar-title rounded-circle bg-success-subtle text-success font-size-16"
                                                }
                                            >
                                                {categorie.nom.charAt(0)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex-grow-1 align-self-center'>
                                        <Row>
                                            <Col className="">
                                                <UncontrolledDropdown className="float-end">
                                                    <DropdownToggle tag="a" className="text-body font-size-16" caret>
                                                        <i className="uil uil-ellipsis-h"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem onClick={() => onEdit(categorie)}>Modifier</DropdownItem>
                                                        <DropdownItem onClick={() => onDelete(categorie.id)}>Supprimer</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <div className="">
                                                    <h5 className="text-truncate font-size-16">
                                                        {categorie.nom}
                                                    </h5>
                                                    <p className="text-muted font-size-14">
                                                        {categorie.produits} produits
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
};

export default CategorieContent;
