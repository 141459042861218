import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Form, FormFeedback, Input, Button, Row, Col, Label } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { key } from "../../constants/key";

const CategoryModal = ({ isOpen, toggle, getAllCategorie, accessToken }) => {
  const validationCategorie = useFormik({
    enableReinitialize: true,
    initialValues: {
      libelle: "",
    },
    validationSchema: Yup.object({
      libelle: Yup.string().required("Please Enter the categorie name"),
    }),
    onSubmit: (values) => {
      const data = {
        libelle: values.libelle,
      };
      axios.post(
        `${key.apiBaseURL}/createcategoriecontact`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((data)=>{
        if(data.status === 201){
          //alert("Success")
          getAllCategorie();
          validationCategorie.resetForm();
          //toggle();
        }
      })
      .catch((error)=>{
        alert("Erreur lors de la création du contact");
        console.log("create categorie error :: ", error);
      })
      toggle()
    }
  });
  const resetForm = () => {
    validationCategorie.resetForm();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Nouvelle Catégorie
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(e) => {
          e.preventDefault();
          validationCategorie.handleSubmit();
          return false;
        }}>
          <Row className="g-3">
            <Col lg={12}>
              <div>
                <Label className="form-label">Nouvelle catégorie de contacts</Label>
                <Input
                  name="libelle"
                  type="text"
                  placeholder="Entrez votre nouvelle categorie"
                  onChange={validationCategorie.handleChange}
                  onBlur={validationCategorie.handleBlur}
                  value={validationCategorie.values.libelle || ""}
                  invalid={validationCategorie.touched.libelle && validationCategorie.errors.libelle ? true : false}
                />
                {validationCategorie.touched.libelle && validationCategorie.errors.libelle ? (
                  <FormFeedback type="invalid">
                    {validationCategorie.errors.libelle}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-end">
              <Button type="button" className="btn btn-secondary me-3" onClick={() => {
                      toggle();
                      resetForm();
                    }}>
                Annuler
              </Button>
              <Button type="submit" className="btn btn-success">
                Enregistrer
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

CategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  getAllCategorie: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
};

export default CategoryModal;