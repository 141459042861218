
import {
    GET_FACTURES_FAIL,
    GET_FACTURES_SUCCESS,
    GET_FACTURE_DETAIL_SUCCESS,
    GET_FACTURE_DETAIL_FAIL,
    ADD_FACTURE_SUCCESS,
    ADD_FACTURE_FAIL,
    UPDATE_FACTURE_SUCCESS,
    UPDATE_FACTURE_FAIL,
    DELETE_FACTURE_SUCCESS,
    DELETE_FACTURE_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    factures: [],
    factureDetail: {},
    error: {},
  }
  
  const Factures = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_FACTURES_SUCCESS:
        return {
          ...state,
          factures: action.payload,
        }
  
      case GET_FACTURES_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
        case ADD_FACTURE_SUCCESS:
          return {
            ...state,
            factures: [...state.factures, action.payload],
          }
  
        case ADD_FACTURE_FAIL:
          return {
            ...state,
            error: action.payload,
          }
  
          case UPDATE_FACTURE_SUCCESS:
            return {
              ...state,
              factures: state.factures.map(facture => 
                facture.id.toString() === action.payload.id.toString()
                ? {facture, ...action.payload}
                : facture
                ),
            }
  
            case UPDATE_FACTURE_FAIL:
              return {
                ...state,
                error: action.payload
              }
  
              case DELETE_FACTURE_SUCCESS: 
              return {
                ...state,
                factures: state.factures.filter(
                  facture => facture.id.toString() !== action.payload.toString()
                ),
              }
  
              case DELETE_FACTURE_FAIL:
                return {
                  ...state,
                  error: action.payload
                }
  
      case GET_FACTURE_DETAIL_SUCCESS:
        return {
          ...state,
          factureDetail: action.payload,
        }
  
      case GET_FACTURE_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
  export default Factures
  