import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { 
  GET_MYCONTACTS, 
  GET_MYCONTACT_INFO,
  ADD_NEW_MYCONTACT,
  UPDATE_MYCONTACT,
  DELETE_MYCONTACT
 } from "./actionTypes";
import {
  getMyContactsSuccess,
  getMyContactsFail,
  addMyContactSuccess,
  addMyContactFail,
  updateMyContactSuccess,
  updateMyContactFail,
  deleteMyContactSuccess,
  deleteMyContactFail,
  getMyContactInfoSuccess,
  getMyContactInfoFail,
} from "./actions";

//Include Both Helper File with needed methods
import { 
  getMyContacts,
  getMyContactInfo,
  addNewMyContact,
  updateMyContact,
  deleteMyContact,
} from "../../helpers/fakebackend_helper";

function* fetchMyContacts() {
  try {
    const response = yield call(getMyContacts);
    yield put(getMyContactsSuccess(response));
  } catch (error) {
    yield put(getMyContactsFail(error));
  }
}

function* onUpdateMyContact ({payload: contact }) {
  try {
    const response = yield call(updateMyContact, contact);
    yield put(updateMyContactSuccess(response));
  } catch (error) {
    yield put(updateMyContactFail(error));
  }
}

function* onDeleteMyContact ({payload: mycontact}) {
  try {
    const response = yield call(deleteMyContact, mycontact);
    yield put(deleteMyContactSuccess(response));
  } catch (error) {
    yield put(deleteMyContactFail(error));
  }
}

function* onAddNewMyContact ({ payload: mycontact}) {
  try {
    const response = yield call(addNewMyContact, mycontact);
    yield put(addMyContactSuccess(response));
  } catch (error) {
    yield put(addMyContactFail(error));
  }
}


function* fetchMyContactInfo() {
  try {
    const response = yield call(getMyContactInfo);
    yield put(getMyContactInfoSuccess(response));
  } catch (error) {
    yield put(getMyContactInfoFail(error));
  }
}

function* MyContactSaga() {
  yield takeEvery(GET_MYCONTACTS, fetchMyContacts);
  yield takeEvery(ADD_NEW_MYCONTACT, onAddNewMyContact);
  yield takeEvery(UPDATE_MYCONTACT, onUpdateMyContact);
  yield takeEvery(DELETE_MYCONTACT, onDeleteMyContact);
  yield takeEvery(GET_MYCONTACT_INFO, fetchMyContactInfo);
}

export default MyContactSaga;
