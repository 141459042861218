import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Row,
    Card,
  } from "reactstrap";

  const CardContent = ({ user, onEdit, onDelete }) => {
    const getContactTypeIcon = (contactType) => {
        switch (contactType) {
          case "Client":
            return <i className="uil-users-alt"></i>; // Use appropriate icon for "Client"
          case "Fournisseur":
            return <i className="uil-truck"></i>; // Use appropriate icon for "Fournisseur"
          default:
            return <i className="uil-users-alt"></i>; // Default icon if contactType is unknown
        }
      };
    return (
      <React.Fragment>
        <Card className="text-center p-0">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <Row>
                <h5 className="font-size-16 mb-1 d-flex align-items-center">
                  <span className="font-size-16 ms-2 me-auto">
                  {getContactTypeIcon(user.type)}
                  </span>
                  {user.nom}
                  <span className="badge badge-pill font-size-12 bg-success-subtle text-success ms-2 me-auto">
                    {user.libelle}
                  </span>
                </h5>
              </Row>
            </li>
            <li className="list-group-item p-0">
              <p className="text-muted mb-2">
                 {user.adresse} <br />
                 {user.email} <br />
                 {user.tel}
              </p>
            </li>
            <li className="list-group-item">
            <div className="btn-group" role="group">
                    <Link to="#" className="text-truncate me-4" onClick={() => onEdit(user)}> 
                        <i className="uil uil-pen font-size-16" id="edittooltip" /> 
                    </Link>
                    <Link to="#" className="text-truncate me-4 text-danger" onClick={() => onDelete(user)}>
                        <i className="uil uil-trash-alt font-size-16" id="deletetooltip"/> 
                    </Link>
                    <Link to="#" className="text-truncate text-success" >
                        <i className="uil uil-eye font-size-16" id="viewtooltip"/> 
                    </Link>
                </div>
            </li>
          </ul>
        </Card>
      </React.Fragment>
    );
  };
  
  CardContent.propTypes = {
    user: PropTypes.shape({
      contactName: PropTypes.string.isRequired,
      contactAddress: PropTypes.string.isRequired,
      contactEmail: PropTypes.string.isRequired,
      contactPhoneno: PropTypes.string.isRequired,
      categorie: PropTypes.string.isRequired,
    }).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };
  
  export default CardContent;